import { ReactNode, useContext, useLayoutEffect, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import LANG, { getLangWithKey, ILang } from "../../../../../lang";
import useDarkMode from "../../../../../hooks/useDarkMode";

import { OverlayContext } from "../../../../../contexts/OverlayContext";


import './ImageModalSection.scss'
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../../../components/bootstrap/Modal";
import { useFormik } from "formik";
import FormGroup from "../../../../../components/bootstrap/forms/FormGroup";
import { useToasts } from 'react-toast-notifications';
import Toasts from '../../../../../components/bootstrap/Toasts';
import Alert from '../../../../../components/bootstrap/Alert';
import Input from "../../../../../components/bootstrap/forms/Input";
import Button from "../../../../../components/bootstrap/Button";
import * as Yup from 'yup';

  
const ImageModalSection: any = (props: any) => {
    const { showImageSelection, setShowModalImageSelection, resetStates, title, saveData } = props;
  const { setImageToUse  } = useContext(OverlayContext);
  const [isDisabled, setIsDisabled] = useState(false);
  const [ imagePreview, setImagePreview] = useState();
  const { t } = useTranslation('menu');
  const { i18n } = useTranslation();


  const changeLanguage = (lng: ILang['key']['lng']) => {
      i18n.changeLanguage(lng);
  };

  /**
   * Language attribute
   */
  useLayoutEffect(() => {
      document.documentElement.setAttribute('lang', i18n.language.substring(0, 2));
  });

  const stepImageForm = useFormik({
    initialValues: {
      fileToSubmit: null,
    },
    validationSchema: Yup.object().shape({
      fileToSubmit: Yup.mixed()
          .required(t("Required") as string)
          .test(
              "fileSize",
              t("FileSizeBig") as string,
              (value:any) => !value || (value && value.size <= 1024 * 14648.4375)
          )
          .test(
              "fileFormat",
              t("UnsuportedFormat") as string,
              (value:any) => !value || (value && ["image/jpeg", "image/png", "image/gif"].includes(value.type))
          ),
  }),
    onSubmit: (values) => {
      
      if (values.fileToSubmit) {
        console.log('File submitted:', values.fileToSubmit);
        console.log('File submitted:', typeof values.fileToSubmit);
        setImageToUse(values.fileToSubmit);
        saveData();
        cleanLocalStates();
    }
    },
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0];
    stepImageForm.setFieldValue('fileToSubmit', file);
    stepImageForm.setFieldTouched('fileToSubmit', true, true);

    if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result as any);
        };
        reader.readAsDataURL(file);
    } else {
        setImagePreview(undefined);
    }
};

  const changeActiveModal = () => {
    setShowModalImageSelection(!showImageSelection);
  }

  const handleClose = () => {
    setImageToUse(undefined);
    // commonStatesProcess();
    setImagePreview(undefined);
    resetStates();
  };

  const cleanLocalStates = () => {
    setIsDisabled(false);
    stepImageForm.resetForm();
    setImagePreview(undefined);
  }


  return (
    <Modal 
      id="modalConfig"
      titleId="titleConfig"
      isOpen={ showImageSelection } // Example: state
      setIsOpen={ setShowModalImageSelection } // Example: setState
      isStaticBackdrop={ true } 
      isScrollable={ false } 
      isCentered={true} 
      fullScreen= 'md' 
      isAnimation={ true }
    >
      <ModalHeader>
        <ModalTitle id="imageModalSectionTitle">{ title }</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="topBodyModalConfig">
          <h6>{t("SelectImageCurrentLevel") as ReactNode}</h6>
          <FormGroup>
            <Input type='file' disabled={isDisabled} onChange={handleFileChange} />
            {stepImageForm.errors.fileToSubmit && stepImageForm.touched.fileToSubmit ? (
                <div className="error">{stepImageForm.errors.fileToSubmit}</div>
            ) : <></>}
          </FormGroup>
        </div>
        <div className="bottomBodyModalConfig">
          <div className='previewImage mt-1'>
            { imagePreview !== undefined ? <img id="imgPreview" src={imagePreview} />: <></>}
          </div>
        </div>
      </ModalBody>
      <ModalFooter /* className={ String } */>
        <button type='button' className='uploadImage btn btn-primary' disabled={isDisabled || imagePreview === undefined } onClick={stepImageForm.submitForm}>
          {t("UploadNewImageMap") as ReactNode}
        </button>
        <div className='buttonSeparator'></div>
        <button type='button' className='recalibrate btn btn-primary' disabled={isDisabled} onClick={handleClose}>
          {t("Cancel")}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ImageModalSection;
