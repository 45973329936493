import React, { useContext, useState, useEffect, useRef } from 'react'

import FormGroup from '../bootstrap/forms/FormGroup'
import Label from '../bootstrap/forms/Label'
import Input from '../bootstrap/forms/Input'
import Checks from '../bootstrap/forms/Checks'

import ListGroup, { ListGroupItem } from '../bootstrap/ListGroup'
import Button from '../bootstrap/Button'
import EditableListItem from './EditableListItem'

import { OverlayContext } from '../../contexts/OverlayContext'
import sensorsServices from '../../services/sensorsServices'
import { currentPose } from '../../helpers/matterportHelper';
import { useTranslation } from "react-i18next";

function SensorProperty() {

    const [addNewSensor, setAddNewSensor] = useState(false);
    const [selectedValue, setSelectedValue] = useState('goToDocumentData');
    const initialSensorData = {

        sensorName: "sensor 1",
        isExternalSensor: true,
        objectTransform: "",
        externalSensorURL: "",
        sensorUnits: "°C",
        sensorMinValue: "15.5",
        sensorMaxValue: "35.5",
        sensorMinTolerance: "20.5",
        sensorMaxTolerance: "30.5",
        sensorCurrentValue: "25.5",
        sweepCameraPosition: "",
        sweepCameraPosition: {
            sweepointUUID: "text",
            cameraHorizontalRotation: 0.1,
            cameraVerticalRotation: 0.1,
        },
        previousName: ""
    }
    const [sensorData, setSensorData] = useState(initialSensorData)
    const [lastIndex, setLasIndex] = useState(0);
    const itemList = useRef();
    const {
        editMode,
        setEditMode,
        activeSaveButton,
        setActiveSaveButton,
        setReturnView,
        setActivePositionButtons,
        positionEditObject,
        currentNodeSelected,
        isSaveClicked,
        setPositionEditObject,
        setModalState,
        modalState,
        currentSensors, setCurrentSensors
    } = useContext(OverlayContext);

    const { t } = useTranslation('menu');

    const changeName = (name) => {
        setSensorData({
            ...sensorData, sensorName: name,
            previousName: name
        })
    }
    const handleSensorUrl = (value) => {
        const regex = /src="(.*?)"/;
        const resultado = regex.exec(value);
        if (Object.is(resultado, null)) {
            setSensorData({ ...sensorData, externalSensorURL: value })
        }
        if (Array.isArray(resultado) && resultado[1] !== undefined) {
            setSensorData({ ...sensorData, externalSensorURL: resultado[1] })
        }
        if (value !== "") {
            setActiveSaveButton(false)
        }
        setActiveSaveButton(true)
    }

    const getElementData = (data) => {

        setSensorData({
            ...sensorData,
            sensorName: data.name,
            previousName: data.name,
            objectTransform: data.position,
            sweepCameraPosition: data.camera,
            externalSensorURL: data.url
        })
    }

    const deleteByName = (name) => {
        sensorsServices.deleteSensor(currentNodeSelected._id, name)
            .then(res => {
                setEditMode(false)
                if (res.ok === true) {
                    setActiveSaveButton(false);
                    updateSensorsToShow();
                }
            }
            )
            .catch(err => console.error(err));
    }
    const updateSensorsToShow = () => {
        sensorsServices.getSensorsByParentUUID(currentNodeSelected._id).then(res => {
            if (res.ok === true) {
                setCurrentSensors(res.sensors)
                setLasIndex(res.lastIndex);
            }
        }).catch((err) => console.error({ err }))
    }



    useEffect(() => {
        if (editMode) {
            setReturnView(true);
        }
    }, [editMode])
    useEffect(() => {
        if (currentNodeSelected !== null && editMode === false) {
            updateSensorsToShow()
        }
    }, [currentNodeSelected, editMode])
    useEffect(() => {
        if (positionEditObject.editPosition !== undefined) {
            setSensorData({
                ...sensorData, objectTransform: {
                    xPosition: positionEditObject.editPosition.position.x,
                    yPosition: positionEditObject.editPosition.position.y,
                    zPosition: positionEditObject.editPosition.position.z,
                    xRotation: positionEditObject.editPosition.rotation._x,
                    yRotation: positionEditObject.editPosition.rotation._y,
                    zRotation: positionEditObject.editPosition.rotation._z,
                    xScale: positionEditObject.editPosition.scale.x,
                    yScale: positionEditObject.editPosition.scale.y,
                    zScale: positionEditObject.editPosition.scale.z
                }
                , sweepCameraPosition: {
                    sweepointUUID: positionEditObject.editsweepCameraPosition.sweepointUUID,
                    cameraHorizontalRotation: positionEditObject.editsweepCameraPosition.cameraHorizontalRotation,
                    cameraVerticalRotation: positionEditObject.editsweepCameraPosition.cameraVerticalRotation
                },
            })
        }
    }, [positionEditObject])
    useEffect(() => {

        if (isSaveClicked) {
            if (!addNewSensor) {
                sensorsServices.uppdateSensorByParentId(
                    currentNodeSelected._id,
                    sensorData,
                    sensorData.previousName
                )
                    .then(res => {
                        if (res.ok) {

                            setEditMode(false)
                            setActiveSaveButton(false)
                            setModalState({
                                ...modalState,
                                isOpen: !modalState.isOpen,
                                data: {
                                    title: t("UpdateTitle"),
                                    text: t("UpdatedElementCorrectly"),
                                    id: "newSensor",
                                    actionType: "new",
                                },
                            });
                        }
                    })
                    .catch((err) => {
                        if (err.response.data.msg) {
                            setModalState({
                                ...modalState,
                                isOpen: !modalState.isOpen,
                                data: {
                                    title: t("Error"),
                                    text: t("AlreadyExistElement"),
                                    id: "errorModel",
                                    actionType: "error",
                                },
                            });
                        }
                    })
            } else {

                sensorsServices.checkSensorName(currentNodeSelected._id, sensorData.sensorName).then(res => {
                    if (res.ok === true && res.existName === false) {
                        sensorsServices.addNewSensor(
                            currentNodeSelected._id,
                            sensorData,
                        )
                            .then(res => {
                                setEditMode(false)
                                setActiveSaveButton(false)
                                setModalState({
                                    ...modalState,
                                    isOpen: !modalState.isOpen,
                                    data: {
                                        title: t("NewElement"),
                                        text: t("NewElementSavedCorrectly"),
                                        id: "newSensor",
                                        actionType: "new",
                                    },
                                });
                            })
                            .catch((err) => console.error(err))
                    } else if (res.ok === true && res.existName === true) {
                        setModalState({
                            ...modalState,
                            isOpen: !modalState.isOpen,
                            data: {
                                title: t("Error"),
                                text: t("AlreadyExistElement"),
                                id: "errorSensor",
                                actionType: "error",
                            },
                        });
                    }
                }).catch(err => console.error({ err }));
            }
        }
    }, [isSaveClicked, addNewSensor])

    return (
        <>
            {editMode ? <FormGroup>
                <Label htmlFor="sensorName">
                    {t("SensorName")}
                </Label>
                <Input
                    ariaDescribedby="sensor Name"
                    id="sensorName"
                    placeholder="Sensor name"
                    className=''
                    value={sensorData.sensorName}
                    onChange={(e) => {
                        setSensorData({ ...sensorData, sensorName: e.target.value })
                        setPositionEditObject(prev => ({
                            ...prev, // Copiar todas las propiedades existentes
                            name: e.target.value, // Actualizar solo esta propiedad
                            type: "volume"
                        }));
                        setActiveSaveButton(true)
                    }
                    }
                />

                <Label htmlFor="externalUrl">
                    {t("ExternalURL")}
                </Label>
                <br />
                {
                    sensorData.isExternalSensor ?
                        <>
                            <Input

                                ariaDescribedby="external url"
                                className={` ${sensorData.isExternalSensor ? '' : 'hidden'}`}

                                id="externalUrl"
                                placeholder="https://example.com/"
                                value={sensorData.externalSensorURL}
                                onChange={(e) => handleSensorUrl(e.target.value)}
                            />

                        </>
                        : <div id="sensorButtom">

                            <div>
                                <Label htmlFor="sensorMinValue">
                                    {t("MinimumValue")}
                                </Label>
                                <Input
                                    ariaDescribedby="sensor min value"
                                    id="sensorMinValue"
                                    placeholder="15.5"
                                    className=''
                                    value={sensorData.sensorMinValue}
                                    onChange={(e) => setSensorData({ ...sensorData, sensorMinValue: e.target.value })}
                                />
                            </div>
                            <div>
                                <Label htmlFor="sensorMAxValue">
                                    {t("MaximumValue")}
                                </Label>
                                <Input
                                    className=''
                                    ariaDescribedby="sensor max value"
                                    id="sensorMAxValue"
                                    placeholder="30.5"
                                    value={sensorData.sensorMaxValue}
                                    onChange={(e) => setSensorData({ ...sensorData, sensorMaxValue: e.target.value })}
                                />
                            </div>
                            <div>
                                <Label htmlFor="sensorMinTolerance">
                                    {t("MinimumTolerance")}
                                </Label>
                                <Input
                                    className=''
                                    ariaDescribedby="sensor minimum tolerance"
                                    id="sensorMinTolerance"
                                    placeholder="20.5"
                                    value={sensorData.sensorMinTolerance}
                                    onChange={(e) => setSensorData({ ...sensorData, sensorMinTolerance: e.target.value })}
                                />
                            </div>
                            <div>
                                <Label htmlFor="sensorMaxTolerance">
                                    {t("MaximumTolerance")}
                                </Label>
                                <Input
                                    className=''
                                    ariaDescribedby="sensor maximum tolerance"
                                    id="sensorMaxTolerance"
                                    placeholder="20.5"
                                    value={sensorData.sensorMaxTolerance}
                                    onChange={(e) => setSensorData({ ...sensorData, sensorMaxTolerance: e.target.value })}
                                />
                            </div>
                            <div>
                                <Label htmlFor="sensorUnits">
                                    {t("UnitsType")}
                                </Label>
                                <Input
                                    className=''
                                    ariaDescribedby="sensor units"
                                    id="sensorUnits"
                                    placeholder="°C"
                                    value={sensorData.sensorUnits}
                                    onChange={(e) => setSensorData({ ...sensorData, sensorUnits: e.target.value })}
                                />
                            </div>
                        </div>
                }
            </FormGroup>
                :
                <>
                    <ListGroup
                        className="mb-3"
                        ref={itemList}
                    >
                        {currentSensors.map((sensor, index) => (
                            <EditableListItem
                                key={index + sensor.sensorName}
                                name={sensor.sensorName}
                                url={sensor.externalSensorURL}
                                stateChanger={setEditMode}
                                changeName={changeName}
                                deleteAction={deleteByName}
                                setStateNotNewVolume={setAddNewSensor}
                                getElementData={getElementData}
                            >

                            </EditableListItem>
                        ))}
                    </ListGroup>
                    <div className='display-flex'>
                        <Button
                            className='grow-0 '
                            color="primary"
                            onClick={() => {
                                setAddNewSensor(true);
                                if (itemList.current.children.length > 0) {
                                    itemList.current.children[itemList.current.children.length - 1].scrollIntoView();
                                }
                                setEditMode(true)
                                setPositionEditObject(prev => ({
                                    ...prev, // Copiar todas las propiedades existentes
                                    name: sensorData.sensorName, // Actualizar solo esta propiedad
                                    type: "sensor"
                                }));
                                setSensorData({
                                    ...sensorData,
                                    sensorName: "Sensor " + (Number(lastIndex) + 1),
                                    externalSensorURL: "",
                                    sweepCameraPosition: {
                                        sweepointUUID: currentPose.sweep,
                                        cameraHorizontalRotation: currentPose.rotation.y,
                                        cameraVerticalRotation: currentPose.rotation.x,
                                    }
                                })
                            }}
                            icon="add"
                        >{t("New")}</Button>
                        <div className='grow-1'></div>
                    </div>

                </>
            }


        </>
    )
}

export default SensorProperty