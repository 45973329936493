// Login.tsx
import React from "react";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "./bootstrap/Dropdown";
import LANG, { getLangWithKey, ILang } from "../../src/lang";
import i18n from "../i18n";
import Button, { ButtonGroup, IButtonProps } from "./bootstrap/Button";
import useDarkMode from "../hooks/useDarkMode";
import Spinner from "./bootstrap/Spinner";
const LanguageSelect: React.FC = () => {
    const clickMe = () => {
        console.log("¡Botón pulsado!");
    };
    const { darkModeStatus } = useDarkMode();
    const styledBtn: IButtonProps = {
        color: darkModeStatus ? "dark" : "light",
        hoverShadow: "default",
        isLight: !darkModeStatus,
        size: "lg",
    };
    const changeLanguage = (lng: ILang['key']['lng']) => {
        i18n.changeLanguage(lng);
    };
    return (
        <div className='col-auto'>
            <Dropdown className="mt-3 floatLogin">
                <DropdownToggle hasIcon={false}>
                    {typeof getLangWithKey(i18n.language as ILang['key']['lng'])?.icon ===
                        'undefined' ? (
                        <Button
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...styledBtn}
                            className='btn-only-icon'
                            aria-label='Change language'
                            data-tour='lang-selector'>
                            <Spinner isSmall inButton='onlyIcon' isGrow />
                        </Button>
                    ) : (
                        <Button
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...styledBtn}
                            icon={
                                getLangWithKey(i18n.language as ILang['key']['lng'])?.icon
                            }
                            aria-label='Change language'
                            data-tour='lang-selector'
                        />
                    )}
                </DropdownToggle>
                <DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
                    {Object.keys(LANG).map((i) => (
                        <DropdownItem key={LANG[i].lng}>
                            <Button
                                icon={LANG[i].icon}
                                onClick={() => changeLanguage(LANG[i].lng)}>
                                {LANG[i].text}
                            </Button>
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

export default LanguageSelect;