
import axios from "axios";

const IMAGES_SERVICE = process.env.REACT_APP_SERVER_DOMAIN + "/maps/";
const LEVELS_INFO = `${process.env.REACT_APP_SERVER_DOMAIN}/maps/listMapsSpace`;
const SOURCE_MAP_INFO = `${process.env.REACT_APP_SERVER_DOMAIN}/maps/mapSource`;
const API_CALIBRATION_DATA =  `${process.env.REACT_APP_SERVER_DOMAIN}/maps/calibrationMap`;

let config = {};

const getToken = () => {
    const storageToken = localStorage.getItem("token");
  const token = storageToken === (null || undefined) ? '' : storageToken;

  config = {
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json",
    },
  };
};

const getMapImages = (model:string|null) => {
    getToken();
    return axios.post(IMAGES_SERVICE, { modelId: model }, config)
    .then(response => response.data);
};

const getLevelsInfo = (space:string | null) => {

    getToken();
    return axios.post(LEVELS_INFO, { space }, config)
    .then(response => response.data)
    .catch((error)=> {
        return error.response.data;
    });

};

const getImagesFromServer = (URI:string) => {
    return axios({
        method: 'GET',
        url: URI,
        responseType: 'arraybuffer'
    }).then(res => {
        return res;
    })
    .catch(err => {
        return err;
    });
};

const setSourceMapInfo = (data: any) => {
    getToken();
    return axios.post(SOURCE_MAP_INFO, { data }, config).
    then(response => response.data)
    .catch(err => err.response.data);
};

const setCalibrationData = (data: any) => {
    getToken();
    return axios.post( API_CALIBRATION_DATA, data, config)
    .then(response => response.data)
    .catch(err => err.response.data);
};

const miniMapServices = {
    getMapImages,
    getImagesFromServer,
    getLevelsInfo,
    setSourceMapInfo,
    setCalibrationData
};

export default miniMapServices;