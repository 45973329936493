import { ReactNode, useContext, useLayoutEffect, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import LANG, { getLangWithKey, ILang } from "../../../../../lang";
import useDarkMode from "../../../../../hooks/useDarkMode";

import { OverlayContext } from "../../../../../contexts/OverlayContext";

import Dimensions from "../../../../../interfaces/Dimensions";
import miniMapServices from "../../../../../services/Sites/miniMap/miniMapServices";

import './ProcessAndSaveInfo.scss'
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../../../components/bootstrap/Modal";
import Spinner from "../../../../../components/bootstrap/Spinner";
import { useToasts } from 'react-toast-notifications';
import Toasts from "../../../../../components/bootstrap/Toasts";
import Alert from "../../../../../components/bootstrap/Alert";





  
const ProcessAndSaveInfo: any = (props: any) => {
    const { showModalProcessInfo, setShowModalProcessInfo, resetStates, positionMatterport, positionCanvas, levelStartedProcess } = props;
    const { addToast } = useToasts();
    const { imageToUse, setImageToUse, recalibrationProcess } = useContext(OverlayContext)
    const [ modalTitle, setModalTitle ] = useState("Procesado de información");
    const [ fileDimensions, setFileDimensions ] = useState<Dimensions>({width: null, height: null});
    const [ infoToSave, setInfoToSave ] = useState({xFloorMeterSize: 0, yFloorMeterSize: 0, xOffset: 0, yOffset: 0});
    const [ imageInB64, setImageInB64 ] = useState("");
    const [ nameFile, setNameFile ] = useState("");
    const [ disabledButton, setDisabledButton ] = useState(true);
    const { t } = useTranslation('menu');
    const { i18n } = useTranslation();


    const changeLanguage = (lng: ILang['key']['lng']) => {
        i18n.changeLanguage(lng);
    };

    /**
     * Language attribute
     */
    useLayoutEffect(() => {
        document.documentElement.setAttribute('lang', i18n.language.substring(0, 2));
    });

    useEffect(() => {
        if(showModalProcessInfo === 1){
             if(fileDimensions.height === null && fileDimensions.width === null){
                 getImageDimensions();
             }else{
                 startToCalculateData();
             }
        }
     }, [showModalProcessInfo, fileDimensions]);

     useEffect(() => {
        if(imageInB64){
            savingData();
        }
    }, [imageInB64])

    const getImageDimensions = () => {
        const img = new Image();
        if(typeof imageToUse === "string"){
            img.src = imageToUse
        }else{
            img.src = URL.createObjectURL(imageToUse);
            setNameFile(imageToUse.name);
        }
        // img.src = typeof imageToUse === "string" ? imageToUse : URL.createObjectURL(imageToUse);
        
        img.onload = () => {
            setFileDimensions({width: img.width, height: img.height});
        }
    };

    const startToCalculateData = () => {

        const xRatio = getRatio("x");
        const yRatio = getRatio("y");

        const xFloorMeterSize = (1/xRatio)* (fileDimensions.width as number);
        const yFloorMeterSize = (1/yRatio)* (fileDimensions.height as number);

        
        const xOffset=calculateOffset("x", xRatio);
        const yOffset=calculateOffset("y", yRatio);

        setInfoToSave({
            xFloorMeterSize, 
            yFloorMeterSize, 
            xOffset, 
            yOffset
        });

        if(typeof imageToUse === "string"){
            setImageInB64(imageToUse);
        }else{
            startTransformImageToB64();
        }
        
    };

    const getRatio = (xOrY: string) => {

        const ratioTop = getAvgRatio(xOrY, "top");
        const ratioBottom = getAvgRatio(xOrY, "bottom");
        
        return (ratioTop + ratioBottom)/2
    };

    const getAvgRatio = (xOrY:string, position:string) => {
        
        const orderedCanvasSizes = orderNumbers([positionCanvas["right"][position][xOrY], positionCanvas["left"][position][xOrY]]);
        const pxCanvasRatio =  orderedCanvasSizes[0] - orderedCanvasSizes[1];
        
        if(xOrY === "y") xOrY="z";
        
        const orderedMatterportSizes = orderNumbers([positionMatterport["right"][position][xOrY], positionMatterport["left"][position][xOrY]]);
        const metersMatterportRatio = orderedMatterportSizes[0] - (orderedMatterportSizes[1]);

        return (pxCanvasRatio / metersMatterportRatio);
    };

    const orderNumbers = (arrayOfNumbers: Array<number>): Array<number> => {
        const sortedArray = [...arrayOfNumbers].sort((a:any, b:any) => a - b);
        return sortedArray.reverse();
    };

    const calculateOffset = (xOrY:string, ratio:number) => {
        
        let matterportValue = xOrY;
        if(xOrY === "y") matterportValue="z";

        const offSetTopRight = positionCanvas.right.top[xOrY] - (positionMatterport.right.top[matterportValue] * ratio);
        const offSetTopLeft = positionCanvas.left.top[xOrY] - (positionMatterport.left.top[matterportValue] * ratio);

        
        const offSetBottomRight = positionCanvas.right.bottom[xOrY] - (positionMatterport.right.bottom[matterportValue] * ratio);
        const offSetBottomLeft = positionCanvas.left.bottom[xOrY] - (positionMatterport.left.bottom[matterportValue] * ratio);
        
        return Math.floor((offSetTopRight + offSetTopLeft + offSetBottomRight + offSetBottomLeft)/4);
    };

    const startTransformImageToB64 = () => {
        const reader = new FileReader();
        reader.readAsDataURL(imageToUse);
        reader.onload = function(event: any) {
            setImageInB64((event.target.result as string));
        };  
    };

    const savingData = () => {

        const model = localStorage.getItem("model");
        const space = localStorage.getItem("space");
        const currentFloor = Number(levelStartedProcess);
        const dataTosend = {
            model,
            currentFloor,
            space,
            nameFile,
            imgB64: imageInB64,
            xSizeM: infoToSave.xFloorMeterSize,
            ySizeM: infoToSave.yFloorMeterSize,
            xSizePx: fileDimensions.width,
            ySizePx: fileDimensions.height,
            xOffset: infoToSave.xOffset,
            yOffset: infoToSave.yOffset,
            recalibrationProcess
        };
        
        
        miniMapServices.setCalibrationData(dataTosend).then(res=>{
            if(res.ok === true) {
                
                genericToast("success", titleForToast(), t("SuccessConfigMap"), 5000);
                resetLocalInfo();
            }else{
                throw new Error('Something went wrong');
            } 
        }).catch(err => {
            genericToast("danger", titleForToast(), t("ErrorConfigMap"), 5000)
            resetLocalInfo();
        });
    };

    const titleForToast = ()=> {
        return recalibrationProcess ? t("Recalibration"): t("UploadNewImageMap")
    }

    const genericToast = (typeColor:string, title:string, message:string, timeout:number) => {
		addToast(
			<Toasts
				title={title}
				isDismiss={ false }>
				{alertToShow(typeColor, message)}
			</Toasts>,
			{
				autoDismiss: true ,
				autoDismissTimeout: timeout 
			}
		)
	};

    const alertToShow = (typeColor:any, message:string) =>{
		return(
			<Alert
				color={ typeColor }
				isLight={ false }
				isOutline={ false }
				borderWidth={ 5 }
				isDismissible={ false }
				shadow={ null } 
				rounded={ 3 }
				>
				{message}
			</Alert>
		)
	}

    const resetLocalInfo = () => {
        setImageToUse(undefined);
        setFileDimensions({ height: null, width:null});
        setModalTitle("Procesado de información");
        setInfoToSave({xFloorMeterSize: 0, yFloorMeterSize: 0, xOffset: 0, yOffset: 0});
        setImageInB64("");
        resetStates();
    };

    return (
        <Modal 
        id="modalConfig"
        titleId="titleConfig"
        isOpen={ showModalProcessInfo } // Example: state
        setIsOpen={ setShowModalProcessInfo } // Example: setState
        isStaticBackdrop={ true } 
        isScrollable={ false } 
        isCentered={true} 
        fullScreen= 'md' 
        isAnimation={ true }
        >
            <ModalHeader>
                <ModalTitle id="processInfoTitle">{modalTitle}</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <div className='processInfoText mb-3'>
                    <h5>{t("SavingInfo")}</h5>
                    <Spinner
                        tag={ 'span' } // 'div' || 'span'
                        color={ 'primary' } 
                        size={ "5vh" } 
                        className="mt-3"
                        />
                </div>
            </ModalBody>
            {/* <ModalFooter>
                <button type='button' className={`btn btn-primary $`} disabled={disabledButton} onClick={resetLocalInfo}>{t("FinishProcess") as ReactNode}</button>
            </ModalFooter> */}
        </Modal>
    );

};

export default ProcessAndSaveInfo;
