
import axios from "axios";

const API_URL_DOCUMENTS = process.env.REACT_APP_DOMAIN + '/api/documents/';
const API_URL_CHECKNAME = API_URL_DOCUMENTS + 'nameExist'

let config = {};

const getToken = () => {
    const storageToken = localStorage.getItem("token");
  const token = storageToken === (null || undefined) ? '' : storageToken;

  config = {
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json",
    },
  };
};

const getTokenDelete = () => {
    const storageToken = localStorage.getItem("token");
    return storageToken === (null || undefined) ? '' : storageToken;
}

const getAllPropierties = async (idParent) => {
    getToken();
    const response = await axios.post(API_URL_DOCUMENTS, JSON.stringify({ idParent }), config);
    return response.data;
};
const checkName = async (idParent, nameToValidate) => {
    getToken();
    const response = await axios.post(API_URL_CHECKNAME, JSON.stringify({ idParent, nameToValidate }), config);
    return response.data;
};
const insertProperties = async (idParent, documentsData) => {
    getToken();
    const response = await axios.put(API_URL_DOCUMENTS, JSON.stringify({ idParent, documentsData }), config);
    return response.data;
};
const deleteProperty = async (idParent, propertyName) => {
    const token = getTokenDelete();
    const response = await axios.delete(API_URL_DOCUMENTS,
        {
            data: { idParent, propertyName }, headers: {
                'x-auth-token': token,
                'Content-Type': 'application/json'
            }
        });

    return response.data;
};


const documentsServices = {
    getAllPropierties,
    checkName,
    insertProperties,
    deleteProperty

};

export default documentsServices;
