import React, { useContext, useState, useEffect } from 'react';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Button from '../bootstrap/Button';
import Modal, { ModalHeader, ModalTitle, ModalBody, ModalFooter } from '../bootstrap/Modal';
import matterportHelper from '../../helpers/matterportHelper';
import modelsServices from '../../services/modelsServices';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ButtonGroup } from '../bootstrap/Button';
import ThemeContext from '../../contexts/themeContext';
import { CardFooterLeft } from '../bootstrap/Card';
import { OverlayContext } from '../../contexts/OverlayContext';
import { useTranslation } from "react-i18next";

const ModalInput = (props) => {

    const { showModalI, updateModelList } = props;
    const { mobileDesign } = useContext(ThemeContext);
    const { setModalState, modalState } = useContext(OverlayContext);
    const { t } = useTranslation('menu');
    const [showModal, setShowModal] = useState({
        show: false,
        title: "title",
        message: "message",
        nameOnButton: "",
        typeButton: "",
        typeAction: "",
        dataObj: "",
        name: showModalI.name,
        prevName: showModalI.name,
        file: ""
    });
    const [modelList, setModelList] = useState([
        {
            name: '',

        },

    ])
    const formik = useFormik({

        initialValues: {
            name: showModal.name,
            fileName: "",

        },
        validationSchema: Yup.object({
            name: Yup.string().required('Obligatorio'),
            fileName: Yup.string()
                .max(20, 'Máximo 20 carácteres')
                .required('Obligatorio'),
        }),
        onSubmit: values => {

            console.log({ values });

        },
        onChange: value => {
            console.log({ value });
        }
    });
    useEffect(() => {
        setShowModal(showModalI)

    }, [showModalI])
    const openModal = function (flag, type, data) {

        switch (type) {
            case "reset":
                showModal.show = flag;
                showModal.title = "Restauración de contraseña";
                showModal.nameOnButton = "Restablecer";
                showModal.typeButton = "info";
                showModal.typeAction = "reset";
                // showModal.dataObj = JSON.stringify(data);
                break;

            case "delete":
                showModal.show = flag;
                showModal.title = "Eliminación";
                showModal.nameOnButton = "Eliminar Usuario";
                showModal.typeButton = "danger";
                showModal.typeAction = "delete";
                // showModal.dataObj = JSON.stringify(data);
                break;

            case "newUser":
                showModal.show = flag;
                showModal.title = "Alta de usuario";
                showModal.nameOnButton = "Dar de alta"
                showModal.typeButton = "success";
                // showModal.typeAction = "new";
                break;

            case "editUser":
                showModal.show = flag;
                showModal.title = "Editar usuario";
                showModal.nameOnButton = "Modificar usuario"
                showModal.typeButton = "success";
                showModal.typeAction = "edit";
                //  showModal.dataObj = JSON.stringify(data);
                break;

            default:
                showModal.show = flag;
                break;
        }

        setShowModal({
            ...showModal
        });
    }
    const closeModal = () => {
        openModal(false, "close");
        setShowModal({
            show: false,
            title: "title",
            message: "message",
            nameOnButton: "",
            typeButton: "",
            typeAction: "",
            dataObj: "",
            name: "",
            file: ""
        })
    }

    const renderForm = (showPass, data) => {
        return (
            <>
                <form onSubmit={formik.handleSubmit}>

                    <FormGroup
                        tag={'div'}
                        isFloating={false}
                        size={'sm'}
                        isColForLabel={true}
                    >
                        <label htmlFor="name"><strong>Nombre</strong></label>
                        <Input
                            type='text'
                            id={'name'}
                            name={'name'}
                            onChange={(e) => {
                                console.log({ e })
                                setShowModal({
                                    ...showModal,
                                    name: e.target.value,

                                })
                            }}
                            // onBlur={() => checkRegisterMail()}
                            value={showModal.name}
                            autoComplete='off'
                        />
                    </FormGroup>
                    <br></br>

                    <br></br>
                    {
                        showPass &&
                        (
                            <FormGroup
                                tag={'div'}
                                isFloating={false}
                                size={'sm'}
                                isColForLabel={true}
                            >
                                <label htmlFor="password"><strong>Contraseña</strong></label>
                                <Input
                                    type='password'
                                    id={'userSecret'}
                                    name={'userSecret'}
                                    onChange={formik.handleChange}
                                    value={formik.values.userSecret}
                                    autoComplete='off'
                                    max={16}
                                    disabled={false}
                                />
                                <p></p>
                                <br></br>
                                <p>La contraseña debe contener lo siguiente: <br></br><br></br>
                                    * 1 Mayúscula. &nbsp; &nbsp; * 2 Carácteres especiales.
                                    <br></br>
                                    * 4 Minúsculas. &nbsp; * Mínimo 8 y máximo 16 carácteres.
                                    <br></br>
                                    <br></br>
                                    * No incluir números consecutivos. <br></br>
                                    * No incluir correo y nombre de usuario. <br></br>
                                    * No incluir malas palabras.
                                </p>
                            </FormGroup>
                        )
                    }
                    <br></br>
                    {
                        buttonActionToShow(showModal.nameOnButton, showModal.typeButton, showModal.typeAction, showModal.dataObj)
                    }
                </form>
            </>
        );
    }
    function removeFileExtension(fileName) {
        // Encuentra la última aparición del punto (.) en el nombre del archivo
        const lastIndex = fileName.lastIndexOf('.');

        // Si el punto no se encuentra o está al principio del nombre, no hay extensión
        if (lastIndex === -1 || lastIndex === 0) {
            return fileName;
        }

        // Retorna el nombre del archivo sin la extensión
        return fileName.substring(0, lastIndex);
    }
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const maxSizeMB = 15;
        if (file) {
            const fileName = removeFileExtension(file.name);
            const extension = file.name.split('.').pop().toLowerCase();
            if (file.size > (maxSizeMB * 1024 * 1024)) {

                setModalState({
                    ...modalState,
                    isOpen: !modalState.isOpen,
                    data: {
                        title: "Error",
                        text: t("fileLimitMessage"),
                        id: "errorModel",
                        actionType: "error",
                    },
                });
            } else {
                if (extension === 'fbx' || extension === 'gltf') {
                    setShowModal({
                        ...showModal,
                        name: fileName
                    })
                } else {
                    setModalState({
                        ...modalState,
                        isOpen: !modalState.isOpen,
                        data: {
                            title: "Error",
                            text: t("validModelFileError"),
                            id: "errorModel",
                            actionType: "error",
                        },
                    });
                }
            }


        }
    };
    const actionEvent = (typeAction, data) => {


        modelsServices.updateModelFileName(showModal.prevName, showModal.name).then(res => {
            if (res.ok) {
                updateModelList()
                // alert("Actualizado")
            } else {
                console.error({ err: "could not update" })
            }
        })
        console.log({ showModal })
        if (typeAction == "delete") {
            // deleteUser(5000, data);

            closeModal();
        } else {
            //resetSecret(5000, data);
            closeModal();
        }
    };

    function convertToBase64() {
        const fileInput = document.getElementById('fileInput');
        const file = fileInput.files[0];

        if (!file) {
            console.error('No file selected.');
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);


        reader.onload = function () {
            const base64String = reader.result.split(',')[1]; // Remove 'data:[<media type>];base64,'

            console.log('Base64 representation of the file:', base64String);



            setShowModal({
                ...showModal,
                file: base64String
            })
            modelsServices.addModelFileName(showModal.name, file.name, base64String).then(res => {
                updateModelList();
                closeModal();
                setTimeout(() => {
                    setModalState({
                        ...modalState,
                        isOpen: !modalState.isOpen,
                        data: {
                            title: t("NewElement"),
                            text: t("NewElementSavedCorrectly"),
                            id: "newHierarchy",
                            actionType: "new",
                        },
                    });
                }, 500);

            })

            // Aquí puedes hacer lo que necesites con la representación base64 del archivo
        };
        reader.onerror = function (error) {
            console.error('Error reading the file:', error);
        };
    }
    const buttonActionToShow = (nameButton, type, typeAction, data) => {
        console.log({ typeAction })
        console.log({ data })
        return (
            <ModalFooter
                className='display-flex'
            >
                {(typeAction == "delete" || typeAction == "reset") &&
                    <div className='centeredButton' id="ModelFileInputContainer">
                        <ButtonGroup
                            className={mobileDesign ? "btn-group-vertical" : ""}
                        >
                            <Button color="primary" isLight={true}
                                onClick={(e) => {
                                    e.preventDefault();
                                    actionEvent(typeAction, JSON.parse(data))
                                    // alert("Clcik")
                                }}>
                                {nameButton}
                            </Button>
                            <Button
                                color="secondary"
                                onClick={(e) => {
                                    e.preventDefault();
                                    closeModal();
                                }}>
                                Regresar
                            </Button>
                        </ButtonGroup>
                    </div>
                }
                {
                    (showModal.typeAction == "new" || showModal.typeAction == "edit") &&
                    <div className='centeredButton' id="ModelFileInputContainer">
                        <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileUpload} />
                        <Button
                            color='info' isLight={true}
                            onClick={() => {


                                document.getElementById('fileInput').click()

                            }}
                        >
                            Subir Archivo
                        </Button>
                        <br />
                        <ButtonGroup className={mobileDesign ? "btn-group-vertical" : ""}>
                            <Button
                                color="primary" isLight={true}
                                onClick={(e) => {
                                    e.preventDefault();
                                    closeModal();
                                }}>
                                Regresar
                            </Button>
                            <Button color="secondary" type="submit"
                                onClick={(e) => {
                                    e.preventDefault();
                                    actionEvent(typeAction, showModal)
                                }}
                            >
                                {nameButton}
                            </Button>

                        </ButtonGroup>
                    </div>
                }

                {
                    (showModal.typeAction == "newFile") &&
                    <div className='centeredButton' id="ModelFileInputContainer">
                        <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileUpload} />
                        <Button
                            color='info' isLight={true}
                            onClick={() => {


                                document.getElementById('fileInput').click()

                            }}
                        >
                            Subir Archivo
                        </Button>
                        <br />
                        <ButtonGroup
                            className={mobileDesign ? "btn-group-vertical" : ""}
                        >
                            <Button
                                color="primary" isLight={true}
                                onClick={(e) => {
                                    e.preventDefault();
                                    closeModal();
                                }}>
                                Regresar
                            </Button>
                            <Button color="secondary" type="submit" type="submit" isLight={false}
                                onClick={(e) => {
                                    e.preventDefault();
                                    convertToBase64();
                                }}
                            >
                                {nameButton}
                            </Button>
                        </ButtonGroup>
                    </div>
                }
            </ModalFooter>
        )
    }
    return (
        <div id="modalInput">
            <Modal
                isCentered={true}
                isOpen={showModal.show}
                size="lg"
                setIsOpen={function () { return false }}
                id="example-modal"
                titleId="example-title"
            >
                <ModalHeader>
                    <ModalTitle id="example-title">
                        <strong>{showModal.title}</strong>
                        {/*<Button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></Button>*/}
                    </ModalTitle>
                </ModalHeader>
                <ModalBody
                    id="modelFIleInputModal"
                >
                    <div>

                        {renderForm()}
                    </div>








                </ModalBody>
                {(showModal.typeAction == "delete" || showModal.typeAction == "reset") &&

                    buttonActionToShow(showModal.nameOnButton, showModal.typeButton, showModal.typeAction, showModal.dataObj)}
            </Modal>
        </div>
    );
}

export default ModalInput;
