import React, { useContext, useEffect, useState } from 'react'
import Input from '../bootstrap/forms/Input'
import ListGroup, { ListGroupItem } from '../bootstrap/ListGroup';
import Label from '../bootstrap/forms/Label';
import { OverlayContext } from '../../contexts/OverlayContext';
import hierachyServices from '../../services/hierachyServices';
import hierarchyHelper from '../../helpers/hierarchyHelper';
import Button, { ButtonGroup } from '../bootstrap/Button';
import { useTranslation } from "react-i18next";

function ParentChanger
    (props) {
    const { hierachyData, updateCurrentParent, closeEdit } = props;
    const palabras = [''];
    const [inputValue, setInputValue] = useState('');
    const [sugerencias, setSugerencias] = useState([]);
    const [mostrarSugerencias, setMostrarSugerencias] = useState(false);
    const [newParent, setNewParent] = useState();
    const [currentParent, setCurrentParent] = useState("");
    const {
        currentNodeSelected,
        setModalState,
        setUpdateHierarchy,
        modalState,
        autoCompleteList,
        setautoCompleteList,

    } = useContext(OverlayContext);
    const [firstRender, setFirstRender] = useState(true)
    const [activeMoveButton, setactiveMoveButton] = useState(false)

    const { t } = useTranslation('menu');

    const handleChange = (e) => {

        const valor = e.target.value;
        if (typeof valor === "string") {
            setInputValue(valor);
        }
        if (valor === "") {
            setactiveMoveButton(false);
        }

        if (autoCompleteList !== undefined) {

            const fiterByQuery = autoCompleteList.objects.filter(objeto => objeto.name.toLowerCase().includes(valor.toLowerCase()));
            console.log({ fiterByQuery });
            if (fiterByQuery.length > 0) {
                setSugerencias(fiterByQuery);
                setMostrarSugerencias(true);
            } else {
                setSugerencias({ msg: t("ParentNotFound") })
            }
        }

    };
    useEffect(() => {
        if (firstRender) {
            setFirstRender(false);
            if (autoCompleteList === undefined) {
                hierachyServices
                    .getHierachyList(space + "")
                    .then((res) => {
                        if (res.ok) {
                            const parentItem = {
                                _id: space,
                                name: site,
                                parentOID: "space",
                                hierarchyLevel: 0,
                            };
                            res.objects.push(parentItem);
                            localStorage.setItem("searchList", JSON.stringify(res));

                            setautoCompleteList(res);
                            console.log({ hierachyData: res });

                        }
                    })
                    .catch((err) => {
                        console.error({ err });
                        setautoCompleteList(undefined);
                    });
            }
            if (currentNodeSelected.hierarchyLevel !== 1) {
                hierachyServices.elementDetails(currentNodeSelected.parentOID)
                    .then(res => {
                        if (res.ok) {
                            setCurrentParent(res.msg.name);
                        }
                    }).catch(err => console.log({ err }))
            } else {
                if ('dataJson' in localStorage) {
                    const dataJson = JSON.parse(localStorage.getItem("dataJson"))
                    setCurrentParent(dataJson.root.spaceName);
                } else {
                    setCurrentParent("ROOT");
                }
            }
        }

    }, [])
    const handleClickSugerencia = (sugerencia) => {
        setInputValue(sugerencia.name);
        setMostrarSugerencias(false);
        setactiveMoveButton(true);
    };
    const handldeSaveNewParent = () => {
        if (newParent !== undefined) {
            setModalState({
                ...modalState,
                isOpen: !modalState.isOpen,
                continueClicked: false,
                data: {
                    title: t("Move"),
                    text: t("ConfirmationTextMoveParent"),
                    id: "deleteElement",
                    actionType: "move",
                    elementName: newParent
                },
            });
        }
    }
    useEffect(() => {
        if (modalState.continueClicked && modalState.data.actionType === "move") {
            setModalState({
                ...modalState,
                continueClicked: false,
            });
            if (currentNodeSelected !== undefined && newParent !== undefined) {

                hierachyServices.SetNewParentObject(
                    String(currentNodeSelected._id),
                    String(newParent._id))
                    .then(res => {
                        if (res.ok) {
                            setCurrentParent(newParent.name);
                            setUpdateHierarchy(true)
                            hierarchyHelper.restartHierarchyData();
                            setModalState({
                                ...modalState,
                                isOpen: !modalState.isOpen,
                                data: {
                                    title: t("UpdateTitle"),
                                    text: t("UpdatedElementCorrectly"),
                                    id: "newMOdel",
                                    actionType: "edit",
                                },
                            });

                            hierachyServices
                                .getHierachyList(space + "")
                                .then((res) => {
                                    if (res.ok) {
                                        const parentItem = {
                                            _id: space,
                                            name: site,
                                            parentOID: "space",
                                            hierarchyLevel: 0,
                                        };
                                        res.objects.push(parentItem);
                                        localStorage.setItem("searchList", JSON.stringify(res));
                                        console.log({ hierachyData1: res });
                                        setautoCompleteList(res);
                                    }
                                })
                                .catch((err) => {
                                    console.error({ err });
                                    setautoCompleteList(undefined);
                                });
                        } else {
                            console.log({ err: res })
                        }
                    }).catch((error) => {
                        console.log({ error })
                        if (error.response !== undefined) {
                            if (error.response.data.ok === false) {

                                let errorMsg;
                                switch (error.response.data.msg) {
                                    case "OwnParent":
                                        errorMsg = t("OwnParent");
                                        break;
                                    case "AlreadyHasThisParent":
                                        errorMsg = t("AlreadyHasThisParent");
                                        break;
                                    case "ExistSameNameOnParent":
                                        errorMsg = t("ExistSameNameOnParent");
                                        break;
                                    case "OwnChild":
                                        errorMsg = t("OwnChild");
                                        break;
                                    case "ParentElementNotExist":
                                        errorMsg = t("ParentElementNotExist");
                                        break;

                                    default:
                                        errorMsg = t("UnexpectedError");
                                        break;
                                }
                                setModalState({
                                    ...modalState,
                                    isOpen: !modalState.isOpen,
                                    data: {
                                        title: "Error",
                                        text: errorMsg,
                                        id: "errorModel",
                                        actionType: "error",
                                    },
                                });
                            }
                        }
                    })
            }
        }
    }, [modalState])

    return (
        <div>
            <Label size="lg">
                {t("CurrentParent")}:
            </Label>
            {` ${currentParent} `}
            <br />
            <Label size="lg">
                {t("NewParent")}:
            </Label>
            {` ${newParent ? newParent.name : ""} `}
            <br />
            <Input
                type="text"
                value={inputValue}
                onChange={handleChange}
                placeholder={t("WriteNameParent")}
            />

            {mostrarSugerencias && (

                <ListGroup >
                    {Array.isArray(sugerencias) ?
                        sugerencias.map((sugerencia, index) => (
                            <ListGroupItem
                                key={index + sugerencia.name}
                                className='pointer-hand'
                                onClick={() => {
                                    updateCurrentParent(sugerencia)
                                    handleClickSugerencia(sugerencia)
                                    setNewParent(sugerencia)
                                }}
                            >
                                {sugerencia.name}
                            </ListGroupItem>
                        ))
                        :
                        <ListGroupItem isDisable>
                            {sugerencias.msg}
                        </ListGroupItem>
                    }

                </ListGroup>


            )}
            {
                activeMoveButton ?
                    <ButtonGroup id="moveHierachy"
                        className='mt-3'
                    >
                        <Button
                            color="secondary"
                            icon="system_update_alt"
                            onClick={handldeSaveNewParent}
                        >
                            {t("Move")}
                        </Button>
                    </ButtonGroup>
                    : <></>
            }
        </div>
    )
}

export default ParentChanger