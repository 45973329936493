import React, { lazy, useEffect, useContext } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import contents from "../../routes/contentRoutes";
import { OverlayContext } from "../../contexts/OverlayContext";
const PAGE_404 = lazy(() => import("../../pages/presentation/auth/Page404"));

const ContentRoutes = () => {
  const location = useLocation();
  const { setToolbarIsOpen } = useContext(OverlayContext);
  /*
  contents.forEach((page) => {
   console.log(page);
  });*/
  useEffect(() => {
    //console.log(`La ruta ha cambiado a ${location.pathname}`);
    if (location.pathname === "/") {
      setToolbarIsOpen(false);
    }
  }, [location]);

  return (
    <Routes>
      {contents.map((page) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Route key={page.path} {...page} />
      ))}

      <Route path="/assets" element={null} />
      {<Route path="*" element={<PAGE_404 />} />}
    </Routes>
  );
};

export default ContentRoutes;
