import { ReactNode, useContext, useLayoutEffect, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import LANG, { getLangWithKey, ILang } from "../../../../lang";
import useDarkMode from "../../../../hooks/useDarkMode";

import { OverlayContext } from "../../../../contexts/OverlayContext";


import ImageModalSection from "./ImageModalSection/ImageModalSection";
import PointSelection from "./PointSelection/PointSelection";
import ProcessAndSaveInfo from "./ProcessAndSaveInfo/ProcessAndSaveInfo";
  
const ChangeMapModal: any = (props: any) => {
  
  const { currentFloor, mapSourceCurrentFloor, currentImage, setImageToUse, stageModal, setStageModal, openModalImage, setOpenModalImage  } = useContext(OverlayContext);
  const { imageToUse, setRecalibrationProcess } = useContext(OverlayContext);
  const [ showModalImage, setShowModalImage ] = useState(false);
  const [ showModalProcessInfo, setShowModalProcessInfo ] = useState(0);
  const [ showModalPointSelector, setShowModalPointSelector ] = useState(0);
  const [ positionToSelect, setPositionToSelect ] = useState('');
  const [ localStage, setLocalStage ] = useState(0);
  const [ levelStartedProcess, setLevelStartedProcess ] = useState(-1);
  const { t } = useTranslation('menu');
  const { i18n } = useTranslation();

    const [ positionMatterport, setPositionMatterport ] = useState({
    left:{
        top: {
            x: 0,
            z: 0,
        },
        bottom: {
            x: 0,
            z: 0,
        }
    },
    right:{
        top: {
            x: 0,
            z: 0,
        },
        bottom: {
            x: 0,
            z: 0,
        }
    }
    });

    const [ positionCanvas, setPositionCanvas ] = useState({
        left:{
            top: {
                x: 0,
                y: 0,
            },
            bottom: {
                x: 0,
                y: 0,
            }
        },
        right:{
            top: {
                x: 0,
                y: 0,
            },
            bottom: {
                x: 0,
                y: 0,
            }
        }
    });

  const changeLanguage = (lng: ILang['key']['lng']) => {
      i18n.changeLanguage(lng);
  };

  /**
   * Language attribute
   */
  useLayoutEffect(() => {
      document.documentElement.setAttribute('lang', i18n.language.substring(0, 2));
  });

  const changeActiveModal = () => {
    setOpenModalImage(!openModalImage);
  }

  useEffect(() => {
    if(openModalImage){
        setLevelStartedProcess(currentFloor+1);
        if(stageModal < 0){
            setShowModalImage(false);
        }else if(stageModal > 0){
            setImageToUse(currentImage);
            setRecalibrationProcess(true);
            changeStepAndTakeAction();
        }else{
            setShowModalImage(true);
        }
    }
  }, [openModalImage]);

  const changeStepAndTakeAction = () => {

    const newStep = localStage + 1;

    if(newStep >= 1 && newStep <5){
        setLocalStage(newStep);
        setStageModal(newStep);
        setPositionSelection(newStep);
        setShowModalPointSelector(newStep);
    }

    if(newStep === 5){
        finishProcess();
    }
  };

  const setPositionSelection = (currentStep: number) => {
    switch(currentStep){
        case 1: 
            setPositionToSelect("upperLeft");    
            break;
        
        case 2: 
            setPositionToSelect("upperRight");    
            break;
        
        case 3: 
            setPositionToSelect("bottomRight");
            break;
        
        case 4: 
            setPositionToSelect("bottomLeft");    
            break;
        default:
            break;
    }
}

  const finishProcess = () => {
    console.log("finishProcess");
    setShowModalProcessInfo(1);
  };

    const saveDataFromImageStep = () => {
        setShowModalImage(false);
        console.log("after been saved", imageToUse);
        changeStepAndTakeAction();
    };

    const resetStates = () => {
        setImageToUse(undefined);
        setRecalibrationProcess(false);
        setPositionToSelect('');
        setShowModalImage(false);
        setOpenModalImage(false);
        setShowModalProcessInfo(0);
        setLocalStage(0);
        setStageModal(-1);
        setLevelStartedProcess(-1);
    };

    const savePositionOnMainObject = (objectToSave:any, isMatterport:any) => {
        switch(positionToSelect){
            case "upperLeft": 
                isMatterport ?
                    setPositionMatterport({...positionMatterport, left: {...positionMatterport.left, top: {x: objectToSave.x, z: objectToSave.z}}})
                    :
                    setPositionCanvas({...positionCanvas, left: {...positionCanvas.left, top: {x: objectToSave.x, y: objectToSave.y}}})
                break;
            
            case "upperRight":
                isMatterport ?
                    setPositionMatterport({...positionMatterport, right: {...positionMatterport.right, top: {x: objectToSave.x, z: objectToSave.z}}})
                    :
                    setPositionCanvas({...positionCanvas, right: {...positionCanvas.right, top: {x: objectToSave.x, y: objectToSave.y}}})
                break;
            
            case "bottomRight": 
                isMatterport ?
                    setPositionMatterport({...positionMatterport, right: {...positionMatterport.right, bottom: {x: objectToSave.x, z: objectToSave.z}}})
                    :
                    setPositionCanvas({...positionCanvas, right: {...positionCanvas.right, bottom: {x: objectToSave.x, y: objectToSave.y}}})
                break;
            
            case "bottomLeft": 
                isMatterport ?
                    setPositionMatterport({...positionMatterport, left: {...positionMatterport.left, bottom: {x: objectToSave.x, z: objectToSave.z}}})
                    :
                    setPositionCanvas({...positionCanvas, left: {...positionCanvas.left, bottom: {x: objectToSave.x, y: objectToSave.y}}})
                break;
            default:
                break;
        }


    }

    const saveInfoPosition = (matterport:any, canvas:any) => {
        savePositionOnMainObject(matterport, true);
        savePositionOnMainObject(canvas, false);
        setShowModalPointSelector(0)
        changeStepAndTakeAction();
    }

  return (
    <>
        <ImageModalSection showImageSelection={showModalImage} setShowModalImageSelection={setShowModalImage} resetStates={resetStates} title={t("UploadNewImageMap")} saveData={saveDataFromImageStep} />
        <PointSelection showModalPointSelector={showModalPointSelector} resetStates={resetStates} positionToSelect={positionToSelect} saveInfoPosition={saveInfoPosition} levelStartedProcess={levelStartedProcess}/>
        <ProcessAndSaveInfo showModalProcessInfo={showModalProcessInfo} setShowModalProcessInfo={setShowModalProcessInfo} resetStates={resetStates} positionMatterport={positionMatterport} positionCanvas={positionCanvas} levelStartedProcess={levelStartedProcess} ></ProcessAndSaveInfo>
    </>
  );
};

export default ChangeMapModal;
