import { ReactNode, useContext, useLayoutEffect, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import LANG, { getLangWithKey, ILang } from "../../../lang";
import useDarkMode from "../../../hooks/useDarkMode";

import { MatterportContext } from "../../../contexts/MatterportContext";
import { OverlayContext } from "../../../contexts/OverlayContext";

import miniMapServices from "../../../services/Sites/miniMap/miniMapServices";

import '../../../styles/components/layout/_commonHeaderRight.scss'
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../components/bootstrap/Modal";
import { useFormik } from "formik";
import Checks from "../../../components/bootstrap/forms/Checks";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import { useToasts } from 'react-toast-notifications';
import Toasts from '../../../components/bootstrap/Toasts';
import Alert from '../../../components/bootstrap/Alert';
import ModalMsgErrorImage from "./ModalMsgErrorImage/ModalMsgErrorImage";
import ChangeMapModal from "./ChangeMapModal/ChangeMapModal";
  
const MenuConfigureModalMap: any = () => {

  const { activeConfigModal, setActiveConfigModal } = useContext(MatterportContext);
  const { currentFloor, mapSourceCurrentFloor, setMapSourceCurrentFloor, currentImage, setStageModal, setOpenModalImage, fileNameConfigProcess } = useContext(OverlayContext);
  const [ isDisabled, setIsDisabled] = useState(false);
  const [ showModalMsgErrorImage, setShowModalMsgErrorImage] = useState(false);
  const [ isMatterportSelected, setIsMatterportSelected] = useState(false);
  const { addToast } = useToasts();
  const { t } = useTranslation('menu');


  const { i18n } = useTranslation();

  const changeLanguage = (lng: ILang['key']['lng']) => {
      i18n.changeLanguage(lng);
  };

  /**
   * Language attribute
   */
  useLayoutEffect(() => {
      document.documentElement.setAttribute('lang', i18n.language.substring(0, 2));
  });

    
  
  const changeActiveConfigModal = () => {
    setActiveConfigModal(!activeConfigModal);
  }

  const optionsImageForm = useFormik({
    initialValues: {
      imgSelection: '',
    },
    onSubmit: () => {
      
      setIsDisabled(true);
      const space = localStorage.getItem('space');
      const level = isNaN(Number(currentFloor)) ? -10 : (Number(currentFloor)+1);
      const optionMap = optionsImageForm.values.imgSelection;
      
      sendInformationMap(space, level, optionMap);

    },
  });

  const validateSourceMapFloor = () => {
    return mapSourceCurrentFloor === "" ? 'database' : mapSourceCurrentFloor; 
  }

  const checkEnableButtons = () => {
    validateSourceMapFloor() === 'matterport' ? setIsMatterportSelected(true) : setIsMatterportSelected(false);
  };

  const setValuesToForm = () => {
    optionsImageForm.setValues({
      imgSelection: validateSourceMapFloor(),
    });
  }

  useEffect(() => {
    console.log("FileName", fileNameConfigProcess);
    checkEnableButtons();
    setValuesToForm();
  }, [activeConfigModal, mapSourceCurrentFloor]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    optionsImageForm.handleChange(event);
    optionsImageForm.submitForm();
  };

  const sendInformationMap = (space:string | null, level: number, optionMap: string) => {
    miniMapServices.setSourceMapInfo({space, level, optionMap})
    .then(res => {
      setIsDisabled(false);
      
      if(res.ok){
        setMapSourceCurrentFloor(optionMap);
        genericToast("success", t("EditRecalibrateMap"), t('CorrectSrcImageMap'), 5000);
        
      }else if(res.msg === 'LevelSpaceError'){
        genericToast("danger", t("EditRecalibrateMap"), t('ProblemLevelSrcImageMap'), 5000)
        checkEnableButtons();
      }else{
        throw new Error();
      }
    })
    .catch(err => {
      genericToast("danger",t("EditRecalibrateMap"), t('GenericProblemSrcImageMap'), 5000);
      checkEnableButtons();
    });
  };

  const genericToast = (typeColor:string, title:string, message:string, timeout:number) => {
    addToast(
      <Toasts
        title={title}
        isDismiss={ false }>
        {alertToShow(typeColor, message)}
      </Toasts>,
      {
        autoDismiss: true ,
        autoDismissTimeout: timeout 
      }
    )
  };

  const alertToShow = (typeColor:any, message:string) =>{
    return(
      
      <Alert
        color={ typeColor }
        isLight={ false }
        isOutline={ false }
        borderWidth={ 5 }
        isDismissible={ false }
        shadow={ null } 
        rounded={ 3 }
        >
        {message}
      </Alert>
    )
  }

  const openChangeMapModal = (stage: number, recalibration: boolean) => {
    
    if(recalibration && (currentImage === "" || currentImage === null || currentImage === undefined)){
      changeActiveConfigModal();
      setShowModalMsgErrorImage(true);
    }else{
      setStageModal(stage);
      setOpenModalImage(true);
      changeActiveConfigModal();
    }
  };

  const hasFileName = () => {
    return fileNameConfigProcess === (undefined || '');
  }

  return (
    <>
      <Modal 
      id="modalConfig"
      titleId="titleConfig"
      isOpen={ activeConfigModal } // Example: state
      setIsOpen={ changeActiveConfigModal } // Example: setState
      isStaticBackdrop={ true } 
      isScrollable={ false } 
      isCentered={true} 
      fullScreen= 'md' 
      isAnimation={ true }>
      <ModalHeader 
        /* className={ String } */
        setIsOpen={ changeActiveConfigModal} // Example: setState
        >
        <ModalTitle id="EditRecalibrateMap">{t("EditRecalibrateMap") as ReactNode}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className="topBodyModalConfig">
          <strong>{t("CurrentFloor")}: {isNaN(Number(currentFloor)) ? 'No disponible' : (Number(currentFloor)+1)}</strong>
        </div>
        <div className="bottomBodyModalConfig">
          <FormGroup className="formParentOption">
            <Checks
                type='radio'
                name='imgSelection'
                id='imgMatterport'
                label={t("SourceMatterport")}
                value='matterport'
                onChange={handleChange}
                checked={optionsImageForm.values.imgSelection}
                disabled={isDisabled}
              />
          </FormGroup>
          <FormGroup className="formParentOption">
            <Checks
              type='radio'
              name='imgSelection'
              id='imgDB'
              label={t("SourceDataBase") as ReactNode}
              value='database'
              onChange={handleChange}
              checked={optionsImageForm.values.imgSelection}
              disabled={isDisabled}
            />
            <p className={`${isMatterportSelected ? 'hide': ''}` }>
              <strong>{t("CurrentFileNameImageMap")}:</strong> {hasFileName() ? (t("ImageFileNameNotFound") as string): fileNameConfigProcess}
            </p>
          </FormGroup>
        </div>
      </ModalBody>
      <ModalFooter className={`${isDisabled || isMatterportSelected ? 'hide': ''  }` }>
        <button type='button' className='uploadImage btn btn-primary' onClick={()=> openChangeMapModal(0, false)}>{t("UploadNewImageMap") as ReactNode}</button>
          <div className='buttonSeparator'></div>
        <button type='button' className='recalibrate btn btn-primary' disabled={hasFileName()} onClick={()=> openChangeMapModal(1, true)}>{t("RecalibrateMap")}</button>
      </ModalFooter>
    </Modal>
    <ModalMsgErrorImage showModalMsgErrorImage={showModalMsgErrorImage} setShowModalMsgErrorImage={setShowModalMsgErrorImage}></ModalMsgErrorImage>
    <ChangeMapModal></ChangeMapModal>
    </>
  );
};

export default MenuConfigureModalMap;
