import React, { useRef, useState, useContext, useEffect, ReactNode } from "react";
import useDebounce from "../../../hooks/useDebounce";
import Header, { HeaderLeft } from "../../../layout/Header/Header";
import CommonHeaderChat from "./CommonHeaderChat";
import CommonHeaderRight from "./CommonHeaderRight";
import Button from "../../../components/bootstrap/Button";
import { useLocation } from "react-router-dom";

import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../../components/bootstrap/OffCanvas";
import Input from "../../../components/bootstrap/forms/Input";
import { ArrowBack } from "../../../components/icon/material-icons";
import Card, { CardBody, CardHeader } from "../../../components/bootstrap/Card";
import { dashboardPagesMenu } from "../../../menu";
import HierarchyTree from "../../../components/HierarchyTree";
import hierachyServices from "../../../services/hierachyServices";
import Toolbar from "../../../components/matterportUi/Toolbar";
import { MatterportContext } from "../../../contexts/MatterportContext";
import { OverlayContext } from "../../../contexts/OverlayContext";
import EditPositionTools from "../../../components/matterportUi/EditPositionTools";

import Spinner from "../../../components/bootstrap/Spinner";
import ObjectManager from "../../../components/ObjectManager";
import sensorsServices from "../../../services/sensorsServices";
import { useTranslation } from "react-i18next";
import modelsServices from "../../../services/modelsServices";
import matterportHelper from "../../../helpers/matterportHelper";
////no-pointer-events
const DashboardHeader = () => {
  let siteName = localStorage.getItem("siteName");

  const location = useLocation();
  const {
    toolbarIsOpen,
    offCanvasOpen,
    setOffCanvasOpen,
    activePositionButtons,
    setPositionEditObject,
    setActivePositionButtons,
    updateHierarchy,

    currentNodeSelected,
    sensorsIsActive,
    setSensorsIsActive,
    modalState,
    currentSensors,
    setCurrentSensors,
    autoCompleteList,
    setautoCompleteList,
  } = useContext(OverlayContext);

  const prevName = usePrevious(currentNodeSelected?.name);

  const { site, setModelList } = useContext(MatterportContext);
  const [mounted, setMounted] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [inputSearch, setInputSearch] = useState([]);
  const [firstRender, setFirstRender] = useState(true);

  const [searchData, setSearchData] = useState([
    {
      id: "",
      children: [],
      hierarchyLevel: 1,
      name: "",
      parentOID: "",
    },
  ]);
  const [details, setDetails] = useState({ name: "" });
  const [hierachyData, setHierachyData] = useState({
    children: [
      { id: "", children: [], hierarchyLevel: 1, name: "", parentOID: "" },
    ],
  });

  const deb = useDebounce(inputSearch, 1000);
  const hierarchyContainer = useRef<HTMLDivElement>(null);
  const offCanvasBodyContainer = useRef<HTMLDivElement>(null);
  const detailsContainer = useRef<HTMLDivElement>(null);

  const { t } = useTranslation('menu');

  function searchChange(event: any) {
    setInputSearch(event.target.value);
  }
  // Custom hook para obtener el valor anterior
  function usePrevious(value: any) {
    const ref = useRef();

    useEffect(() => {
      ref.current = value;
    }, [value]);

    return ref.current;
  }

  const handleCustomEvent = (event: any) => {
    console.log({ event });
    // Do something with the event data
    hierarchyContainer.current?.classList.remove("hidden");
    setTimeout(() => {
      console.log("Han pasado dos segundos");
      // Hacer cualquier otra acción que desees después de dos segundos
      detailsContainer.current?.classList.remove("hidden");

    }, 1000);

    setOffCanvasOpen(true);
    console.log("EVENTO cathc");
  };

  function isUrlValid(url: string) {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  }

  const updateSensorsToShow = () => {
    sensorsServices
      .getSensorsByParentUUID(currentNodeSelected._id)
      .then((res) => {
        if (res.ok === true) {
          setCurrentSensors(res.sensors);
        }
      })
      .catch((err) => console.error({ err }));
  };
  const handleLoadedEvent = (event: any) => {
    setLoadingState(true);
  };

  useEffect(() => {
    if (deb === "") {
      document
        .getElementById("auto-complete-container")
        ?.classList.add("hidden");
    } else {
      if (autoCompleteList !== undefined) {
        const fiterByQuery = autoCompleteList.objects.filter((objeto: any) =>
          objeto.name.toLowerCase().includes(deb.toString().toLowerCase())
        );
        if (fiterByQuery.length > 0) {
          setSearchData(fiterByQuery);
        } else {
          setSearchData([]);
        }
      }
    }
  }, [deb]);

  useEffect(() => {
    if (!mounted) {
      console.log("PRIMER");
      setMounted(true);
      window.addEventListener("detailsClickEvent", handleCustomEvent);
      window.addEventListener("doneLoaded", handleLoadedEvent);
      modelsServices.getModelsFilesNames()
        .then(res => {
          if (res.ok) {
            console.log({ MdeolsFIles: res })
            res.models.forEach(function (objeto: any) {

              if (objeto.hasOwnProperty('name')) {

                objeto.text = objeto.name;


              }
            });

            setModelList(res.models)
          }
        })
        .catch(err => console.error({ err }))

      window.addEventListener("clickOnModel", clickOnModelEventHandler);
      setOffCanvasOpen(false)
    }
  }, [mounted]);

  useEffect(() => {
    if (
      searchData !== undefined &&
      firstRender &&
      location.pathname === "/space"
    ) {
      setFirstRender(false);
      const space = localStorage.getItem("space");

      hierachyServices
        .getHierachyList(space + "")
        .then((res) => {
          if (res.ok) {
            const parentItem = {
              _id: space,
              name: site,
              parentOID: "space",
              hierarchyLevel: 0,
            };

            res.objects.push(parentItem);
            localStorage.setItem("searchList", JSON.stringify(res));
            setautoCompleteList(res);
            setLoadingState(true);
          }
        })
        .catch((err) => {
          console.error({ err });
          setautoCompleteList(undefined);
        });

      if (space !== undefined && space !== null) {
        hierachyServices
          .getQueryBySpace(space)
          .then((res) => {
            setHierachyData(res);
          })
          .catch((err) => console.error(err));
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (updateHierarchy) {
      setLoadingState(false);
    } else {
      setLoadingState(true);
    }
  }, [updateHierarchy]);

  useEffect(() => {
    if (currentNodeSelected !== undefined) {
      updateSensorsToShow();
      if (currentNodeSelected.name !== prevName) {
        setSensorsIsActive(false);
      }
    }
  }, [currentNodeSelected]);

  useEffect(() => {
    if (location.pathname !== "/space") {
      setSensorsIsActive(false);
    }
  }, [location.pathname]);

  const isSite = () => {
    if (window.location.pathname === `/${dashboardPagesMenu.visualization.path}`) {
      return true;
    }
    return false;
  };
  const clickOnModelEventHandler = (event: any) => {

    console.log({ event })
    console.log({ eventCLICK: event.detail })
    if (event.detail.content !== ""
      && event.detail.interactuable === "openURL") {

      matterportHelper.openNewTab(event.detail.content)
    } else {
      setOffCanvasOpen(true)
    }
    /*
    const eventVolumeDiv = document.getElementById("eventVolume");
    console.log({ eventVolumeDiv })

        setTimeout(() => {
          eventVolumeDiv.dispatchEvent(clickEvent);
        }, 1000);
    */
  };
  return (
    <div id="headerLayout">
      <EditPositionTools isActive={activePositionButtons} />

      <div id="eventVolume"></div>
      <Header>
        <HeaderLeft>
          {isSite() ? (
            <>
              <Button
                color="info"
                isLight
                onClick={() => {
                  hierarchyContainer.current?.classList.remove("hidden");
                  detailsContainer.current?.classList.add("hidden");
                  setOffCanvasOpen(true);
                  setPositionEditObject((prev: any) => ({
                    ...prev, // Copiar todas las propiedades existentes
                    active: false, // Actualizar solo esta propiedad
                  }));
                  setActivePositionButtons(false);
                }}
              >
                {t("Hierarchy") as ReactNode}
              </Button>

              <ObjectManager></ObjectManager>

              <div id="siteTitle" className="siteTitle">
                <h3>
                  {`${site !== "" ? site : siteName}`}
                </h3>
                <small className="siteTitle" >{currentNodeSelected?.name}</small>
              </div>
            </>

          ) : null}
        </HeaderLeft>

        <CommonHeaderRight afterChildren={<CommonHeaderChat />} />
      </Header>

      <OffCanvas
        id="example"
        isOpen={offCanvasOpen}
        placement="start"
        setOpen={() => {
          setOffCanvasOpen(false);
        }}
        titleId="exampleTitle"
      >
        <OffCanvasHeader
          className="..."
          initialLock={false}
          setOpen={() => {
            setOffCanvasOpen(false);
          }}
        >
          <OffCanvasTitle id="...">{t("Hierarchy") as ReactNode}</OffCanvasTitle>
        </OffCanvasHeader>

        <OffCanvasBody className="...">
          <Input
            id="search-input"
            onBlur={() => { }}
            onChange={searchChange}
            onFocus={function noRefCheck() { }}
            onInput={function noRefCheck() { }}
            onInvalid={function noRefCheck() { }}
            onSelect={function noRefCheck() { }}
            value={inputSearch}
          />
          <br />
          <Card ref={detailsContainer} id="detailsContainer" className="hide">
            <CardBody>
              <ArrowBack
                onClick={() => {
                  detailsContainer.current?.classList.add("hidden");
                  hierarchyContainer.current?.classList.remove("hidden");
                }}
              />
              <h3>Detalles</h3>
              <table>
                <tbody>
                  <tr key="1">
                    <td>Nombre:</td>
                    <td>&nbsp;{details.name}</td>
                  </tr>
                  <tr key="2">
                    <td>Fecha: </td>
                    <td>&nbsp;12/12/1946</td>
                  </tr>
                </tbody>
              </table>
            </CardBody>
          </Card>
          {!loadingState ? (
            <div id="hierachySpinner" className="p-l-1">
              <Spinner
                color="primary"
                size={60}
                className="spinner hierarchySpinner"
              >
                Loading...
              </Spinner>
            </div>
          ) : (
            <></>
          )}
          <div ref={offCanvasBodyContainer} id="offCanvasBodyContainer">
            <HierarchyTree
              refContainer={hierarchyContainer}
              isOpen={offCanvasOpen}
              data={searchData}
              inputSearch={inputSearch}

            />
          </div>
        </OffCanvasBody>
      </OffCanvas>

      {toolbarIsOpen ? <Toolbar first={"details"}></Toolbar> : <></>}

      {sensorsIsActive ? (
        <Card
          id="sensorsContainer"
          className="

mt-3 mb-3 mr-3"
        >
          <div className="container d-flex align-items-center justify-content-between p-3">
            <h2
              className="col-8"
            >{t("Sensors")}</h2>
            <Button
              className="col-4"
              color="danger"
              hoverShadow="default"
              icon="Close"
              isLight
              onClick={() => {
                setSensorsIsActive(false)
              }}
            >
            </Button>

          </div>

          <CardBody className="h-100">
            <div id="sensorsGallery">
              {currentNodeSelected === undefined ? (
                <h3>{t("SensorMessageElementNotSelected") as ReactNode}</h3>
              ) : (
                <>
                  {Array.isArray(currentSensors) &&
                    currentSensors.length > 0 ? (
                    currentSensors.map((sensor, index) => (
                      <div key={index + "sensorFrame"}>
                        <small>{`${sensor["sensorName"]}`}</small>
                        <iframe
                          srcDoc={
                            sensor["externalSensorURL"] === "" ||
                              !isUrlValid(sensor["externalSensorURL"])
                              ? " Este sensor no tiene una url valida"
                              : undefined
                          }
                          src={sensor["externalSensorURL"]}
                          width="450"
                          height="200"
                        ></iframe>
                      </div>
                    ))
                  ) : (
                    <>
                      <h2>{t("NoSensorsOnSelectedElement") as string}</h2>
                    </>
                  )}
                </>
              )}
            </div>
          </CardBody>
        </Card>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DashboardHeader;
