import axios from "axios";

const API_URL_HIERARCHY = process.env.REACT_APP_DOMAIN + "/api/hierarchy/";
const API_URL_HIERARCHY_SPACE = process.env.REACT_APP_DOMAIN + "/api/hierarchy";
const API_URL_HIERARCHY_CAMERA_POSITION =
  process.env.REACT_APP_DOMAIN + "/api/hierarchy/camera";
const API_URL_HIERARCHY_ELEMENT_DETAILS =
  process.env.REACT_APP_DOMAIN + "/api/hierarchy/details";
const API_URL_HIERARCHY_SET_NEW_PARENT =
  process.env.REACT_APP_DOMAIN + "/api/hierarchy/parent";
const API_URL_HIERARCHY_GET_CHILDREN =
  process.env.REACT_APP_DOMAIN + "/api/hierarchy/children";
const API_URL_HIERARCHY_NEW_CHILD =
  process.env.REACT_APP_DOMAIN + "/api/hierarchy/child";
const API_URL_UPDATE_CAMERA =
  process.env.REACT_APP_DOMAIN + "/api/hierarchy/position";
const API_URL_SEARCH_LIST =
  process.env.REACT_APP_DOMAIN + "/api/hierarchy/search";
const API_URL_ADD_ROOT_CHILD =
  process.env.REACT_APP_DOMAIN + "/api/hierarchy/addFirst";
let config = {};
const getToken = () => {
  const storageToken = localStorage.getItem("token");
  const token = storageToken === (null || undefined) ? "" : storageToken;

  config = {
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json",
    },
  };
};

const getTokenDelete = () => {
  const storageToken = localStorage.getItem("token");
  return storageToken === (null || undefined) ? "" : storageToken;
};

const getQueryBySpace = function (space: string) {
  const token = localStorage.getItem("token");
  let localConfig: any;
  if (token !== undefined) {
    localConfig = {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    };
  } else {
    localConfig = config;
  }

  return new Promise<any>((resolve, reject) => {
    axios
      .post(API_URL_HIERARCHY_SPACE, JSON.stringify({ space }), config)
      .then(function (response: any) {
        console.log("getQueryBySpace", response.data);
        resolve(response.data);
      })
      .catch(function (error: any) {
        console.error(error);
        reject();
      });
  });
};

const cameraPosition = function (id: string) {
  getToken();
  return new Promise<any>((resolve, reject) => {
    axios
      .post(API_URL_HIERARCHY_CAMERA_POSITION, JSON.stringify({ id }), config)
      .then(function (response: any) {
        resolve(response.data);
      })
      .catch(function (error: any) {
        reject();
      });
  });
};

const elementDetails = function (id: string) {
  getToken();
  return new Promise<any>((resolve, reject) => {
    axios
      .post(API_URL_HIERARCHY_ELEMENT_DETAILS, JSON.stringify({ id }), config)
      .then(function (response: any) {
        resolve(response.data);
      })
      .catch(function (error: any) {
        reject();
      });
  });
};

const SetNewParentObject = function (id: string, newIdParent: string) {
  getToken();
  return new Promise<any>((resolve, reject) => {
    axios
      .post(
        API_URL_HIERARCHY_SET_NEW_PARENT,
        JSON.stringify({
          id,
          newIdParent,
        }),
        config
      )
      .then(function (response: any) {
        resolve(response.data);
      })
      .catch(function (error: any) {
        reject(error);
      });
  });
};

const getChildren = function (id: string) {
  getToken();
  return new Promise<any>((resolve, reject) => {
    axios
      .post(
        API_URL_HIERARCHY_GET_CHILDREN,
        JSON.stringify({
          id,
        }),
        config
      )
      .then(function (response: any) {
        resolve(response.data);
      })
      .catch(function (error: any) {
        reject();
      });
  });
};

const addNewChildren = function (
  idParent: string,
  name: string,
  spaceId: string
) {
  getToken();
  return new Promise<any>((resolve, reject) => {
    axios
      .post(
        API_URL_HIERARCHY_NEW_CHILD,
        JSON.stringify({
          idParent,
          name,
          spaceId,
        }),
        config
      )
      .then(function (response: any) {
        resolve(response.data);
      })
      .catch(function (error: any) {
        reject();
      });
  });
};

const deleteHierarchyElement = function (id: string) {
  const token = getTokenDelete();
  return new Promise<any>((resolve, reject) => {
    axios
      .delete(API_URL_HIERARCHY, {
        data: { id },
        headers: {
          "x-auth-token": token,
          "Content-Type": "application/json",
        },
      })
      .then(function (response: any) {
        resolve(response.data);
      })
      .catch(function (error: any) {
        reject();
      });
  });
};

const updateCamera = function (
  id: string,
  sweepointUUID: string,
  cameraHorizontalRotation: string,
  cameraVerticalRotation: string
) {
  getToken();
  console.log("Datos CAmara")
  console.log({id,
    sweepointUUID,
    cameraHorizontalRotation,
    cameraVerticalRotation})
  return new Promise<any>((resolve, reject) => {

    axios
      .put(
        API_URL_UPDATE_CAMERA,
        JSON.stringify({
          id,
          sweepointUUID,
          cameraHorizontalRotation,
          cameraVerticalRotation,
        }),
        config
      )
      .then(function (response: any) {
        resolve(response.data);
      })
      .catch(function (error: any) {
        reject();
      });
  });
};

const updateName = function (id: string, newName: string) {
  getToken();
  return new Promise<any>((resolve, reject) => {
    axios
      .put(
        API_URL_HIERARCHY,
        JSON.stringify({
          id,
          newName,
        }),
        config
      )
      .then(function (response: any) {
        resolve(response.data);
      })
      .catch(function (error: any) {
        reject(error);
      });
  });
};
const getHierachyList = function (space: string) {
  getToken();
  return new Promise<any>((resolve, reject) => {
    axios
      .post(
        API_URL_SEARCH_LIST,
        JSON.stringify({
          space,
        }),
        config
      )
      .then(function (response: any) {
        resolve(response.data);
      })
      .catch(function (error: any) {
        reject();
      });
  });
};
const addRootNodeChild = function (name: string, spaceId: string) {
  getToken();
  return new Promise<any>((resolve, reject) => {
    axios
      .post(
        API_URL_ADD_ROOT_CHILD,
        JSON.stringify({
          name,
          spaceId,
        }),
        config
      )
      .then(function (response: any) {
        resolve(response.data);
      })
      .catch(function (error: any) {
        reject();
      });
  });
};
const hierachyServices = {
  getQueryBySpace,
  cameraPosition,
  elementDetails,
  SetNewParentObject,
  getChildren,
  addNewChildren,
  deleteHierarchyElement,
  updateCamera,
  updateName,
  getHierachyList,
  addRootNodeChild,
};
export default hierachyServices;
