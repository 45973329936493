import React, {
  FC,
  useCallback,
  useContext,
  useState,
  useRef,
  useEffect,
  ReactNode,
  useLayoutEffect,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Page from "../../../layout/Page/Page";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Button, { ButtonGroup, IButtonProps } from "../../../components/bootstrap/Button";
import Logo from "../../../components/Logo";
import useDarkMode from "../../../hooks/useDarkMode";
import { useFormik } from "formik";
import AuthContext from "../../../contexts/authContext";

import USERS, {
  getUserDataWithUsername,
} from "../../../common/data/userDummyData";

import Spinner from "../../../components/bootstrap/Spinner";
import Alert from "../../../components/bootstrap/Alert";
import authServices from "../../../services/authServices";
import { useTranslation } from "react-i18next";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../../components/bootstrap/Dropdown";
import i18n from "../../../i18n";
import LANG, { getLangWithKey, ILang } from "../../../lang";
import '../../../styles/pages/login.scss';

interface ILoginHeaderProps {
  isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {

  const { t } = useTranslation('menu');

  if (isNewUser) {
    return (
      <>
        <div className="text-center h1 fw-bold mt-5">{t('Sign Up') as ReactNode}</div>
        <div className="text-center h4 text-muted mb-5">

          {t('SecondSignUpMessage') as ReactNode}
        </div>
      </>
    );
  }
  return (
    <>
      <div className="text-center h1 fw-bold mt-5">{t('Welcome') as ReactNode}</div>
      <div className="text-center h4 text-muted mb-5">
        {t('SecondLoginMessage') as ReactNode}
      </div>
    </>
  );
};

interface ILoginProps {
  isSignUp?: boolean;
}
const Login: FC<ILoginProps> = ({ isSignUp }) => {
  const { setUser, setUserData } = useContext(AuthContext);

  const { darkModeStatus } = useDarkMode();
  const inputUSer = React.useRef<HTMLInputElement>(null);
  const inputPassword = React.useRef<HTMLInputElement>(null);

  const [signInPassword, setSignInPassword] = useState<boolean>(false);
  const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);

  const [singUserName, setSingUserName] = useState<string>("");


  const navigate = useNavigate();

  //const handleOnClick = useCallback(() => navigate(`/sites`), [navigate] )
  const [language, setLanguage] = useState<string>(i18n.language); 

  const usernameCheck = (username: string) => {
    return !!getUserDataWithUsername(username);
  };

  const passwordCheck = (username: string, password: string) => {
    return getUserDataWithUsername(username).password === password;
  };
  useEffect(() => {
    window.localStorage.clear();
  }, []);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      loginUsername: "",
      loginPassword: "",
    },
    validate: (values) => {
      const errors: { loginUsername?: string; loginPassword?: string } = {};

      if (!values.loginUsername) {
        errors.loginUsername = t('Obligatory') as string;
      }

      if (!values.loginPassword) {
        errors.loginPassword = t('Obligatory') as string;
      }

      return errors;
    },
    validateOnChange: false,
    onSubmit: (values) => {

      if (inputPassword.current !== null) {
        setIsLoading(true);
        authServices
          .login(singUserName, inputPassword.current.value)
          .then((res) => {
            if (res.ok === true) {
              setIsLoading(false);
              if (setUser) {
                if (inputUSer.current === null) {
                  console.log("Es null");
                }
                console.log({ userRes: res })
                setUser(res.username);
                setUserData({
                  id: res.id,
                  username: res.username,
                  name: res.username,
                  surname: "Doe",
                  position: res.roleName,
                  email: res.email,
                  src: "",
                  srcSet: "",
                  isOnline: true,
                  color: "red",
                  password: "",
                });
                localStorage.setItem("role", res.roleName);
                localStorage.setItem("token", res.token);
                let nextRoute: string;
                if (res.passwordExpired === true) {
                  nextRoute = "/auth-pages/renewPassword";
                } else {
                  nextRoute = "/";
                }
                setTimeout(function () {
                  navigate(nextRoute);
                }, 1000);
                // 2000 milisegundos son 2 segundos
              }
            } else {
              checkErrorMessage(res);
              
            }
          })
          .catch((error) => {
            console.log({ LOGINE: error });
            let msgErr = "";
            if (error.code === "ERR_NETWORK" || error.code === "ECONNABORTED") {
              msgErr = t('ErrorConection') as string;
            } else {
              msgErr = t('WrongUserPassword') as string;
            }
            //
            formik.setFieldError("loginPassword", msgErr);
          });
      }
    },
  });

  const checkErrorMessage = (res: any) => {
    if(res.msg === "UserIsInactive"){
      formik.setFieldError(
        "loginPassword",
        (t("UserIsInactive") as string)
      );
    }else{
      formik.setFieldError(
        "loginPassword",
        (t("WrongUserPassword") as string)
      );
    }
    if (setUser) {
      setUser(null);
    }
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleContinue = () => {
    setIsLoading(true);

    setTimeout(() => {
      if (inputUSer.current !== null) {
        console.log(inputUSer.current.value);
        setSingUserName(inputUSer.current.value);
        inputUSer.current !== null
          ? (formik.values.loginUsername = inputUSer.current.value)
          : console.log(formik.values.loginUsername);
        setSignInPassword(true);
        setIsLoading(false);
      }

    }, 1000);
  };

  const { t } = useTranslation('menu');

  const styledBtn: IButtonProps = {
    color: darkModeStatus ? "dark" : "light",
    hoverShadow: "default",
    isLight: !darkModeStatus,
    size: "lg",
  };

  const changeLanguage = (lng: ILang['key']['lng']) => {
    i18n.changeLanguage(lng).then(() => {
      setLanguage(lng); // Update the language state when the language changes
    });
  };

  /**
   * Language attribute
   */
  useLayoutEffect(() => {
    document.documentElement.setAttribute('lang', i18n.language.substring(0, 2));
  });

  useLayoutEffect(() => {
    document.documentElement.setAttribute(
      "lang",
      i18n.language.substring(0, 2)
    );
  });

  useEffect(() => {
    formik.validateForm();
  }, [language]);

  return (
    <PageWrapper
      isProtected={false}
      title={singUpStatus ? "Sign Up" : "Login"}
      className={classNames({
        "bg-dark": !singUpStatus,
        "bg-light": singUpStatus,
      })}
    >
      <div className='col-auto'>
        <Dropdown className="mt-3 floatLogin">
          <DropdownToggle hasIcon={false}>
            {typeof getLangWithKey(i18n.language as ILang['key']['lng'])?.icon ===
              'undefined' ? (
              <Button
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...styledBtn}
                className='btn-only-icon'
                aria-label='Change language'
                data-tour='lang-selector'>
                <Spinner isSmall inButton='onlyIcon' isGrow />
              </Button>
            ) : (
              <Button
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...styledBtn}
                icon={
                  getLangWithKey(i18n.language as ILang['key']['lng'])?.icon
                }
                aria-label='Change language'
                data-tour='lang-selector'
              />
            )}
          </DropdownToggle>
          <DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
            {Object.keys(LANG).map((i) => (
              <DropdownItem key={LANG[i].lng}>
                <Button
                  icon={LANG[i].icon}
                  onClick={() => changeLanguage(LANG[i].lng)}>
                  {LANG[i].text}
                </Button>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
      <Page className="p-0">
        <div className="row h-100 align-items-center justify-content-center">
          <div className="col-xl-4 col-lg-6 col-md-8 shadow-3d-container">
            <Card className="shadow-3d-dark" data-tour="login-page">
              <CardBody>
                <div className="text-center mb-4">
                  <Link
                    to="/auth-pages/login"
                    className={classNames(
                      "text-decoration-none  fw-bold display-2",
                      {
                        "text-dark": !darkModeStatus,
                        "text-light": darkModeStatus,
                      }
                    )}
                  >
                    <Logo
                      height={50}
                      width={150}
                    />
                  </Link>
                </div>

                <div
                  className={classNames("rounded-3", {
                    "bg-l10-dark": !darkModeStatus,
                    "bg-dark": darkModeStatus,
                  }, "hidden")}
                >
                  <div className="row row-cols-2 g-3 pb-3 px-3 mt-0">
                    <div className="col">
                      <Button
                        color={darkModeStatus ? "light" : "dark"}
                        isLight={singUpStatus}
                        className="rounded-1 w-100"
                        size="lg"
                        onClick={() => {
                          setSignInPassword(false);
                          setSingUpStatus(!singUpStatus);
                        }}
                      >
                        {t('Login') as ReactNode}
                      </Button>
                    </div>
                    <div className="col">
                      <Button
                        color={darkModeStatus ? "light" : "dark"}
                        isLight={!singUpStatus}
                        className="rounded-1 w-100"
                        size="lg"
                        onClick={() => {
                          setSignInPassword(false);
                          setSingUpStatus(!singUpStatus);
                        }}
                      >
                        {t('Sign Up') as ReactNode}
                      </Button>
                    </div>
                  </div>
                </div>

                <LoginHeader isNewUser={singUpStatus} />

                <form className="row g-4">
                  {singUpStatus ? (
                    <>
                      <div className="col-12">
                        <FormGroup id="signup-email" isFloating label={t('Email') as string}>
                          <Input type="email" autoComplete="off" />
                        </FormGroup>
                      </div>
                      <div className="col-12">
                        <FormGroup id="signup-name" isFloating label={t('User') as string}>
                          <Input autoComplete="off" />
                        </FormGroup>
                      </div>
                      <div className="col-12">
                        <FormGroup
                          id="signup-surname"
                          isFloating
                          label={t('Name') as string}
                        >
                          <Input autoComplete="off" />
                        </FormGroup>
                      </div>
                      <div className="col-12">
                        <FormGroup
                          id="signup-password"
                          isFloating
                          label={t('Password') as string}
                        >
                          <Input type="password" autoComplete="off" />
                        </FormGroup>
                      </div>
                      <div className="col-12">
                        <Button
                          color="info"
                          className="w-100 py-3"
                        //onClick={handleOnClick}
                        >
                          {t('Sign Up') as ReactNode}
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-12">
                        <FormGroup
                          id="loginUsername"
                          isFloating
                          label={t('EmailUser') as string}
                          className={classNames({
                            "d-none": signInPassword,
                          })}
                        >
                          <Input
                            ref={inputUSer}
                            autoComplete="off"
                            value={formik.values.loginUsername}
                            isTouched={formik.touched.loginUsername}
                            invalidFeedback={formik.errors.loginUsername}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onFocus={() => {
                              formik.setErrors({});
                            }}
                          />
                        </FormGroup>
                        {signInPassword && (
                          <div className="text-center h4 mb-3 fw-bold">
                            {t('Welcome') as ReactNode}, {formik.values.loginUsername}.
                          </div>
                        )}
                        <FormGroup
                          id="loginPassword"
                          isFloating
                          label={t('Password') as string}
                          className={classNames({
                            "d-none": !signInPassword,
                          })}
                        >
                          <Input
                            ref={inputPassword}
                            type="password"
                            autoComplete="off"
                            value={formik.values.loginPassword}
                            isTouched={formik.touched.loginPassword}
                            invalidFeedback={formik.errors.loginPassword}
                            validFeedback="¡Todo correcto!"
                            isValid={formik.isValid}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-12">
                        {!signInPassword ? (
                          <Button
                            color="warning"
                            className="w-100 py-3"
                            isDisable={!formik.values.loginUsername}
                            onClick={handleContinue}
                          >
                            {t('Continue') as ReactNode}
                          </Button>
                        ) : (
                          <div className="row row-cols-2 g-3 pb-3 px-3 mt-0">
                            <div className="col">
                              <Button
                                color={darkModeStatus ? "light" : "dark"}
                                className="w-100 py-3"
                                onClick={() => {
                                  formik.values.loginPassword = "";
                                  setSignInPassword(false);
                                }}
                              >
                                {t('Go back') as ReactNode}
                              </Button>
                            </div>
                            <div className="col">
                              <Button
                                color="warning"
                                className="w-100 py-3"
                                onClick={formik.handleSubmit}
                              >
                                {t('Login') as ReactNode}
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}


                  {/* BEGIN :: Social Login */}
                  {!signInPassword && (
                    <>
                      <div className="col-12 mt-3 text-center text-muted"></div>
                      <div className="col-12 mt-3"></div>
                      <div className="col-12"></div>
                    </>
                  )}
                  {/* END :: Social Login */}
                </form>
              </CardBody>
            </Card>
            <div className="text-center">
              <a
                href="/auth-pages/login"
                className={classNames("text-decoration-none me-3", {
                  "link-light": singUpStatus,
                  "link-dark": !singUpStatus,
                })}
              >
                Privacy policy
              </a>
              <a
                href="/auth-pages/login"
                className={classNames("link-light text-decoration-none", {
                  "link-light": singUpStatus,
                  "link-dark": !singUpStatus,
                })}
              >
                Terms of use
              </a>
            </div>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};
Login.propTypes = {
  isSignUp: PropTypes.bool,
};
Login.defaultProps = {
  isSignUp: false,
};

export default Login;
