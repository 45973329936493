
import axios from "axios";

const API_URL_VOLUMES_BY_PARENT_UUID = process.env.REACT_APP_DOMAIN + '/api/volumens/';
const API_URL_UPDATE_VOLUMEN = process.env.REACT_APP_DOMAIN + '/api/volumens/';
const API_URL_CHECK_VOLUME_NAME = process.env.REACT_APP_DOMAIN + "/api/volumens/nameExist";
const API_URL_VOLUMES_POSITION = process.env.REACT_APP_DOMAIN + '/api/volumes/upadetePosition';
const API_URL_VOLUME_BY_ID = process.env.REACT_APP_DOMAIN + '/api/volumes/getModelById';
const API_URL_ADD_VOLUME = process.env.REACT_APP_DOMAIN + '/api/volumes/addNewVolume';
const API_URL_GET_ALL_VOLUME = process.env.REACT_APP_DOMAIN + '/api/volumes/getAllVolumes';
const API_URL_VOLUMES_BY_SPACE = process.env.REACT_APP_DOMAIN + '/api/volumens/space';

let config = {};

const getToken = () => {
    const storageToken = localStorage.getItem("token");
    const token = storageToken === (null || undefined) ? '' : storageToken;

    config = {
        headers: {
            "x-auth-token": token,
            "Content-Type": "application/json",
        },
    };
};

const getTokenDelete = () => {
    const storageToken = localStorage.getItem("token");
    return storageToken === (null || undefined) ? '' : storageToken;
}

const deleteVolumen = async (idParent, volumeNameToDelete) => {
    const token = getTokenDelete();
    const response = await axios.delete(API_URL_UPDATE_VOLUMEN,
        {
            data: { idParent, volumeNameToDelete }, headers: {
                'x-auth-token': token,
                'Content-Type': 'application/json'
            }
        })
    return response.data;
};

const uppdateVolumeByParentId = async (idParent, volumenData, previousName) => {
    getToken();
    if (volumenData.volumeName === previousName) {
        previousName = ""
    }
    const response = await axios.patch(API_URL_UPDATE_VOLUMEN, JSON.stringify({
        idParent,
        volumenData,
        previousName
    }), config);

    return response.data;
};

const addNewVolume = async (idParent, volumenData) => {
    getToken();
    const response = await axios.put(API_URL_UPDATE_VOLUMEN, JSON.stringify({ idParent, volumenData, }), config);
    return response.data;
};

const getVolumesByParentUUID = async (idParent) => {
    getToken();
    const response = await axios.post(API_URL_VOLUMES_BY_PARENT_UUID, JSON.stringify({ idParent }), config);
    return response.data;
};

const updateVolumesPositionByid = async (id, position, rotation, scale) => {
    getToken();
    const response = await axios.put(API_URL_VOLUMES_POSITION, JSON.stringify({ id, position, rotation, scale }), config);
    return response.data;
};

const getVolumeById = async (id) => {
    getToken();
    const response = await axios.post(API_URL_VOLUME_BY_ID, JSON.stringify({ id }), config);
    return response.data;
};

const getAllVolumes = async () => {
    getToken();
    const response = await axios.get(API_URL_GET_ALL_VOLUME, config);
    return response.data;
};

const checkName = async (idParent, volumeName) => {
    getToken();
    const response = await axios.post(API_URL_CHECK_VOLUME_NAME, JSON.stringify({ idParent, volumeName }), config);
    return response.data;
};

const getVolumesBySpace = async (space) => {
    getToken();
    const response = await axios.post(API_URL_VOLUMES_BY_SPACE, JSON.stringify({ space }), config);
    return response.data;
};

const volumenServices = {
    getVolumesByParentUUID,
    updateVolumesPositionByid,
    getVolumeById,
    addNewVolume,
    getAllVolumes,
    getVolumesBySpace,
    uppdateVolumeByParentId,
    deleteVolumen,
    checkName
};

export default volumenServices;
