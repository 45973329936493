import React from 'react';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import { useTranslation } from "react-i18next";
import Button from '../../../components/bootstrap/Button';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import useDarkMode from '../../../hooks/useDarkMode';
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import Logo from '../../../components/Logo';
import { useContext } from 'react';
import AuthContext from '../../../contexts/authContext';
import { useFormik } from 'formik';
import usersServices from '../../../services/usersServices';
import { useToasts } from 'react-toast-notifications';
import Toasts from '../../../components/bootstrap/Toasts';
import Alert from '../../../components/bootstrap/Alert';
import '../../../styles/pages/RenewPassword.scss'
import * as Yup from 'yup';
import LanguageSelect from '../../../components/LanguageSelect';
const RenewPassword = (props) => {
    const { updateCurrent } = props;
    const { t } = useTranslation('menu');
    const { darkModeStatus } = useDarkMode();
    const { userData, setUser, user } = useContext(AuthContext);
    const navigate = useNavigate();
    const { addToast } = useToasts();
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            oldPassword: "",
            newPassword: "",
            repeatNewPassword: ""
        },
        validate: (values) => {
            const errors = {};

            if (!values.oldPassword) {
                errors.oldPassword = t('Obligatory');
            }

            if (!values.newPassword) {
                errors.newPassword = t('Obligatory');
            }
            if (values.newPassword !== values.repeatNewPassword) {
                errors.repeatNewPassword = t('passwordRepeatError');
            }
            if (!values.repeatNewPassword) {
                errors.repeatNewPassword = t('Obligatory');
            }
            return errors;
        },
        validateOnChange: false,
        onSubmit: (values) => {
            console.log({ values })
            console.log({ userData, user })
            /*
            {
                "id": "65a2071dcf70991234e0ada4",
                "username": "dbUser5",
                "email": "dbAdminRau@gmail.com",
                "newPassword":"pizza",
                "oldPassWord":"202465221039"
            }
            */
            usersServices.updateExpiredPassWord({
                "id": userData.id,
                "username": userData.username,
                "email": userData.email,
                "newPassword": values.newPassword,
                "oldPassWord": values.oldPassword
            }).then(res => {
                if (res.ok) {
                    genericToast("Success", t('update'), t('passwordUpdatedMessage'), 30000)
                    if (updateCurrent) {
                        navigate("/");
                    } else {
                        navigate("/auth-pages/login");
                    }
                } else {
                    switch (res.msg) {
                        case "ErrorOldPassword":
                            genericToast("danger", t('Error'), t('OldPassword') + " error", 30000)
                            break;
                        case "ErrorDataUser":
                            genericToast("danger", t('Error'), t('emailOrUserError'), 30000)

                            break;
                        case "GettingUsersError":
                            genericToast("danger", t('Error'), t("emailOrUserError"), 30000)

                            break;
                        case "GettingObjectsError":
                            genericToast("danger", t('Error'), t("UnexpectedError"), 30000)

                            break;
                    }
                }
            })

            /*
                        if (inputPassword.current !== null) {
                            setIsLoading(true);
                            authServices
                                .login(singUserName, inputPassword.current.value)
                                .then((res) => {
                                    if (res.ok === true) {
                                        setIsLoading(false);
                                        if (setUser) {
                                            if (inputUSer.current === null) {
                                                console.log("Es null");
                                            }
                                            console.log({ userRes: res })
                                            setUser(res.username);
                                            setUserData({
                                                id: "1",
                                                username: res.username,
                                                name: res.username,
                                                surname: "Doe",
                                                position: res.roleName,
                                                email: "MAIL@mail.com",
                                                src: "",
                                                srcSet: "",
                                                isOnline: true,
                                                color: "red",
                                                password: "",
                                            });
                                            localStorage.setItem("role", res.roleName);
                                            localStorage.setItem("token", res.token);
                                            let nextRoute;
                                            if (res.passwordExpired === true) {
                                                nextRoute = "/auth-pages/renewPassword";
                                            } else {
                                                nextRoute = "/";
                                            }
                                            setTimeout(function () {
                                                navigate(nextRoute);
                                            }, 1000);
                                            // 2000 milisegundos son 2 segundos
                                        }
                                    } else {
                                        formik.setFieldError(
                                            "loginPassword",
                                            "Usuario o contraseña incorrecto."
                                        );
                                        if (setUser) {
                                            setUser(null);
                                        }
                                    }
                                })
                                .catch((error) => {
                                    console.log({ LOGINE: error });
                                    let msgErr = "";
                                    if (error.code === "ERR_NETWORK" || error.code === "ECONNABORTED") {
                                        msgErr = t('ErrorConection');
                                    } else {
                                        msgErr = t('WrongUserPassword');
                                    }
                                    //
                                    formik.setFieldError("loginPassword", msgErr);
                                });
                        }*/
        },
    });
    const genericToast = (typeColor, title, message, timeout) => {
        addToast(
            <Toasts
                className="changePassword"
                title={title}
                isDismiss={false}>
                {alertToShow(typeColor, message)}
            </Toasts>,
            {
                autoDismiss: true,
                autoDismissTimeout: timeout
            }
        )
    };
    const alertToShow = (typeColor, message) => {
        return (

            <Alert
                color={typeColor}
                isLight={false}
                isOutline={false}
                borderWidth={5}
                isDismissible={false}
                shadow={null}
                rounded={3}
            >
                {message}
            </Alert>
        )
    }
    const LoginHeader = () => {
        return (
            <>
                <div className="text-center h1 fw-bold mt-5">{`${t('renewMessage')}`}</div>
                <div className='text-center h4 text-muted mb-5'>
                    {`${user}, ${!updateCurrent ? `
                     ${t('SecondRenewMessage')}` :
                        ` ${t('updatePasswordSub')}`
                        }`}
                </div>
            </>
        );
    }
    return (
        <PageWrapper

            isProtected={false}
            title={"Login"}
            className={
                "bg-light"
            }
        >
            <LanguageSelect></LanguageSelect>
            <Page className="p-0">

                <div className="row h-100 align-items-center justify-content-center">
                    <div className="col-xl-4 col-lg-6 col-md-8 shadow-3d-container">
                        <Card className="shadow-3d-dark" data-tour="login-page">
                            <CardBody>
                                <div className="text-center mb-4">
                                    <Link
                                        to="/auth-pages/login"
                                        className={classNames(
                                            "text-decoration-none  fw-bold display-2",
                                            {
                                                "text-dark": !darkModeStatus,
                                                "text-light": darkModeStatus,
                                            }
                                        )}
                                    >
                                        <Logo
                                            height={50}
                                            width={150}
                                        />
                                    </Link>
                                </div>
                                <LoginHeader></LoginHeader>
                                <form className="row g-4">
                                    <div className="col-12">
                                        <FormGroup id="oldPassword" isFloating label={t('OldPassword')}>
                                            <Input type="password"
                                                autoComplete="off"
                                                value={formik.values.oldPassword}
                                                isTouched={formik.touched.oldPassword}
                                                invalidFeedback={formik.errors.oldPassword}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                onFocus={() => {
                                                    formik.setErrors({});
                                                }} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-12">
                                        <FormGroup id="newPassword" isFloating label={t('NewPassword')}>
                                            <Input value={formik.values.newPassword}
                                                type="password"
                                                isTouched={formik.touched.newPassword}
                                                invalidFeedback={formik.errors.newPassword}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                onFocus={() => {
                                                    formik.setErrors({});
                                                }} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-12">
                                        <FormGroup
                                            id="repeatNewPassword"
                                            isFloating
                                            label={t('repeatPassword')}
                                        >
                                            <Input autoComplete="off"
                                                value={formik.values.repeatNewPassword}
                                                type="password"
                                                isTouched={formik.touched.repeatNewPassword}
                                                invalidFeedback={formik.errors.repeatNewPassword}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                onFocus={() => {
                                                    formik.setErrors({});
                                                }} />
                                        </FormGroup>
                                    </div>

                                    <div className="col-12">
                                        <div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
                                            <div className='col'>
                                                <Button
                                                    color="info"
                                                    className="btn btn-dark w-100 py-3"
                                                    onClick={() => {
                                                        if (updateCurrent) {
                                                            navigate("/")
                                                        } else {
                                                            navigate("/auth-pages/login")
                                                        }
                                                    }
                                                    }
                                                >
                                                    {t('Go back')}
                                                </Button>
                                            </div>
                                            <div className='col'>
                                                <Button
                                                    color="info"
                                                    className="btn btn-warning w-100 py-3"
                                                    onClick={formik.handleSubmit}
                                                >
                                                    {t('OK')}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </Page>
        </PageWrapper>
    );
}

export default RenewPassword;
