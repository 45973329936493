import React, { useState, useEffect, useContext } from 'react'
import { ButtonGroup } from '../bootstrap/Button'
import Button from '../bootstrap/Button';
import matterportHelper from '../../helpers/matterportHelper';
import { MatterportContext } from '../../contexts/MatterportContext';
import { OverlayContext } from '../../contexts/OverlayContext';
import { useTranslation } from "react-i18next";
import modelsServices from '../../services/modelsServices';

function EditPositionTools(props) {
    const { isActive } = props;

    const [pose, setPose] = useState({});

    const {
        matterportSdk,
        fbxObjects,
        setFbxObjects,
        setCurrentEditObject,
        modelList, setModelList,
        setCurrentVolumes, currentVolumes
    } = useContext(MatterportContext);

    const {
        positionEditObject,
        currentNodeSelected,
        setUpdateHierarchy,
        setActiveSaveButton,
        type, setType
    } = useContext(OverlayContext);

    const { t } = useTranslation('menu');
    /*matterportSdk.Camera.getPose().then(function (pose) {
    });*/
    useEffect(() => {
        let objectTransform = { position: {}, rotation: {}, scale: {} };
        if (matterportSdk !== undefined && isActive) {

            console.log({ positionEditObject })

            setType("move");

            if (positionEditObject.type === "volume") {
                console.log("VOLUME TYPE")
                if (positionEditObject.isNew) {

                    console.log('Current position is ', pose.position);
                    console.log('Rotation angle is ', pose.rotation);
                    console.log('Sweep UUID is ', pose.sweep);
                    console.log('View mode is ', pose.mode);
                    console.log({ positionEditObject });
                    objectTransform.position = pose.position;
                    objectTransform.rotation = { x: 0, y: 0, z: 0 }
                    objectTransform.scale = { x: 1, y: 1, z: 1 }

                    const frontPosition = matterportHelper.positionInFrontCamera(pose.position.x, pose.position.z, pose.rotation.y);
                    matterportHelper.loadModel(matterportSdk, {
                        mongoName: positionEditObject.name,
                        animated: 0,
                        id: 1,
                        interactuable: positionEditObject.modelOid,
                        length: 1,
                        interactuable_content: "zxczxc",
                        interactuable_type: "text",
                        modelfilename: "CuboTransparente.fbx",
                        modelformat: "fbx",
                        parent: "",
                        positionx: frontPosition.x,
                        positiony: objectTransform.position.y,
                        positionz: frontPosition.z,
                        rotationx: objectTransform.rotation.x,
                        rotationy: objectTransform.rotation.y,
                        rotationz: objectTransform.rotation.z,
                        scalex: 1,
                        scaley: 1,
                        scalez: 1

                    })
                        .then((data) => {
                            matterportHelper.activeGizmo(data.node, matterportSdk).then((res) => {
                                console.log({ gizmoRes: res })
                                // data.name = res.volumens[i].volumen.volumeName + "/" + res.volumens[i].parentId
                                data.node.name = positionEditObject.initialModelData.mongoName;
                                setCurrentEditObject({
                                    model: data,
                                    control: res

                                });
                                setCurrentVolumes([...currentVolumes, { data: data.node }]);
                                setUpdateHierarchy(true);
                            })

                        })
                        .catch((err) => {
                            console.error(err);
                        });
                }
            }

            /*
            if (positionEditObject.type === "model") {
                console.log("modelTYPE ")
                if (positionEditObject.isNew) {
                    console.log("NEW MODEL")
                    console.log({ pose });
                    objectTransform.position = pose.position;
                    objectTransform.rotation = { x: 0, y: 0, z: 0 }
                    objectTransform.scale = { x: 1, y: 1, z: 1 }
                    console.log({ modelList, oid: positionEditObject.modelOID })
                    const foundModel = modelList.find(model => model.id === positionEditObject.modelOID);
                    console.log({ foundModel })
                    matterportHelper.loadModelMongo(matterportSdk, {
                        mongoName: positionEditObject.name,
                        animated: 0,
                        id: 1,
                        //  interactuable: positionEditObject.fileName,
                        length: 1,
                        interactuable_content: "",
                        interactuable_type: "text",
                        modelfilename: foundModel.name,
                        modelformat: "fbx",
                        parent: "",
                        positionx: pose.position.x,
                        positiony: pose.position.y,
                        positionz: pose.position.z,
                        rotationx: 0,
                        rotationy: 0,
                        rotationz: 0,
                        scalex: objectTransform.scale.x,
                        scaley: objectTransform.scale.y,
                        scalez: objectTransform.scale.z
                    })
                        .then((data) => {
                            console.log({ nodeMOngo: data })
                            matterportHelper.activeGizmo(data.node, matterportSdk).then((res) => {
                                console.log({ gizmoRes: res })
                                setCurrentEditObject({
                                    model: data,
                                    control: res

                                });
                            })

                        })
                        .catch((err) => {
                            console.error(err);
                        });

                } else if (positionEditObject.modelExists) {
                    console.log({ exists: positionEditObject.modelExists })
                    console.log({ fbxObjects })
                    console.log({ currentVolumes })
                    matterportHelper.turnOnFbxObjectByName(fbxObjects, currentNodeSelected._id + "/" + positionEditObject.name).then(res => {

                        if (res !== null) {

                            matterportHelper.activeGizmo(res, matterportSdk);
                            console.log({ encontrado: res })
                            setCurrentEditObject({
                                model: { node: res },

                            });
                        } else {
                            objectTransform = positionEditObject.editPosition;
                            console.log({ positionEditObject })

                            const foundModel = modelList.find(model => model.id === positionEditObject.modelOID);
                            console.log({ foundModel })
                            matterportHelper.loadModelMongo(matterportSdk, {
                                mongoName: currentNodeSelected._id + "/" + positionEditObject.name,
                                animated: 0,
                                id: 1,
                                interactuable: positionEditObject.interactuable,
                                length: 1,
                                interactuable_content: "zxczxc",
                                interactuable_type: "text",
                                modelfilename: foundModel.name,
                                modelformat: "fbx",
                                parent: "",
                                positionx: objectTransform.position.x,
                                positiony: objectTransform.position.y,
                                positionz: objectTransform.position.z,
                                rotationx: objectTransform.rotation.x,
                                rotationy: objectTransform.rotation.y,
                                rotationz: objectTransform.rotation.z,
                                scalex: objectTransform.scale.x,
                                scaley: objectTransform.scale.y,
                                scalez: objectTransform.scale.z

                            })
                                .then((data) => {
                                    matterportHelper.activeGizmo(data.node, matterportSdk).then(res);
                                    console.log({ nodeMOngo: data })
                                    console.log({ gizmoRes: res })
                                    setCurrentEditObject({
                                        model: data,
                                        control: res

                                    });
                                })
                                .catch((err) => {
                                    console.error(err);
                                });
                        }
                        console.log({ currentNodeSelected })
                        console.log({ positionEditObject })



                    }).catch(err => console.error({ err }))
                } else {



                    const foundModel = modelList.find(model => model.id === positionEditObject.modelOID);
                    console.log({ foundModel })


                    matterportHelper.deleteModeltById(fbxObjects, currentNodeSelected._id + "/" + positionEditObject.name)
                    matterportHelper.loadModelMongo(matterportSdk, {
                        mongoName: positionEditObject.name,
                        animated: 0,
                        id: 1,
                        //  interactuable: positionEditObject.fileName,
                        length: 1,
                        interactuable_content: "",
                        interactuable_type: "text",
                        modelfilename: foundModel.name,
                        modelformat: "fbx",
                        parent: "",
                        positionx: positionEditObject.editPosition.position.x,
                        positiony: positionEditObject.editPosition.position.y,
                        positionz: positionEditObject.editPosition.position.z,
                        rotationx: positionEditObject.editPosition.rotation.x,
                        rotationy: positionEditObject.editPosition.rotation.y,
                        rotationz: positionEditObject.editPosition.rotation.z,
                        scalex: positionEditObject.editPosition.scale.x,
                        scaley: positionEditObject.editPosition.scale.y,
                        scalez: positionEditObject.editPosition.scale.z,

                    })
                        .then((data) => {
                            console.log({ nodeMOngo: data })
                            matterportHelper.activeGizmo(data.node, matterportSdk).then((res) => {
                                console.log({ gizmoRes: res })
                                setCurrentEditObject({
                                    model: data,
                                    control: res

                                });
                            })

                        })
                        .catch((err) => {
                            console.error(err);
                        });
                }

            }

            */

        }


    }



        , [isActive]);
    useEffect(() => {

        modelsServices.getModelsFilesNames()
            .then(res => {
                if (res.ok) {
                    console.log({ MdeolsFIles: res })
                    res.models.forEach(function (objeto) {

                        if (objeto.hasOwnProperty('name')) {

                            objeto.text = objeto.name;


                        }
                    });


                    setModelList(res.models)
                }
            })
            .catch(err => console.error({ err }))
    }, [

    ])
    useEffect(() => {
        if (matterportSdk !== undefined) {
            matterportSdk.Camera.pose.subscribe(function (pose) {
                setPose(pose);

            });
        }
    }, [matterportSdk])
    return (
        <div id="editPositionTools"
            className={isActive ? "" : "hidden"}
        >
            <ButtonGroup >
                <Button
                    {...(type === "move" ? { isActive: true } : {})}
                    color="light"
                    icon="open_with"
                    onClick={() => {
                        setType("move")
                        matterportHelper.dispatchCustomEvent("translate", { msg: "Mensaje" })
                    }}
                >
                    {t("Move")}
                </Button>
                <Button
                    {...(type === "rotate" ? { isActive: true } : {})}
                    onClick={() => {
                        matterportHelper.dispatchCustomEvent("rotate", { msg: "Mensaje" })
                        setType("rotate")
                    }}
                    color="light"
                    icon="flip_camera_android"
                >
                    {t("Rotate")}
                </Button>
                <Button
                    {...(type === "scale" ? { isActive: true } : {})}
                    onClick={() => {
                        matterportHelper.dispatchCustomEvent("scale", { msg: "Mensaje" })
                        setType("scale")
                    }}
                    color="light"
                    icon="open_in_full"
                >
                    {t("Scale")}
                </Button>
                <Button

                    onClick={() => {
                        setType("pin");
                        matterportHelper.onMovePoint(matterportSdk, positionEditObject.editsweepCameraPosition);
                    }}
                    color="primary"
                    icon="pin_drop"
                >
                    {t("Pin")}
                </Button>

            </ButtonGroup>
        </div>
    )
}

export default EditPositionTools