import axios, * as others from "axios";

const API_POST_LOGIN = process.env.REACT_APP_SERVER_DOMAIN + "/api/auth/";
const API_POST_LOGIN_MAIL =
  process.env.REACT_APP_SERVER_DOMAIN + "/api/auth/email";
const API_POST_LOGIN_PASSWORD =
  process.env.REACT_APP_SERVER_DOMAIN + "/api/auth/password";

const API_POST_LOGIN_MAIL_USERNAME =
  process.env.REACT_APP_SERVER_DOMAIN + "/api/auth/emailOrUsername";

const API_POST_LOGIN_USER_NAME =
  process.env.REACT_APP_SERVER_DOMAIN + "/api/login/";

  

const config = {
  headers: {
    "Content-Type": "application/json",
  },
  /*
  validateStatus: function (status: any) {
    return status < 500; // Resolve only if the status code is less than 500
  },
  */
};

type User = {
  UUID: number;
  email: string;
  username: string;
  status: boolean;
};
type GetUsersResponse = {
  data: User[];
};

const login = (username: string, password: string) => {
  return axios.post(API_POST_LOGIN_USER_NAME, { username, password }, config)
    .then( (response: any) =>  response.data)
    .catch((error: any) => error.response.data);
};

const loginMailOrUsername = function (userName: string) {
  return new Promise<any>((resolve, reject) => {
    axios
      .post(API_POST_LOGIN_MAIL_USERNAME, { userName }, config)
      .then(function (response: any) {
        resolve(response.data);
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  });
};

const loginPassword = function (password: string, user: string) {
  return new Promise<any>((resolve, reject) => {
    axios
      .post(API_POST_LOGIN_PASSWORD, { password, user }, config)
      .then(function (response: any) {
        resolve(response.data);
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  });
};

const authServices = {
  login,
  loginMailOrUsername,
  loginPassword,
};
export default authServices;
