import { ReactNode, useContext, useLayoutEffect, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import LANG, { getLangWithKey, ILang } from "../../../../../../lang";

import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../../../../components/bootstrap/Modal";

import { MatterportContext } from "../../../../../../contexts/MatterportContext";
import { OverlayContext } from '../../../../../../contexts/OverlayContext';

  
const PointSelection: any = (props: any) => {
    const { showModalInformation, setShowModalInformation, title, resetStates, positionToSelect, saveInfoMatterport, levelStartedProcess } = props;
    const { matterportSdk } = useContext(MatterportContext);
    const { imageToUse } = useContext(OverlayContext);
    const { t } = useTranslation('menu');
    const { i18n } = useTranslation();
    const changeLanguage = (lng: ILang['key']['lng']) => {
        i18n.changeLanguage(lng);
    };

    /**
     * Language attribute
     */
    useLayoutEffect(() => {
        document.documentElement.setAttribute('lang', i18n.language.substring(0, 2));
    });
    const [ showInstructions, setShowInstructions ] = useState(false);
    const [ instructionText, setInstructionText ] = useState("");
    
    const [ checkClickFloorPlan, setCheckClickFloorPlan ]  = useState(false);
    const refClickFloorPlan = useRef(checkClickFloorPlan);
    

    useEffect(() => {
        if(showModalInformation){
             cleanLocalStates();
             matterportSdk.Mode.moveTo(matterportSdk.Mode.Mode.FLOORPLAN);
             chooseTextToShow();
             setShowInstructions(true);
        }
     }, [showModalInformation])

    const returnInfoToParent = (position:any) => {
        saveInfoMatterport(position);
        cleanLocalStates();
        //resetStates();
    };

    const chooseTextToShow = () => {
        switch(positionToSelect){
            case "bottomRight": 
                setInstructionText(`${t("BaseInstructionText")} inferior derecha`);
                break;
            
            case "bottomLeft": 
                setInstructionText(`${t("BaseInstructionText")} inferior izquierda`);
                break;
            
            case "upperRight": 
                setInstructionText(`${t("BaseInstructionText")} superior derecha`);
                break;
            
            case "upperLeft": 
                setInstructionText(`${t("BaseInstructionText")} superior izquierda`);
                break;
            default:
                break;
        }
    }

    const changeToFloorView = (evaluate:boolean) => {

        
        
        if(matterportSdk !== undefined && evaluate){
            setCheckClickFloorPlan(true);
            
            
            matterportSdk.Camera.pose.subscribe(function (pose:any) {	
                
                if(refClickFloorPlan.current && pose.mode === 'mode.inside'){
                    returnInfoToParent(pose.position)
                }

                if(pose.mode === 'mode.floorplan'){
                    
                    matterportSdk.Floor.moveTo(levelStartedProcess);
                    const iFrameMatterport = document?.getElementById('showcase') as HTMLIFrameElement;
                    const floorContainer = iFrameMatterport?.contentWindow?.document?.querySelector('.floor-container') as HTMLElement;
                    if(floorContainer !== null){
                        floorContainer.style.display = "none";
                    }
                    refClickFloorPlan.current = true;
                }
                
            });
            
            setShowModalInformation(false);
        }
        
        
    }

    const cleanLocalStates = () => {
        setCheckClickFloorPlan(false)
        refClickFloorPlan.current = false;
        setInstructionText("");
        setShowInstructions(false);
    }

    return (
        <Modal 
        id="modalConfig"
        titleId="titleConfig"
        isOpen={ showModalInformation } // Example: state
        setIsOpen={ setShowModalInformation } // Example: setState
        isStaticBackdrop={ true } 
        isScrollable={ false } 
        isCentered={true} 
        fullScreen= 'md' 
        isAnimation={ true }
        >
            <ModalHeader>
                <ModalTitle id="imageModalSectionTitle">{ title }</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <div className='calibration'>
                    <div className={` ${showInstructions ? 'instructionText' : 'hidden'} mb-2`}>{instructionText}</div>
                </div>
            </ModalBody>
            <ModalFooter>
                <button type='button' className='btn btn-primary' onClick={()=>changeToFloorView(true)}>{t("Continue") as ReactNode}</button>
            </ModalFooter>
        </Modal>
    );
};

export default PointSelection;
