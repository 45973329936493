import { ReactNode, useContext, useLayoutEffect, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import LANG, { getLangWithKey, ILang } from "../../../../../lang";
import useDarkMode from "../../../../../hooks/useDarkMode";

import { OverlayContext } from "../../../../../contexts/OverlayContext";


// import './PointSelection.scss'
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../../../components/bootstrap/Modal";
import { useFormik } from "formik";
import FormGroup from "../../../../../components/bootstrap/forms/FormGroup";
import { useToasts } from 'react-toast-notifications';
import Toasts from '../../../../../components/bootstrap/Toasts';
import Alert from '../../../../../components/bootstrap/Alert';
import Input from "../../../../../components/bootstrap/forms/Input";
import Button from "../../../../../components/bootstrap/Button";
import * as Yup from 'yup';
import ModalInformation from "./ModalInformation/ModalInformation";
import CanvasInformation from "./CanvasInformation/CanvasInformation";

  
const PointSelection: any = (props: any) => {
  const { showModalPointSelector, resetStates, saveInfoPosition, positionToSelect, levelStartedProcess } = props;
  const [ showModalInformation, setShowModalInformation ] = useState(false);
  const { imageToUse } = useContext(OverlayContext);
  const [ positionMatterpot, setPositionMatterport] = useState({});
  const [ showModalCanvas, setShowModalCanvas ] = useState(false);
  const { t } = useTranslation('menu');
  const { i18n } = useTranslation();


  const changeLanguage = (lng: ILang['key']['lng']) => {
      i18n.changeLanguage(lng);
  };

  /**
   * Language attribute
   */
  useLayoutEffect(() => {
      document.documentElement.setAttribute('lang', i18n.language.substring(0, 2));
  });

  useEffect(() => {
    if(showModalPointSelector > 0) {
      console.log("start PointSelector", imageToUse);
      setShowModalInformation(true);
    }
  },[showModalPointSelector]);

  const handleClose = () => {
    resetStates();
  };

  const saveInfoMatterport = (positionMatterpot:any) => {
    
    setPositionMatterport(positionMatterpot);
    console.log("finishingModalInformations", imageToUse);
    setShowModalCanvas(true);
  }

  const saveInfoCanvas = (positionCanvas:any) =>{
    saveInfoPosition(positionMatterpot, positionCanvas);
    setShowModalInformation(false);
    setShowModalCanvas(false);
}

  return (
    <div id='ChangeMapModal'>
        <ModalInformation showModalInformation={showModalInformation} setShowModalInformation={setShowModalInformation} title={t("Calibration")} resetStates={handleClose} positionToSelect={positionToSelect} saveInfoMatterport={saveInfoMatterport} levelStartedProcess={levelStartedProcess}></ModalInformation>
        <CanvasInformation showModalCanvas={showModalCanvas} setShowModalCanvas={setShowModalCanvas} title={t("Calibration")} resetStates={handleClose} positionToSelect={positionToSelect} saveInfoCanvas={saveInfoCanvas}></CanvasInformation>
    </div>
  );
};

export default PointSelection;
