
import axios from "axios";
"api/models/nameExist"
const API_URL_MODELS_BY_PARENT_UUID = process.env.REACT_APP_DOMAIN + '/api/models/modelsByParentUUID/';
const API_URL_MODELS_POSITION = process.env.REACT_APP_DOMAIN + '/api/models/upadetePosition';
const API_URL_MODEL_BY_ID = process.env.REACT_APP_DOMAIN + '/api/models/getModelById';
const API_URL_MODELS_LIST = process.env.REACT_APP_DOMAIN + '/api/models/3dModelsList';
const API_URL_ADD_MODEL = process.env.REACT_APP_DOMAIN + '/api/models/addNewModel';
const API_URL_MODELS = process.env.REACT_APP_DOMAIN + '/api/models/';
const API_URL_CHECK_MODEL_NAME = process.env.REACT_APP_DOMAIN + '/api/models/nameExist';
const API_URL_MODELS_BY_SPACE = process.env.REACT_APP_DOMAIN + '/api/models/space';
const API_URL_MODEL_BY_NODE_ID_AND_NAME = process.env.REACT_APP_DOMAIN + '/api/models/node';
const API_URL_MODELS_FILES_NAME = process.env.REACT_APP_DOMAIN + '/api/modelsFiles';
const API_URL_MODELS_FILES_NAME_UPDATE = process.env.REACT_APP_DOMAIN + '/api/modelsFiles/edit';
const API_URL_MODELS_FILES_NAME_ADD = process.env.REACT_APP_DOMAIN + '/api/modelsFiles/new';
const API_URL_MODELS_FILES_DELETE_BY_NAME = process.env.REACT_APP_DOMAIN + '/api/modelsFiles/delete';

let config = {};

const getToken = () => {
    const storageToken = localStorage.getItem("token");
    const token = storageToken === (null || undefined) ? '' : storageToken;

    config = {
        headers: {
            "x-auth-token": token,
            "Content-Type": "application/json",
        },
    };
};

const getTokenDelete = () => {
    const storageToken = localStorage.getItem("token");
    return storageToken === (null || undefined) ? '' : storageToken;
}

const checkName = async (idParent, modelName) => {
    getToken();
    const response = await axios.post(API_URL_CHECK_MODEL_NAME, JSON.stringify({ idParent, modelName }), config);
    return response.data;
};


const updateModelsPositionByid = async (id, position, rotation, scale) => {
    getToken();
    const response = await axios.patch(API_URL_MODELS_POSITION, JSON.stringify({ id, position, rotation, scale }), config);
    return response.data;
};

const getModelById = async (id) => {
    getToken();
    const response = await axios.post(API_URL_MODEL_BY_ID, JSON.stringify({ id }), config);
    return response.data;
};


const getModelsList = async () => {
    getToken();
    const response = await axios.get(API_URL_MODELS_LIST, config);
    return response.data;
};
const getModelsOfObject = async () => {
    getToken();
    const response = await axios.get(API_URL_MODELS_LIST, config);
    return response.data;
};


const getModelsBySpace = async (space) => {
    getToken();
    const response = await axios.post(API_URL_MODELS_BY_SPACE, JSON.stringify({ space }), config);
    return response.data;
};
const addNewModel = async (idParent, modelData, sweepCameraPosition) => {
    if (modelData.interactionType === "goToDocumentData") {
        modelData.interactionReference = ""
    }
    console.log({ modelData })
    getToken();
    const response = await axios.put(API_URL_MODELS, JSON.stringify({ idParent, modelData, sweepCameraPosition }), config);
    return response.data;
};
const updateModel = async (idParent, modelData, sweepCameraPosition, previousName, modelOID) => {
    console.log({ modelData })
    if (modelData.interactionType === "goToDocumentData") {
        modelData.interactionReference = ""
    }
    modelData.modelOID = modelOID;
    console.log({ ModelToUpdate: modelData, idParent, modelData, sweepCameraPosition, previousName, modelOID })
    getToken();
    if (modelData.modelName === previousName) {
        previousName = ""
    }
    const response = await axios.patch(API_URL_MODELS, JSON.stringify({ idParent, modelData, sweepCameraPosition, previousName, modelOID }), config);
    return response.data;
};

const deleteModel = async (idParent, modelNameToDelete) => {
    const token = getTokenDelete();
    const response = await axios.delete(API_URL_MODELS,
        {
            data: { idParent, modelNameToDelete }, headers: {
                'x-auth-token': token,
                'Content-Type': 'application/json'
            }
        })
    return response.data;
};


const getModelsByParentUUID = async (idParent) => {
    getToken();
    const response = await axios.post(API_URL_MODELS, JSON.stringify({ idParent }), config);
    return response.data;
};
const getModelsFilesNames = async () => {
    getToken();
    const response = await axios.post(API_URL_MODELS_FILES_NAME, config);
    return response.data;
};

const updateModelFileName = async (previousName, newName) => {
    getToken();
    const response = await axios.post(API_URL_MODELS_FILES_NAME_UPDATE, { previousName, newName }, config);
    return response.data;
};

const addModelFileName = async (name, fileName, file) => {
    getToken();
    const response = await axios.post(API_URL_MODELS_FILES_NAME_ADD, { name, fileName, file }, config);
    return response.data;
};
const deleteModelFileName = async (modelName) => {
    const token = getToken();

    const response = await axios.delete(API_URL_MODELS_FILES_DELETE_BY_NAME,
        {
            data: { modelName }, headers: {
                'x-auth-token': token,
                'Content-Type': 'application/json'
            }
        })
    return response.data;
};
const getModelByNameAndId = async (id, name) => {
    getToken();
    const response = await axios.post(API_URL_MODEL_BY_NODE_ID_AND_NAME, JSON.stringify({ id, name }), config);
    return response.data;
};
const modelsServices = {
    updateModelsPositionByid,
    getModelById,
    getModelsList,
    addNewModel,
    checkName,
    getModelsByParentUUID,
    getModelsBySpace,
    updateModel,
    deleteModel,
    getModelsFilesNames,
    updateModelFileName,
    addModelFileName,
    deleteModelFileName,
    getModelByNameAndId
};

export default modelsServices;
