import React, { useState, useEffect, useContext } from 'react'
import Input from '../bootstrap/forms/Input'
import ListGroup from '../bootstrap/ListGroup';
import InputGroup from '../bootstrap/forms/InputGroup';
import documentsServices from '../../services/documentsServices';
import { OverlayContext } from '../../contexts/OverlayContext';
import { useTranslation } from "react-i18next";
import Button from '../bootstrap/Button';

import '../../styles/components/layout/_detailsNode.scss'


function DetailsNode(props) {
    const {
        editMode,
        setActiveSaveButton,
        setReturnView,
        isSaveClicked,
        setModalState,
        modalState
    } = useContext(OverlayContext);
    const { currentNodeSelected } = props;

    const [addNewProperty, setAddNewProperty] = useState(false);
    const [detailsData, setDetailsData] = useState([
    ]
    );

    const { t } = useTranslation('menu');

    const updateDocumentsToshow = () => {
        documentsServices.getAllPropierties(currentNodeSelected._id).then(res => {
            if (res.ok) {
                setDetailsData(res.documents)
            }
        }).catch(err => {
            if (err.response) {
                if (err.response.data.msg == "NotFound") {
                    setModalState({
                        ...modalState,
                        isOpen: !modalState.isOpen,
                        data: {
                            title: "Error",
                            text: "No se puede eleminar este elemento ",
                            id: "errorModel",
                            actionType: "error",
                        },
                    });
                }
            }
        })
    }
    const handleKeyNameInputChange = (event) => {
        const { name, value } = event.target;
        const updatedData = detailsData.map((item) =>
            item.position === parseInt(name) ? { ...item, property: value } : item
        );
        setActiveSaveButton(true)
        setDetailsData(updatedData)
    };

    // Manejar el cambio en el input y actualizar la propiedad "nombre" del estado
    const handleValueInputChange = (event) => {
        const { name, value } = event.target;
        const updatedData = detailsData.map((item) =>
            item.position === parseInt(name) ? { ...item, value } : item

        );
        setActiveSaveButton(true)
        setDetailsData(updatedData)
    };
    const handleDelete = (event) => {

        const { name } = event.target;

        setModalState({
            ...modalState,
            isOpen: !modalState.isOpen,
            continueClicked: false,
            data: {
                title: t("Delete"),
                text: t("QuestionDeleteProperty"),
                id: "deleteElement",
                actionType: "delete",
                elementName: name
            },
        });

    };

    useEffect(() => {
        if (editMode) {
            setReturnView(true);
        }
    }, [editMode])

    useEffect(() => {
        if (currentNodeSelected !== null && editMode === false) {
            updateDocumentsToshow();
        }
    }, [currentNodeSelected, editMode]);

    useEffect(() => {
        if (modalState.continueClicked && modalState.data.actionType === "delete") {
            documentsServices.deleteProperty(currentNodeSelected._id, modalState.data.elementName)
                .then(res => {
                    if (res.ok) {
                        updateDocumentsToshow();
                    }
                }).catch(err => {
                    if (err.response) {
                        if (err.response.data.msg == "NotFound") {
                            setModalState({
                                ...modalState,
                                isOpen: !modalState.isOpen,
                                data: {
                                    title: "Error",
                                    text: "No se puede eleminar este elemento ",
                                    id: "errorModel",
                                    actionType: "error",
                                },
                            });
                        }
                    }
                })
            setModalState({
                ...modalState,
                continueClicked: false,
            })


        }
    }, [modalState]);

    useEffect(() => {
        if (isSaveClicked && currentNodeSelected !== null) {
            documentsServices.insertProperties(currentNodeSelected._id, detailsData)
                .then(res => {
                    if (res.ok) {
                        setModalState({
                            ...modalState,
                            isOpen: !modalState.isOpen,
                            data: {
                                title: "Actualización",
                                text: "¡El elemento se actualizó correctamente en la base de datos!",
                                id: "newMOdel",
                                actionType: "edit",
                            },
                        });
                        setActiveSaveButton(false)
                    }
                })
        }
    }, [isSaveClicked])

    return (
        <>
            {detailsData.length > 0 && detailsData !== undefined ? (
                <div className="title row">
                    <div className='col-6 text-center halfSizeTwoElementsFirstElement' >
                        <h5 className='card-title '>{t("Name")}</h5>
                    </div>
                    <div className='col-6 text-center halfSizeTwoElements'>
                        <h5 className='card-title '>{t("Property")}</h5>
                    </div>
                </div>
            ) : (
                <></> // No renderizar nada si el arreglo está vacío o es indefinido
            )}


            <ListGroup id='detailsList'
                className='mb-3'
            >

                {(detailsData !== undefined && Array.isArray(detailsData)) ? detailsData.map((elemento, index) => (

                    <InputGroup
                        key={`new_${index}`}
                        className="propertyList"
                    >
                        <Input ariaLabel="Input"

                            className='mt-2 mr-2 input '

                            name={elemento.position}
                            placeholder={elemento.property}
                            value={elemento.property}
                            onChange={handleKeyNameInputChange}
                        />
                        <Input
                            className={` ${elemento.value?.match(/^https?:\/\/[^\s]+/i) ? "link" : ""} rigth-rounded mt-2 mr-2 input`}
                            name={elemento.position}
                            placeholder={elemento.value ? elemento.value : ""}
                            value={elemento.value ? elemento.value : ""}
                            onChange={handleValueInputChange}
                        ></Input>
                        <Button
                            className='detailsDeleteButton'
                            name={elemento.property}
                            color="danger"
                            isOutline
                            icon="delete"
                            size="sm"
                            onClick={handleDelete}
                        ></Button>
                    </InputGroup>
                )) : <></>}

            </ListGroup>

            <div
                className='display-flex'
            >
                <Button
                    className={`grow-0 ${!addNewProperty ? "" : "hidden"}`}
                    id="nuevoModelo"
                    color="primary"
                    onClick={() => {
                        detailsData.forEach(element => {
                            element.isNew = false;
                        }
                        )
                        setDetailsData(detailsData => [...detailsData, {
                            property: t("New"),
                            value: t("New"),
                            isNew: true,
                            position: detailsData.length + 1
                        }]);
                    }}
                    icon="add"
                >{t("New")}
                </Button>
                <div
                    className='grow-1'
                >
                </div>

            </div>

        </>
    )



}

export default DetailsNode