import React, { useState, useContext, useEffect, useRef } from 'react'
import FormGroup from '../bootstrap/forms/FormGroup'
import Label from '../bootstrap/forms/Label'

import Select from '../bootstrap/forms/Select'
import ListGroup from '../bootstrap/ListGroup'
import Button from '../bootstrap/Button'
import { OverlayContext } from '../../contexts/OverlayContext'
import EditableListItem from './EditableListItem'
import modelsServices from '../../services/modelsServices'
import matterportHelper, { currentPose } from '../../helpers/matterportHelper'
import { useTranslation } from "react-i18next";
import ModelManager from './ModelManager'
import { MatterportContext } from '../../contexts/MatterportContext'

import FormModel from '../FormModel'
function ModelProperty() {
    const [models, setModels] = useState([{
        "idParent": "64e4273d3e1a224a7b855565",
        "modelData"
            : {
            "modelName": "Model 1",
            "modelFile": "cube.fbx",
            "interactionType": "openURL",
            "modelFileName": "",
            "interactionReference": "Un Objeto a acceder",
            "objectTransform": {
                "xPosition": 0.01,
                "yPosition": 0.01,
                "zPosition": 0.01,
                "xRotation": 0.01,
                "yRotation": 0.01,
                "zRotation": 0.01,
                "xScale": 1,
                "yScale": 1,
                "zScale": 1
            },
            "sweepCameraPosition": {
                "sweepointUUID": "texto",
                "cameraHorizontalRotation": 0,
                "cameraVerticalRotation": 0
            }
        }
    }]);
    const [addNewModel, setAddNewModel] = useState(false);
    const [listElementData, setListElementData] = useState();
    const [lastIndex, setLasIndex] = useState(0);
    const [currentListModel, setCurrentListModel] = useState("")
    const itemList = useRef();


    const initialModelData = {

        modelName: "model 1",
        modelFile: "cube.fbx",
        interactionType: "openURL",
        interactionReference: "",
        objectTransform: "",
        sweepCameraPosition: {
            sweepointUUID: "",
            cameraHorizontalRotation: 0,
            cameraVerticalRotation: 0,
        },
        previousName: ""
    }



    const {
        editMode,
        setEditMode,
        setActiveSaveButton,
        setReturnView,
        setActivePositionButtons,
        setPositionEditObject,
        positionEditObject,
        currentNodeSelected,
        isSaveClicked,
        setModalState,
        modalState,
        addModelFile, setAddModelFile,
        modelData,
        setModelData,
    } = useContext(OverlayContext);

    const {
        fbxObjects,
        currentEditObject,
        modelList, setModelList,
        setCurrentEditObject,
        matterportSdk,
        setFbxObjects
    } = useContext(MatterportContext);

    const { t } = useTranslation('menu');

    const changeName = (name) => {

        setModelData({
            ...modelData, modelName: name

        })
    }

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const nuevoArchivo = {
                text: file.name,
                value: file.name,
            };
            setModelList([...modelList, nuevoArchivo]);
        }
    };
    const deleteByName = (name) => {

        modelsServices.deleteModel(currentNodeSelected._id, name)
            .then(res => {
                setEditMode(false)
                if (res.ok === true) {
                    updateModelToShow();
                    setModalState({
                        ...modalState,
                        isOpen: !modalState.isOpen,
                        data: {
                            title: t("DeletedElementCorrectlyTitle"),
                            text: t("DeletedElementCorrectly"),
                            id: "updateModel",
                            actionType: "update",
                        },
                    });
                    const res = matterportHelper.deleteModeltById(fbxObjects, currentNodeSelected._id + "/" + name);
                    console.log({ deleterRes: res })
                }
            }
            )
            .catch(err => console.error(err));
    }
    const updateModelToShow = () => {
        modelsServices.getModelsByParentUUID(currentNodeSelected._id).then(res => {
            if (res.ok === true) {
                console.log({ models: res.models })
                setModels(res.models)
                setLasIndex(res.lastIndex);
            }
        }).catch((err) => console.error({ err }))
    }
    const getElementData = (data) => {
        console.log({ data })
        setListElementData({ ...data, modelFileName: matterportHelper.getModelNameByOID(modelList, data.modelOID) });
        setModelData({
            ...modelData, modelName: data.name,
            interactionType: data.interactionType,
            modelFileName: matterportHelper.getModelNameByOID(modelList, data.modelOID),
            objectTransform: data.position,
            interactionReference: data.interactionReference,
            sweepCameraPosition: data.sweepCameraPosition,
            modelOID: data.modelOID
        })

        setPositionEditObject(prev => ({
            ...prev, // Copiar todas las propiedades existentes
            editsweepCameraPosition: data.sweepCameraPosition,
            initialModelData: {
                mongoName: data.parentOID + "/" + data.name,
                animated: 0,
                id: "",
                interactuable: data.interactionType,
                length: 1,
                interactuable_content: data.interactionReference,
                interactuable_type: data.interactionType,
                modelFilename: matterportHelper.getModelNameByOID(modelList, data.modelOID),
                modelformat: "fbx",
                parent: "",
                OID: data.modelOID,
                positionx: data.position.xPosition,
                positiony: data.position.yPosition,
                positionz: data.position.zPosition,
                rotationx: data.position.xRotation,
                rotationy: data.position.yRotation,
                rotationz: data.position.zRotation,
                scalex: data.position.xScale,
                scaley: data.position.yScale,
                scalez: data.position.zScale,
                originalOID: data.modelOID
            },
            isNew: false
        }));
        /**
         * 
         * 
         */
        if (data.modelOID !== "" && modelList !== undefined) {

            var objetoConId3 = modelList.find(objeto => objeto.id === data.modelOID);
            console.log({ objetoConId3 })
            console.log({ modelList })
            if (objetoConId3 !== undefined) {
                setCurrentListModel(objetoConId3.name)
                setPositionEditObject(prev => ({
                    ...prev, // Copiar todas las propiedades existentes
                    modelOID: data.modelOID,

                    active: false,
                    editsweepCameraPosition: data.sweepCameraPosition
                }));
            }
        }
    }

    const updateModelFileListToShow = () => {
        //  setAddModelFile(false)
        modelsServices.getModelsFilesNames()
            .then(res => {
                if (res.ok) {
                    console.log({ MdeolsFIles: res })
                    res.models.forEach(function (objeto) {

                        if (objeto.hasOwnProperty('name')) {

                            objeto.text = objeto.name;


                        }
                    });
                    console.log({ modelList })
                    console.log({ objLIST: res.models })
                    setModelList(res.models)
                }
            })
            .catch(err => console.error({ err }))
    }

    function turnOnModelByid() {
        const foundModel = modelList.find(model => model.id === positionEditObject.modelOID);
        matterportHelper.loadModelMongo(matterportSdk, {
            mongoName: currentNodeSelected._id + "/" + positionEditObject.name,
            animated: 0,
            id: 1,
            interactuable: positionEditObject.interactuable,
            length: 1,
            interactuable_content: "",
            interactuable_type: "text",
            modelfilename: foundModel.name,
            modelformat: "fbx",
            parent: "",
            positionx: objectTransform.position.x,
            positiony: objectTransform.position.y,
            positionz: objectTransform.position.z,
            rotationx: objectTransform.rotation.x,
            rotationy: objectTransform.rotation.y,
            rotationz: objectTransform.rotation.z,
            scalex: objectTransform.scale.x,
            scaley: objectTransform.scale.y,
            scalez: objectTransform.scale.z

        })
            .then((data) => {
                console.log({ nodeMOngo: data })
                matterportHelper.activeGizmo(data.node, matterportSdk).then(res);
                console.log({ gizmoRes: res })
                setCurrentEditObject({
                    model: data,
                    control: res

                });
            })
            .catch((err) => {
                console.error(err);
            });
    }
    const actualizarListaModelos = (nuevoEstado) => {
        setModelList(nuevoEstado);
    };

    function reloadModel(modelOID, sdk, initialModel, newData) {

        console.log({ modelOID, initialModel, newData })
        initialModel = {
            ...initialModel,
            positionx: newData.objectTransform.xPosition,
            positiony: newData.objectTransform.yPosition,
            positionz: newData.objectTransform.zPosition,
            rotationx: newData.objectTransform.xRotation,
            rotationy: newData.objectTransform.yRotation,
            rotationz: newData.objectTransform.zRotation,
            scalex: newData.objectTransform.xScale,
            scaley: newData.objectTransform.yScale,
            scalez: newData.objectTransform.zScale,
            interactuable_content: newData.interactionReference,
            interactuable_type: newData.interactionType,
            modelFilename: newData.modelFileName,
            parent: newData.parent
        }
        console.log({ fbxObjects })
        matterportHelper.deleteModeltById(fbxObjects, initialModel.mongoName).then(res => {
            console.log({ deleterRes: res })
        });
        matterportHelper.dispatchCustomEvent("delete", { msg: "Borrado" })
        matterportHelper.loadModelMongoOID(sdk,
            initialModel
            , false
        )
            .then((data) => {
                if (data !== undefined) {
                    data.isEdit = false;
                    console.log({ fbxObjects })
                    setFbxObjects(current => [...current, { data: data }]);
                    matterportHelper.borrarElementosConMismoIdExceptoUltimo(fbxObjects, initialModel.mongoName)
                }


            })
            .catch((err) => {
                console.error(err);

            });



    }



    useEffect(() => {
        if (editMode) {
            setReturnView(true);
            if (addNewModel === false) {
                modelData.previousName = listElementData.name;
            } else {
                modelData.previousName = ""
            }
        }

    }, [editMode])
    useEffect(() => {
        if (currentNodeSelected !== null && editMode === false) {
            updateModelToShow()
        }
    }, [currentNodeSelected, editMode])




    useEffect(() => {
        console.log({ modelPosition: positionEditObject })
        if (positionEditObject.editPosition !== undefined) {
            console.log({ positionEditObject, currentPose })
            setModelData({
                ...modelData, objectTransform: {
                    xPosition: positionEditObject.editPosition.position.x,
                    yPosition: positionEditObject.editPosition.position.y,
                    zPosition: positionEditObject.editPosition.position.z,
                    xRotation: positionEditObject.editPosition.rotation._x,
                    yRotation: positionEditObject.editPosition.rotation._y,
                    zRotation: positionEditObject.editPosition.rotation._z,
                    xScale: positionEditObject.editPosition.scale.x,
                    yScale: positionEditObject.editPosition.scale.y,
                    zScale: positionEditObject.editPosition.scale.z
                }
                , sweepCameraPosition: {
                    sweepointUUID: positionEditObject.editsweepCameraPosition.sweepointUUID,
                    cameraHorizontalRotation: positionEditObject.editsweepCameraPosition.cameraHorizontalRotation,
                    cameraVerticalRotation: positionEditObject.editsweepCameraPosition.cameraVerticalRotation
                },
                modelOID: positionEditObject.modelOID
            })
        }
    }, [positionEditObject]);

    useEffect(() => {
        if (isSaveClicked) {

            if (!addNewModel) {
                console.log({ modelData })
                modelsServices.updateModel(
                    currentNodeSelected._id,
                    modelData,
                    modelData.sweepCameraPosition,
                    modelData.previousName,
                    positionEditObject.initialModelData.OID

                )
                    .then(res => {
                        console.log({ UpdateREs: res })
                        console.log({ currentEditObject, positionEditObject })
                        modelData.parent = currentNodeSelected._id;
                        positionEditObject.initialModelData.scalex = modelData.objectTransform.xScale;
                        positionEditObject.initialModelData.scaley = modelData.objectTransform.yScale;
                        positionEditObject.initialModelData.scalez = modelData.objectTransform.zScale;
                        positionEditObject.initialModelData.rotationx = modelData.objectTransform.xRotation;
                        positionEditObject.initialModelData.rotationy = modelData.objectTransform.yRotation;
                        positionEditObject.initialModelData.rotationz = modelData.objectTransform.zRotation;


                        reloadModel(positionEditObject.initialModelData.OID, matterportSdk, positionEditObject.initialModelData, modelData)

                        //  setCurrentEditObject({})
                        setReturnView(false);
                        setEditMode(false)
                        setActiveSaveButton(false);
                        setModalState({
                            ...modalState,
                            isOpen: !modalState.isOpen,
                            data: {
                                title: t("UpdateTitle"),
                                text: t("UpdatedElementCorrectly"),
                                id: "newMOdel",
                                actionType: "new",
                            },
                        });
                    })
                    .catch((err) => console.error(err))
            } else if (isSaveClicked && addNewModel) {
                modelsServices.checkName(currentNodeSelected._id, modelData.modelName).then(res => {

                    if (res.ok === true && res.existName === false) {
                        console.log({ modelData, positionEditObject })
                        modelData.modelOID = positionEditObject.initialModelData.OID
                        modelsServices.addNewModel(
                            currentNodeSelected._id,
                            modelData,
                        )
                            .then(res => {
                                setEditMode(false)
                                setReturnView(false)
                                setActiveSaveButton(false)
                                reloadModel(positionEditObject.initialModelData.OID, matterportSdk, positionEditObject.initialModelData, modelData)
                                //  matterportHelper.turnOffAllCurrentFbxObjects(fbxObjects);
                                console.log({ DEleteObjet: currentEditObject })
                                if (currentEditObject.model !== undefined) {

                                    currentEditObject.control?.nodeControl?.stop();
                                    currentEditObject.model?.node.stop();
                                }
                                setModalState({
                                    ...modalState,
                                    isOpen: !modalState.isOpen,
                                    data: {
                                        title: t("NewElement"),
                                        text: t("NewElementSavedCorrectly"),
                                        id: "newModel",
                                        actionType: "new",
                                    },
                                });
                            })
                            .catch((err) => console.error(err))
                    } else if (res.ok === true && res.existName === true) {
                        setModalState({
                            ...modalState,
                            isOpen: !modalState.isOpen,
                            data: {
                                title: t("Error"),
                                text: t("AlreadyExistElement"),
                                id: "errorModel",
                                actionType: "error",
                            },
                        });
                    }
                }).catch(err => console.error({ err }));
            }
        }
    }, [isSaveClicked, addNewModel])
    useEffect(() => {
        updateModelFileListToShow();
        setAddModelFile(false)
        console.log({ modelData })
        setModelData(initialModelData)

    }, [])


    return (

        <>



            {editMode /*&& !addModelFile*/ ?

                <>

                    <FormModel
                        initialModel={

                            {
                                mongoName: modelData.parentOID + "/" + modelData.name,
                                animated: 0,
                                id: "",
                                interactuable: "openUrl",
                                length: 1,
                                interactuable_content: modelData.interactionReference,
                                interactuable_type: modelData.interactionType,
                                modelFileName: modelData.modelFileName,
                                modelformat: "fbx",
                                parent: "1233",
                                OID: modelData.modelOID,
                                positionx: modelData.xPosition ? modelData.xPosition : 0,
                                positiony: modelData.yPosition ? modelData.yPosition : 0,
                                positionz: modelData.zPosition ? modelData.zPosition : 0,
                                rotationx: 0,
                                rotationy: 0,
                                rotationz: 0,
                                scalex: modelData.xScale ? modelData.xScale : 1,
                                scaley: modelData.yScale ? modelData.yScale : 1,
                                scalez: modelData.zScale ? modelData.zScale : 1,
                                modelName: modelData.name
                            }
                        }
                        modelList={modelList}
                        models={models}
                    ></FormModel>

                </>
                :
                <>
                    <ListGroup
                        ref={itemList}
                        className={`mb-3 ${addModelFile ? 'hidden' : ''}`}
                        id="modelList"
                    >
                        {models.map((model, index) => (
                            <EditableListItem
                                key={index + model.modelName} name={model.modelName}
                                fileName={model.fileName}
                                modelOID={model.modelOID}
                                getElementData={getElementData}
                                deleteAction={deleteByName}
                                stateChanger={setEditMode}
                                changeName={changeName}
                                changePosition={changeName}
                                setStateNotNewVolume={setAddNewModel}
                                position={model.objectTransform}
                                interactionType={model.interactionType}
                                modelFileName={model.modelFileName}
                                interactionReference={model.interactionReference}
                                sweepCameraPosition={model.sweepCameraPosition}
                                parentOID={model.parentOID}
                            >

                            </EditableListItem>
                        ))}
                    </ListGroup>
                    <div className='display-flex'>
                        <Button
                            color="primary"
                            className={`grow-0 ${addModelFile ? 'hidden' : ''}`}
                            id='nuevoModelo'
                            onClick={() => {
                                setAddNewModel(true);
                                if (itemList.current.children.length > 0) {
                                    itemList.current.children[itemList.current.children.length - 1].scrollIntoView();
                                }
                                setEditMode(true);
                                setPositionEditObject(prev => ({
                                    ...prev, // Copiar todas las propiedades existentes
                                    name: "Model " + (Number(lastIndex) + 1),
                                    fileName: modalState.fileName, // Actualizar solo esta propiedad
                                    type: "modelOID",
                                    position: modelData.objectTransform,
                                    isNew: true

                                }));

                                setModelData({
                                    ...modelData,
                                    modelName: "Model " + (Number(lastIndex) + 1),
                                    modelFileName: t("noSelectedModel"),
                                    sweepCameraPosition: {
                                        sweepointUUID: currentPose.sweep,
                                        cameraHorizontalRotation: currentPose.rotation.y,
                                        cameraVerticalRotation: currentPose.rotation.x,
                                    }
                                })
                            }}
                            icon="add"
                        >{t("New")}</Button>
                        <div className='grow-1'
                        ></div>
                    </div>

                </>
            }
            {
                addModelFile ? <ModelManager
                    updateList={updateModelFileListToShow}

                ></ModelManager> : <></>
            }
        </ >
    )
}

export default ModelProperty