import { ReactNode, useContext, useLayoutEffect, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import LANG, { getLangWithKey, ILang } from "../../../../../../lang";
/* import useDarkMode from "../../../../../hooks/useDarkMode";

import { OverlayContext } from "../../../../../contexts/OverlayContext"; */


import './CanvasInformation.scss'
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../../../../components/bootstrap/Modal";
/* import { useFormik } from "formik";
import FormGroup from "../../../../../components/bootstrap/forms/FormGroup";
import { useToasts } from 'react-toast-notifications';
import Toasts from '../../../../../components/bootstrap/Toasts';
import Alert from '../../../../../components/bootstrap/Alert';
import Input from "../../../../../components/bootstrap/forms/Input";
import Button from "../../../../../components/bootstrap/Button";
import * as Yup from 'yup'; */
import { MatterportContext } from "../../../../../../contexts/MatterportContext";
import { OverlayContext } from "../../../../../../contexts/OverlayContext";

  
const CanvasInformation: any = (props: any) => {
    const { showModalCanvas, setShowModalCanvas, title, resetStates, positionToSelect, saveInfoCanvas } = props;
    const { matterportSdk } = useContext(MatterportContext);
    const { imageToUse } = useContext(OverlayContext);
    const [ imageOfCanvas, setImageOfCanvas] = useState<string | undefined>(undefined);
    const [ showInstructions, setShowInstructions ] = useState(false);
    const [ instructionText, setInstructionText ] = useState("");
    const [ pointImage, setPointImage ] = useState({
        x: 0, 
        y: 0 
    });
    const [sizeCanvas, setSizeCanvas] = useState({
        width: 0,
        height: 0,
    });
    const [ clickedOnCanvas, setClickedOnCanvas ] = useState(false);
    const { t } = useTranslation('menu');
    const { i18n } = useTranslation();
    const changeLanguage = (lng: ILang['key']['lng']) => {
        i18n.changeLanguage(lng);
    };

    /**
     * Language attribute
     */
    useLayoutEffect(() => {
        document.documentElement.setAttribute('lang', i18n.language.substring(0, 2));
    });

    useEffect(() => {
        if(showModalCanvas){
             cleanLocalInfo();
             if(typeof imageToUse === "string"){
                 setImageOfCanvas(imageToUse);
             }else{
                console.log(imageToUse);
                 setImageOfCanvas(window.URL.createObjectURL(imageToUse));
             }
             chooseTextToShow();
             setShowInstructions(true);    
             
        }
     }, [showModalCanvas]);

     const chooseTextToShow = () => {
        switch(positionToSelect){
            case "bottomRight": 
                setInstructionText(`${t("BaseInstructionText")} inferior derecha`);
                break;
            
            case "bottomLeft": 
                setInstructionText(`${t("BaseInstructionText")} inferior izquierda`);
                break;
            
            case "upperRight": 
                setInstructionText(`${t("BaseInstructionText")} superior derecha`);
                break;
            
            case "upperLeft": 
                setInstructionText(`${t("BaseInstructionText")} superior izquierda`);
                break;
            default:
                break;
        }
    };

    const setSizeCanvasOnLoadEvent = (width:any, height:any) => {
        setSizeCanvas({ width: width, height: height });
    };

    const getClickCanvasPosition = (canvas:any, event:any) => {
        const rect = canvas.getBoundingClientRect();
        const coordinatesCanvas ={
            x: event.clientX - rect.left,
            y: event.clientY - rect.top
          };
        setPointImage(coordinatesCanvas);
        setClickedOnCanvas(true);
    };

    const returnInfoToParent = () => {
        setShowModalCanvas(false);
        saveInfoCanvas(pointImage);
        cleanLocalInfo();
    };
    
    const cleanLocalInfo = () => {
        setImageOfCanvas(undefined);
        setShowInstructions(false);
        setInstructionText("");
        setPointImage({
            x: 0, 
            y: 0 
        });
        setSizeCanvas({
            width: 0,
            height: 0,
        });
        setClickedOnCanvas(false);
    }

    return (
        <Modal 
            id="modalConfig"
            titleId="titleConfig"
            isOpen={ showModalCanvas } // Example: state
            setIsOpen={ setShowModalCanvas } // Example: setState
            isStaticBackdrop={ true } 
            isScrollable={ false } 
            isCentered={true} 
            fullScreen= 'md' 
            isAnimation={ true }
            >
            <ModalHeader>
                <ModalTitle id="imageModalSectionTitle">{ title }</ModalTitle>
            </ModalHeader>
            <ModalBody>
            <div className='canvasParent'>
                <div className={` ${showInstructions ? 'instructionText' : 'hidden'} mb-2`}>{instructionText}</div>
                <div className='canvasContainer mt-1'>
                    <canvas
                        id="myCanvas"
                        width={sizeCanvas.width}
                        height={sizeCanvas.height}
                        onClick={e => getClickCanvasPosition(e.target, e)}
                    />
                    <img 
                    id="imageCanvas"
                    src={imageOfCanvas}
                    onLoad={e =>{
                        const img = e.target as HTMLImageElement;
                        setSizeCanvasOnLoadEvent(img.width, img.height)
                        }
                    }
                    ></img>
                </div>
            </div>
            </ModalBody>
            <ModalFooter>
                <button type='button' className='btn btn-primary'  onClick={returnInfoToParent} disabled={!clickedOnCanvas}>{t("Continue") as ReactNode}</button>
            </ModalFooter>
        </Modal>
    );
};

export default CanvasInformation;
