import React, { useState, useContext, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { demoPagesMenu } from "../../menu";

import Dropdown, {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "../../components/bootstrap/Dropdown";
import Button from "../../components/bootstrap/Button";
import useDarkMode from "../../hooks/useDarkMode";
import Collapse from "../../components/bootstrap/Collapse";
import { NavigationLine } from "../Navigation/Navigation";
import Icon from "../../components/icon/Icon";
import useNavigationItemHandle from "../../hooks/useNavigationItemHandle";
import USERS from "../../common/data/userDummyData";
import AuthContext from "../../contexts/authContext";

const User = () => {
  const { userData, setUser, user } = useContext(AuthContext);

  const navigate = useNavigate();
  const handleItem = useNavigationItemHandle();
  const { darkModeStatus, setDarkModeStatus } = useDarkMode();

  const [collapseStatus, setCollapseStatus] = useState<boolean>(true);

  const { t } = useTranslation(["menu"]);

  return (
    <>
      <div
        className={classNames("user", { open: collapseStatus })}
        role="presentation"
        /* onClick={() => setCollapseStatus(!collapseStatus)} */
      >
        <div className="user-avatar">
          <img
            //srcSet={userData?.srcSet}
            //src={userData?.src}
            srcSet={USERS.ADAN?.srcSet}
            src={USERS.ADAN?.src}
            alt="Avatar"
            width={128}
            height={128}
          />
        </div>
        <div className="user-info">
          <div className="user-name d-flex align-items-center">
            {`${user} `}
            <Icon icon="Verified" className="ms-1" color="info" />
          </div>
          <div className="user-sub-title">{userData.position}</div>
        </div>

        <Dropdown className="user-info" setIsOpen={() => { }}>
          <DropdownToggle hasIcon={false}>
            <Button
              isActive={true}
              color="link"
              icon="more_vert"
              rounded="circle"
              style={{ color: "white" }}
            />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem>
              <Button
                icon="lock"
                className="breakWords"
                onClick={() => {
                  navigate(`/auth-pages/updatePassword`);
                }}
              >
                {t('updatePassword') as ReactNode}
              </Button>
            </DropdownItem>
            <DropdownItem>
              <Button
                icon="logout"
                onClick={() => {
                  localStorage.removeItem("token");
                  navigate(`/auth-pages/login`);
                }}
              >
                {t('Logout') as ReactNode}
              </Button>
            </DropdownItem>
            <DropdownItem isDivider />
            <DropdownItem>
              <Button
                icon={darkModeStatus ? "LightMode" : "DarkMode"}
                onClick={() => setDarkModeStatus(!darkModeStatus)}
                aria-label="Toggle fullscreen"
              >
                {darkModeStatus ? "Light Mode" : "Dark Mode"}
              </Button>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <DropdownMenu>
        <DropdownItem>
          <Button
            icon={darkModeStatus ? "DarkMode" : "LightMode"}
            onClick={() => setDarkModeStatus(!darkModeStatus)}
            aria-label="Toggle fullscreen"
          >
            {darkModeStatus ? "Dark Mode" : "Light Mode"}
          </Button>
        </DropdownItem>
      </DropdownMenu>
    </>
  );
};

export default User;
