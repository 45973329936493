
import React, { useContext, useEffect, useState } from 'react';
import Label from '../bootstrap/forms/Label';
import Button from '../bootstrap/Button';

import Select from '../bootstrap/forms/Select';

import ListGroup from '../bootstrap/ListGroup';
import EditableListItem from './EditableListItem';
import { useRef } from 'react';
import modelsServices from '../../services/modelsServices';
import { useTranslation } from "react-i18next";
import { OverlayContext } from '../../contexts/OverlayContext';
import ModalInput from './ModalInput';
import matterportHelper from '../../helpers/matterportHelper';
import { MatterportContext } from '../../contexts/MatterportContext';

const initialModelData = {

    modelName: "model 1",
    modelFile: "cube.fbx",
    interactionType: "",
    interactionReference: "",
    objectTransform: "",
    sweepCameraPosition: {
        sweepointUUID: "",
        cameraHorizontalRotation: 0,
        cameraVerticalRotation: 0,
    },
    previousName: ""
}

const ModelManager = (props) => {
    const { updateList } = props;
    const {
        editMode,
        setEditMode,
        setModalState
    } = useContext(OverlayContext);
    const {
        matterportSdk
    } = useContext(MatterportContext);


    const { t } = useTranslation('menu');
    const itemList = useRef();
    const [modelData, setModelData] = useState(initialModelData)
    const [listElementData, setListElementData] = useState();
    const [addNewModel, setAddNewModel] = useState(false);
    const [showModal, setShowModal] = useState({
        show: false,
        title: "test",
        nameOnButton: "Regresar",
        typeButton: "info",
        typeAction: "new",
        dataObj: "",
        prevName: "",
        file: ""
    })
    const [modelList, setModelList] = useState([
        {
            name: '',

        },

    ])

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const nuevoArchivo = {
                name: file.name,
            };
            setModelList([...modelList, nuevoArchivo]);
        }
    };
    const getElementData = (data) => {
        setShowModal({
            show: true,
            title: "Modelo",
            nameOnButton: "Guardar",
            typeButton: "info",
            dataObj: '',
            typeAction: "edit",
            name: data.name,
            prevName: data.name,
            file: ""

        })
        console.log({ data })
        setListElementData(data);
        setModelData({
            ...modelData, modelName: data.name,
        })
    }
    useEffect(() => {
        updateModelList();
    }, [])

    useEffect(() => {
        console.log({ showModal })
    }, [showModal])
    const deleteByName = (name) => {


        modelsServices.deleteModelFileName(name)
            .then(res => {
                setEditMode(false)
                updateModelList();
                if (res.ok === true) {
                    setModalState({
                        ...modalState,
                        isOpen: !modalState.isOpen,
                        data: {
                            title: t("DeletedElementCorrectlyTitle"),
                            text: t("DeletedElementCorrectly"),
                            id: "updateModel",
                            actionType: "update",
                        },
                    });
                }
            }
            )
            .catch(err => console.error(err));

    }

    /*
        const [showModal, setShowModal] = useState({
            show: true,
            title: "test",
            nameOnButton: "Regresar",
            typeButton: "info",
            typeAction: "new",
            dataObj: ""
        });
        */
    const updateModelList = () => {
        updateList()
        modelsServices.getModelsFilesNames()
            .then((res) => {
                if (res.ok) {

                    setModelList(res.models)
                }
            })
            .catch(err => {
                console.error({ err })
            })
    };
    return (
        <div id="modelManager">
            <ModalInput
                showModalI={showModal}
                updateModelList={updateModelList}

            />
            <Label htmlFor="selectType">
                {t("ModelList")}
            </Label>



            <ListGroup
                ref={itemList}
                className="mb-3 "
            >
                {modelList.map((model, index) => (
                    <EditableListItem
                        key={index + model.name} name={model.name}
                        modelName={model.filename}
                        getElementData={getElementData}
                        deleteAction={deleteByName}
                        stateChanger={setEditMode}
                        //   changeName={changeName}
                        //changePosition={changeName}
                        setStateNotNewVolume={setAddNewModel}
                    >

                    </EditableListItem>
                ))}
            </ListGroup>
            {/*<input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleFileUpload} />*/}

            <Button
                ariaLabel
                id="editPostion"
                color="primary"
                className=' '
                onClick={() => {

                    // document.getElementById('fileInput').click()

                    setShowModal({
                        show: true,
                        title: "Modelo",
                        nameOnButton: "Guardar",
                        typeButton: "info",
                        dataObj: '',
                        typeAction: "newFile",
                        file: ""

                    })
                }

                }
            >
                Subir modelo
            </Button>
            {
                /*
                <Button
                            onClick={() => {
                                alert("Test")
                                matterportHelper.loadModelMongo(matterportSdk, {})
                            }}
                        >
                            TEST
                        </Button>
                */
            }

        </div>
    );
}

export default ModelManager;
