import React, { useEffect, useState,useContext } from 'react';
import OffCanvas, { OffCanvasBody, OffCanvasHeader } from '../../../components/bootstrap/OffCanvas';
import Chat, { ChatGroup, ChatHeader } from '../../../components/Chat';
import InputGroup from '../../../components/bootstrap/forms/InputGroup';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import Button from '../../../components/bootstrap/Button';
import USERS from '../../../common/data/userDummyData';
import Avatar from '../../../components/Avatar';
import showNotification from '../../../components/extras/showNotification';
import CHATS from '../../../common/data/chatDummyData';
import AuthContext from '../../../contexts/authContext';
const CommonHeaderChat = () => {
	const { user,userData } = useContext(AuthContext);

	return (
		<>
			<div
				className='col d-flex align-items-center cursor-pointer'
				//onClick={() => setState(!state)}
				role='presentation'>
				<div className='me-3'>
					<div className='text-end'>
						<div className='fw-bold fs-6 mb-0'>
							{`${user}`}
						</div>
						<div className='text-muted'>
							<small>{typeof userData !== "undefined"?userData.position:"no postion"}</small>
						</div>
					</div>
				</div>
				<div className='position-relative'>
					<Avatar
						srcSet={USERS.ADAN.srcSet}
						src={USERS.ADAN.src}
						size={48}
						color={USERS.ADAN.color}
					/>
					
					<span className='position-absolute top-85 start-85 translate-middle badge border border-2 border-light rounded-circle bg-success p-2'>
						<span className='visually-hidden'>Online user</span>
					</span>
				</div>
			</div>
			
		</>
	);
};

export default CommonHeaderChat;
