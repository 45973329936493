


function restartHierarchyData() {
    localStorage.removeItem("dataJson");
};
  const hierarchyHelper = {
restartHierarchyData
};

export default hierarchyHelper;