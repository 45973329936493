import React from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import Navigation from '../../../layout/Navigation/Navigation';
import { componentPagesMenu, pageLayoutTypesPagesMenu } from '../../../menu';
import useDeviceScreen from '../../../hooks/useDeviceScreen';
import CommonHeaderRight from './CommonHeaderRight';
import Button from '../../../components/bootstrap/Button';
import Breadcrumb from '../../../components/bootstrap/Breadcrumb';
import { useLocation } from 'react-router-dom';

interface crumbType {
	title: string,
	to: string
}
const DefaultHeader = () => {
	let breadCrumbList: crumbType[]=[];
	const location=useLocation();
	let currentLink:string='';
	const { width } = useDeviceScreen();

	const crumbs= location.pathname.split('/')
	.filter(crumb=>crumb!=='')
	.map(crumb=>{
		currentLink=currentLink + `/${crumb}`
		const currentCrumb:crumbType ={
			title:crumb,
			to:currentLink
		};
		
		breadCrumbList.push(currentCrumb)
	});
	
	return (
		<Header>
 		<HeaderLeft>
{/**
 * <Navigation
					menu={{ ...pageLayoutTypesPagesMenu, ...componentPagesMenu }}
					id='header-top-menu'
					horizontal={
						!!width && width >= Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)
						
					}
				/>
 * 
 */}				
<Breadcrumb
list={breadCrumbList
	}
/>

			</HeaderLeft>
			<CommonHeaderRight />
		</Header>
	);
};

export default DefaultHeader;
