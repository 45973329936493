import React, { useEffect, useState } from "react";

import { Route, Routes } from "react-router-dom";
import headers from "../../routes/headerRoutes";
const HeaderRoutes = () => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
  }, [mounted]);
  return (
    <>
      {mounted ? (
        <Routes>
          {headers.map((page) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Route key={page.path} {...page} />
          ))}
        </Routes>
      ) : null}
    </>
  );
};
export default HeaderRoutes;
