

import VolumeProperty from './VolumeProperty'
import SensorProperty from './SensorProperty'
import ModelProperty from './ModelProperty'
import DetailsNode from './DetailsNode'
import EditHierarchy from './EditHierarchy'


function PropertiesPanel({ type, currentNodeSelected }) {

    switch (type) {
        case "volume":
            return <VolumeProperty></VolumeProperty>

        case "sensor":
            return <SensorProperty></SensorProperty>

        case "model":
            return <ModelProperty></ModelProperty>

        case "details":
            return <DetailsNode currentNodeSelected={currentNodeSelected}></DetailsNode>

        case "rootNode":
            return <EditHierarchy rootNodeOption={true}></EditHierarchy>


        case "hierachy":
            return <EditHierarchy rootNodeOption={false}></EditHierarchy>

    }
}

export default PropertiesPanel