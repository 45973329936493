import axios from "axios";

const API_GET_USERS = process.env.REACT_APP_SERVER_DOMAIN + "/api/users/";
const API_PATCH_SECRET =
  process.env.REACT_APP_SERVER_DOMAIN + "/api/users/";
const API_PATCH_CHANGE_STATUS =
  process.env.REACT_APP_SERVER_DOMAIN + "/api/users/status";
const API_CHECK_MAIL =
  process.env.REACT_APP_SERVER_DOMAIN + "/api/users/checkMail";
const API_SET_USER =
  process.env.REACT_APP_SERVER_DOMAIN + "/api/users/user";
const API_UPDATE_EXPIRED_PASSWORD =process.env.REACT_APP_SERVER_DOMAIN + "/api/users/expiredSecret"; 
const API_UPDATE_SPACES =process.env.REACT_APP_SERVER_DOMAIN + "/api/users/updateUserSpaces"; 
let config = {
  headers: {
    "Content-Type": "application/json",
    "x-auth-token": ''
  },
};


const getToken = () => {
  const storageToken = localStorage.getItem("token");
  const token = storageToken === (null || undefined) ? '' : storageToken;

 if(token !== null){
  config = {
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json",
    },
  };
 }
}

const getUsers = () => {
  getToken();
  return axios
    .get(API_GET_USERS, config)
    .then(function (response: any) {
      return(response.data);
    })
    .catch(function (error: any) {
      const token = localStorage.getItem("token");
      return({ token, error });
    });
};

const resetSecret:any = function (id: string) {
  
    return axios
      .patch(API_PATCH_SECRET, { id }, config)
      .then(function (response: any) {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  
};


const changeStatusById:any = function (id: string, status: boolean) {
  
    return axios
      .patch(API_PATCH_CHANGE_STATUS, { id, status }, config)
      .then(function (response: any) {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
};

const setNewUser = (data: any) => {
  return axios.post(API_SET_USER, data, config)
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    return error.response.data;
  });
} 

const updateUser = (data: any) => {
  return axios.put(API_SET_USER, data, config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}
const updateExpiredPassWord = (data: any) => {
  getToken();
  return axios.post(API_UPDATE_EXPIRED_PASSWORD, data, config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}
const updateUserSpaces = (id:string,spaceList:string[],showAllSites:boolean) => {
  return axios.patch(API_UPDATE_SPACES,{id, spaceList,showAllSites}, config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

API_UPDATE_SPACES
const usersServices = {
  getUsers,
  resetSecret,
  changeStatusById,
  setNewUser,
  updateUser,
  updateExpiredPassWord,
  updateUserSpaces
};
export default usersServices;
