import React, { useMemo, useState } from "react";

type OverlayContext = {
  detailsIsOpen: boolean;
  setDetailsIsOpen: React.Dispatch<React.SetStateAction<boolean>>;

  state1: boolean;
  setState1: React.Dispatch<React.SetStateAction<boolean>>;

  updateHierarchy: boolean;
  setUpdateHierarchy: React.Dispatch<React.SetStateAction<boolean>>;

  currentSensors: any;
  setCurrentSensors: React.Dispatch<React.SetStateAction<any>>;

  currentNodeSelected: any;
  setCurrentNodeSelected: React.Dispatch<React.SetStateAction<any>>;
  toolbarIsOpen: boolean;
  setToolbarIsOpen: React.Dispatch<React.SetStateAction<boolean>>;

  offCanvasOpen: boolean;
  setOffCanvasOpen: React.Dispatch<React.SetStateAction<boolean>>;

  activeSaveButton: boolean;
  setActiveSaveButton: React.Dispatch<React.SetStateAction<boolean>>;
  editMode: boolean;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  returnView: boolean;
  setReturnView: React.Dispatch<React.SetStateAction<boolean>>;

  activePositionButtons: boolean;
  setActivePositionButtons: React.Dispatch<React.SetStateAction<boolean>>;

  positionEditObject: any;
  setPositionEditObject: React.Dispatch<React.SetStateAction<any>>;

  isSaveClicked: boolean;
  setisSaveClicked: React.Dispatch<React.SetStateAction<boolean>>;

  modalState: any;
  setModalState: React.Dispatch<React.SetStateAction<any>>;

  sensorsIsActive: boolean;
  setSensorsIsActive: React.Dispatch<React.SetStateAction<boolean>>;

  autoCompleteList: any;
  setautoCompleteList: React.Dispatch<React.SetStateAction<any>>;

  hierarchyRootOption: boolean;
  setHierarchyRootOption: React.Dispatch<React.SetStateAction<any>>;

  addModelFile: boolean;
  setAddModelFile: React.Dispatch<React.SetStateAction<any>>;

  hierarchyEventsActive: boolean;
  setHierarchyEventsActive: React.Dispatch<React.SetStateAction<any>>;
  modelData: any;
  setModelData: React.Dispatch<React.SetStateAction<any>>;

  volumeData: any;
  setVolumeData: React.Dispatch<React.SetStateAction<any>>;

  editModel: any;
  setEditModel: React.Dispatch<React.SetStateAction<any>>;

  type: any;
  setType: React.Dispatch<React.SetStateAction<any>>;

  currentFloor: number;
  setCurrentFloor: React.Dispatch<React.SetStateAction<number>>;

  mapSourceCurrentFloor: string;
  setMapSourceCurrentFloor: React.Dispatch<React.SetStateAction<string>>;

  currentImage: string; 
  setCurrentImage: React.Dispatch<React.SetStateAction<string>>;

  stageModal: number;
  setStageModal: React.Dispatch<React.SetStateAction<number>>;

  openModalImage: boolean;
  setOpenModalImage: React.Dispatch<React.SetStateAction<boolean>>;

  imageToUse: any;
  setImageToUse: React.Dispatch<React.SetStateAction<any>>;

  nameImageToUse: string;
  setNameImageToUse: React.Dispatch<React.SetStateAction<string>>;

  recalibrationProcess: boolean;
  setRecalibrationProcess: React.Dispatch<React.SetStateAction<boolean>>;

  fileNameConfigProcess: string;
  setFileNameConfigProcess: React.Dispatch<React.SetStateAction<string>>;
};

export const OverlayContext = React.createContext<OverlayContext>({
  state1: true,
  setState1: () => { },
  updateHierarchy: false,
  setUpdateHierarchy: () => { },

  detailsIsOpen: true,
  setDetailsIsOpen: () => { },
  currentSensors: [
    {
      Id: 1,
      Max: "1.2",
      Min: "0.6",
      Percentage: "25",
      Sensor_tag: "W200004_S1",
      UUID_Parent: "d51abf9a-1a5f-11ed-b646-002b67db2934",
      rotationx: 0.00731324,
      rotationy: -0.000825457,
      rotationz: -0.0315229,
      source:
        "http://localhost:3500/d-solo/SCSmFAb4z/new-dashboard?orgId=1&from=1685996911173&to=1686018511173&panelId=4from=1678713014157&to=1678734614158&panelId=6",
      units: "bar",
      val: "0.8",
      xpos: "28",
      ypos: "1",
      zpos: "-22.941460510505426",
    },
    {
      Id: 2,
      Max: "22",
      Min: "18",
      Percentage: "25",
      Sensor_tag: "W200004_S2",
      UUID_Parent: "d51abf9a-1a5f-11ed-b646-002b67db2934",
      rotationx: 176.853,
      rotationy: 3.31371,
      rotationz: 177.118,
      source:
        "http://localhost:3500/d-solo/SCSmFAb4z/new-dashboard?orgId=1&from=1685996911173&to=1686018511173&panelId=4from=1678711087660&to=1678732687661&panelId=2",
      units: "°C",
      val: "20",
      xpos: "28.17368261172855",
      ypos: "-0.045151328063453566",
      zpos: "-25.687043665254855",
    },
    {
      Id: 3,
      Max: "45",
      Min: "10",
      Percentage: "25",
      Sensor_tag: "W200004_S2",
      UUID_Parent: "d51abf9a-1a5f-11ed-b646-002b67db2934",
      rotationx: 179.215,
      rotationy: 85.9935,
      rotationz: -179.272,
      source:
        "http://localhost:3500/d-solo/SCSmFAb4z/new-dashboard?orgId=1&from=1685996911173&to=1686018511173&panelId=4from=1678711087660&to=1678732687661&panelId=2",
      units: "°K",
      val: "21",
      xpos: "25",
      ypos: "0.36758668242504644",
      zpos: "-23.5549605792781",
    },
  ],
  setCurrentSensors: () => { },
  currentNodeSelected: null,
  setCurrentNodeSelected: () => { },
  toolbarIsOpen: true,
  setToolbarIsOpen: () => { },
  offCanvasOpen: true,
  setOffCanvasOpen: () => { },
  editMode: false,
  setEditMode: () => { },

  returnView: false,
  setReturnView: () => { },

  activeSaveButton: false,
  setActiveSaveButton: () => { },

  activePositionButtons: false,
  setActivePositionButtons: () => { },

  positionEditObject: {
    name: "",
    active: false,
    editPosition: {
      position: { x: 0, y: 0, z: 0 },
      rotation: { _x: 0, _y: 0, _z: 0 },
      scale: { x: 1, y: 1, z: 1 },
    },
    modelOid: "",
    editsweepCameraPosition: {
      sweepointUUID: "5f2891d883da44ecbb6adc77bc6ce519",
      cameraHorizontalRotation: -43.122998156834875,
      cameraVerticalRotation: -5.520044221174712,
    },
  },
  setPositionEditObject: () => { },

  isSaveClicked: true,
  setisSaveClicked: () => { },

  modalState: {
    isOpen: true,
    continueClicked: false,
    data: {
      title: "PIZZA",
      text: "asd",
      id: "zxc",
      actionType: "edit",
      elementName: "",
    },
  },
  setModalState: () => { },
  sensorsIsActive: true,
  setSensorsIsActive: () => { },
  autoCompleteList: {},
  setautoCompleteList: () => { },

  hierarchyRootOption: false,
  setHierarchyRootOption: () => { },

  addModelFile: false,
  setAddModelFile: () => { },



  hierarchyEventsActive: false,
  setHierarchyEventsActive: () => { },

  modelData: {

    modelName: "model 1",
    modelFile: "cube.fbx",
    interactionType: "openURL",
    interactionReference: "",
    objectTransform: "",
    sweepCameraPosition: {
      sweepointUUID: "",
      cameraHorizontalRotation: 0,
      cameraVerticalRotation: 0,
    },
    previousName: "",
    modelOid: ""
  },
  setModelData: () => { },

  volumeData: {

    volumeName
      : "",
    interactionType: "goToDocumentData",
    interactionReference: "",
    objectTransform: {
      xPosition: 0,
      yPosition: 0,
      zPosition: 0,
      xRotation: 0,
      yRotation: 0,
      zRotation: 0,
      xScale: 0,
      yScale: 0,
      zScale: 0,

    },
    sweepCameraPosition: {
      sweepointUUID: "text",
      cameraHorizontalRotation: 0.1,
      cameraVerticalRotation: 0.1,
    },
    previousName: ""
  },
  setVolumeData: () => { },

  editModel: undefined,
  setEditModel: () => { },

  type: "move",
  setType: () => { },

  currentFloor: -1,
  setCurrentFloor: () => { },

  mapSourceCurrentFloor: "",
  setMapSourceCurrentFloor: () => { },

  currentImage: "", 
  setCurrentImage: () => { },

  stageModal: -1,
  setStageModal: () => { },

  openModalImage: false,
  setOpenModalImage: () => { },

  imageToUse: undefined,
  setImageToUse: () => { },

  nameImageToUse: "",
  setNameImageToUse: () => { },

  recalibrationProcess: false,
  setRecalibrationProcess: () => { },

  fileNameConfigProcess: "",
  setFileNameConfigProcess: () => { },

});

export const OverlayProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [toolbarIsOpen, setToolbarIsOpen] = useState(false);
  const [detailsIsOpen, setDetailsIsOpen] = useState(false);

  const [state1, setState1] = useState(true);
  const [currentSensors, setCurrentSensors] = useState([]);
  const [currentNodeSelected, setCurrentNodeSelected] = useState();
  const [offCanvasOpen, setOffCanvasOpen] = useState(true);
  const [activeSaveButton, setActiveSaveButton] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [returnView, setReturnView] = useState(false);
  const [activePositionButtons, setActivePositionButtons] = useState(false);
  const [positionEditObject, setPositionEditObject] = useState({});
  const [isSaveClicked, setisSaveClicked] = useState(false);
  const [modalState, setModalState] = useState({});
  const [updateHierarchy, setUpdateHierarchy] = useState(false);
  const [sensorsIsActive, setSensorsIsActive] = useState(false);
  const [autoCompleteList, setautoCompleteList] = useState();
  const [hierarchyRootOption, setHierarchyRootOption] = useState(false);
  const [addModelFile, setAddModelFile] = useState(false);
  const [hierarchyEventsActive, setHierarchyEventsActive] = useState(false);
  const [modelData, setModelData] = useState({});
  const [volumeData, setVolumeData] = useState({});
  const [editModel, setEditModel] = useState(undefined);
  const [type, setType] = useState("move");
  const [currentFloor, setCurrentFloor] = useState(-1);
  const [mapSourceCurrentFloor, setMapSourceCurrentFloor] = useState("");
  const [currentImage, setCurrentImage] = useState("");
  const [stageModal, setStageModal] = useState(-1);
  const [openModalImage, setOpenModalImage] = useState(false);
  const [imageToUse, setImageToUse] = useState();
  const [nameImageToUse, setNameImageToUse] = useState("");
  const [recalibrationProcess, setRecalibrationProcess] = useState(false);
  const [fileNameConfigProcess, setFileNameConfigProcess] = useState("");

  const conextValue = useMemo(() => ({
    state1,
        setState1,
        detailsIsOpen,
        setDetailsIsOpen,
        currentSensors,
        setCurrentSensors,
        currentNodeSelected,
        setCurrentNodeSelected,
        toolbarIsOpen,
        setToolbarIsOpen,
        offCanvasOpen,
        setOffCanvasOpen,
        activeSaveButton,
        setActiveSaveButton,
        editMode,
        setEditMode,
        returnView,
        setReturnView,
        activePositionButtons,
        setActivePositionButtons,
        positionEditObject,
        setPositionEditObject,
        modalState,
        setModalState,
        isSaveClicked,
        setisSaveClicked,
        updateHierarchy,
        setUpdateHierarchy,
        sensorsIsActive,
        setSensorsIsActive,
        autoCompleteList,
        setautoCompleteList,
        hierarchyRootOption,
        setHierarchyRootOption,
        addModelFile, setAddModelFile,
        hierarchyEventsActive, setHierarchyEventsActive,
        modelData, setModelData,
        volumeData, setVolumeData,
        editModel, setEditModel,
        type, setType, 
        currentFloor, 
        setCurrentFloor,
        mapSourceCurrentFloor, 
        setMapSourceCurrentFloor,
        currentImage, 
        setCurrentImage,
        stageModal, 
        setStageModal,
        openModalImage, 
        setOpenModalImage,
        imageToUse, 
        setImageToUse,
        nameImageToUse, 
        setNameImageToUse,
        recalibrationProcess,
        setRecalibrationProcess,
        fileNameConfigProcess,
        setFileNameConfigProcess
  }),[state1,
    setState1,
    detailsIsOpen,
    setDetailsIsOpen,
    currentSensors,
    setCurrentSensors,
    currentNodeSelected,
    setCurrentNodeSelected,
    toolbarIsOpen,
    setToolbarIsOpen,
    offCanvasOpen,
    setOffCanvasOpen,
    activeSaveButton,
    setActiveSaveButton,
    editMode,
    setEditMode,
    returnView,
    setReturnView,
    activePositionButtons,
    setActivePositionButtons,
    positionEditObject,
    setPositionEditObject,
    modalState,
    setModalState,
    isSaveClicked,
    setisSaveClicked,
    updateHierarchy,
    setUpdateHierarchy,
    sensorsIsActive,
    setSensorsIsActive,
    autoCompleteList,
    setautoCompleteList,
    hierarchyRootOption,
    setHierarchyRootOption,
    addModelFile, setAddModelFile,
    hierarchyEventsActive, setHierarchyEventsActive,
    modelData, setModelData,
    volumeData, setVolumeData,
    editModel, setEditModel,
    type, setType, 
    currentFloor, 
    setCurrentFloor,
    mapSourceCurrentFloor,
    setMapSourceCurrentFloor,
    currentImage, 
    setCurrentImage,
    stageModal, 
    setStageModal,
    openModalImage, 
    setOpenModalImage,
    imageToUse, 
    setImageToUse,
    nameImageToUse, 
    setNameImageToUse,
    recalibrationProcess,
    setRecalibrationProcess,
    fileNameConfigProcess,
    setFileNameConfigProcess
  ]);

  return (
    <OverlayContext.Provider value={conextValue}>
      {children}
    </OverlayContext.Provider>
  );
};
