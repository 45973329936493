import React, { useMemo, useState } from "react";

type MatterportContextProps = {
  state1: boolean;
  setState1: React.Dispatch<React.SetStateAction<boolean>>;
  site: string;
  setSite: React.Dispatch<React.SetStateAction<string>>;
  startPoint: string;
  setStartPoint: React.Dispatch<React.SetStateAction<string>>;
  matterportSdk: any;
  setMatterportSdk: React.Dispatch<React.SetStateAction<any>>;
  activeMeasure: boolean;
  setActiveMeasure: React.Dispatch<React.SetStateAction<boolean>>;
  activeSensors: boolean;
  setActiveSensors: React.Dispatch<React.SetStateAction<boolean>>;
  sensors: any;
  setSensors: React.Dispatch<React.SetStateAction<any>>;
  fbxObjects: any;
  setFbxObjects: React.Dispatch<React.SetStateAction<any>>;

  currentEditObject: any;
  setCurrentEditObject: React.Dispatch<React.SetStateAction<any>>;

  currentSensors: any;
  setCurrentSensors: React.Dispatch<React.SetStateAction<any>>;

  currentVolumes: any;
  setCurrentVolumes: React.Dispatch<React.SetStateAction<any>>;


  modelList: any;
  setModelList: React.Dispatch<React.SetStateAction<any>>;

  appState: any;
  setAppState: React.Dispatch<React.SetStateAction<any>>;

  activeConfigModal: boolean;
  setActiveConfigModal: React.Dispatch<React.SetStateAction<boolean>>;

  secretModelCtx: string | null;
  setSecretModelCtx: React.Dispatch<React.SetStateAction<string | null>>;

  messageErrorLoadMAtterport: string;
  setMessageErrorLoadMAtterport: React.Dispatch<React.SetStateAction<string>>;
 
};

export const MatterportContext = React.createContext<MatterportContextProps>({
  state1: true,
  setState1: () => { },
  site: "",
  setSite: () => { },
  startPoint: "",
  setStartPoint: () => { },
  matterportSdk: undefined,
  setMatterportSdk: () => { },
  activeMeasure: false,
  setActiveMeasure: () => { },
  activeSensors: false,
  setActiveSensors: () => { },
  sensors: [],
  setSensors: () => { },
  fbxObjects: [],
  setFbxObjects: () => { },
  currentEditObject: [],
  setCurrentEditObject: () => { },
  currentSensors: [],
  setCurrentSensors: () => { },
  currentVolumes: [],
  setCurrentVolumes: () => { },
  modelList: [],
  setModelList: () => { },
  appState: "Loading",
  setAppState: () => { },
  activeConfigModal: false,
  setActiveConfigModal: () => { },
  secretModelCtx: null,
  setSecretModelCtx: () => { },
  messageErrorLoadMAtterport: '',
  setMessageErrorLoadMAtterport: () => { },
});

export const MatterportProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state1, setState1] = useState(true);
  const [site, setSite] = useState("");
  const [matterportSdk, setMatterportSdk] = useState(undefined);
  const [activeMeasure, setActiveMeasure] = useState(false);
  const [activeSensors, setActiveSensors] = useState(false);
  const [startPoint, setStartPoint] = useState("null");
  const [sensors, setSensors] = useState([]);
  const [fbxObjects, setFbxObjects] = useState([]);
  const [currentEditObject, setCurrentEditObject] = useState({});
  const [currentSensors, setCurrentSensors] = useState([]);
  const [currentVolumes, setCurrentVolumes] = useState([]);
  const [modelList, setModelList] = useState([
    {
      name: '',
      value: ""
    },

  ])
  const [appState, setAppState] = useState("Loading");
  const [activeConfigModal, setActiveConfigModal] = useState(false);
  const [secretModelCtx, setSecretModelCtx] = useState<string | null>(null);
  const [messageErrorLoadMAtterport, setMessageErrorLoadMAtterport] = useState('');

  const value = useMemo(() => ({
    state1,
    setState1,
    site,
    setSite,
    startPoint,
    setStartPoint,
    matterportSdk,
    setMatterportSdk,
    activeMeasure,
    setActiveMeasure,
    activeSensors,
    setActiveSensors,
    sensors,
    setSensors,
    fbxObjects,
    setFbxObjects,
    currentEditObject,
    setCurrentEditObject,
    currentSensors,
    setCurrentSensors,
    currentVolumes,
    setCurrentVolumes,
    modelList,
    setModelList,
    appState,
    setAppState,
    activeConfigModal,
    setActiveConfigModal,
    secretModelCtx,
    setSecretModelCtx,
    messageErrorLoadMAtterport,
    setMessageErrorLoadMAtterport,
  }), [
    state1, site, startPoint, matterportSdk, activeMeasure, activeSensors,
    sensors, fbxObjects, currentEditObject, currentSensors, currentVolumes,
    modelList, appState, activeConfigModal, secretModelCtx, messageErrorLoadMAtterport
  ]);
  
  return (
    <MatterportContext.Provider
      value={value}
    >
      {children}
    </MatterportContext.Provider>
  );
};
