import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { ThemeProvider } from "react-jss";
import { ReactNotifications } from "react-notifications-component";
import { useFullscreen } from "react-use";
import { ToastProvider } from "react-toast-notifications";
import { TourProvider } from "@reactour/tour";
import ThemeContext from "../contexts/themeContext";
import Wrapper from "../layout/Wrapper/Wrapper";
import Portal from "../layout/Portal/Portal";
import { Toast, ToastContainer } from "../components/bootstrap/Toasts";
import useDarkMode from "../hooks/useDarkMode";
import COLORS from "../common/data/enumColors";
import { getOS } from "../helpers/helpers";
import steps, { styles } from "../steps";
import AsideRoutes from "../layout/Aside/AsideRoutes";

const App = () => {
  getOS();
  window.addEventListener('storage', (event: any) => {
    console.log({ eventChange: event })
    if (event.key === 'user_preference/unit_type') {

      console.log({ new: event.newValue, old: event.oldValue })
      // Crear y despachar el evento custom

      const updateLineTagEvent = new CustomEvent("tagUnits", {
        detail: {
          oldValue: event.oldValue,
          newValue: event.newValue
        }
      });
      document.dispatchEvent(updateLineTagEvent);
    }
  });
  /**
   * Dark Mode
   */
  const { themeStatus, darkModeStatus } = useDarkMode();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
  }, [mounted]);
  const theme = {
    theme: themeStatus,
    primary: COLORS.PRIMARY.code,
    secondary: COLORS.SECONDARY.code,
    success: COLORS.SUCCESS.code,
    info: COLORS.INFO.code,
    warning: COLORS.WARNING.code,
    danger: COLORS.DANGER.code,
    dark: COLORS.DARK.code,
    light: COLORS.LIGHT.code,
  };

  useEffect(() => {
    if (darkModeStatus) {
      document.documentElement.setAttribute("theme", "dark");
    }
    return () => {
      document.documentElement.removeAttribute("theme");
    };
  }, [darkModeStatus]);

  /**
   * Full Screen
   */
  // @ts-ignore
  const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
  const ref = useRef(null);
  useFullscreen(ref, fullScreenStatus, {
    onClose: () => setFullScreenStatus(false),
  });

  /**
   * Modern Design
   */
  useLayoutEffect(() => {
    if (process.env.REACT_APP_MODERN_DESGIN === "true") {
      document.body.classList.add("modern-design");
    } else {
      document.body.classList.remove("modern-design");
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <ToastProvider components={{ ToastContainer, Toast }}>
        <TourProvider
          steps={steps}
          styles={styles}
          showNavigation={false}
          showBadge={false}
        >
          <div
            ref={ref}
            className="app"
            style={{
              backgroundColor: fullScreenStatus
                ? "var(--bs-body-bg)"
                : undefined,
              zIndex: fullScreenStatus ? 1 : undefined,
              overflow: fullScreenStatus ? "scroll" : undefined,
            }}
          >
            <AsideRoutes />
            <Wrapper />
          </div>
          <Portal id="portal-notification">
            <ReactNotifications />
          </Portal>
        </TourProvider>
      </ToastProvider>
    </ThemeProvider>
  );
};

export default App;
