import React, { useState, useEffect, useContext } from 'react';

import { editPosition } from '../helpers/matterportHelper';
import Input from './bootstrap/forms/Input';
import FormGroup from './bootstrap/forms/FormGroup';
import Label from './bootstrap/forms/Label';
import Select from './bootstrap/forms/Select';
import ListGroup from './bootstrap/ListGroup';
import Button from './bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { OverlayContext } from '../contexts/OverlayContext';
import matterportHelper, { currentPose, defaultModelId } from '../helpers/matterportHelper';
const FormModel = (props) => {
    const { initialModel, modelList, models } = props;

    const {
        setActivePositionButtons
        , activePositionButtons,
        setActiveSaveButton,
        modelData,
        setModelData,
        setPositionEditObject, positionEditObject,
        currentNodeSelected,
        setAddModelFile,
        setReturnView
    } = useContext(OverlayContext);

    const [modelsList, setModelsList] = useState(modelList)
    console.log({ modelList })
    const { t } = useTranslation('menu');
    const [currentListModel, setCurrentListModel] = useState("")

    const handleEditPosition = () => {
        // Implementar la lógica para editar la posición del modelo

        setActivePositionButtons(true)
    };

    const handleSave = () => {

    };
    const handleCancel = () => {
        setActivePositionButtons(false);

    }
    const getModelData = (data) => {
        console.log({ toSave: data })
        setActivePositionButtons(false);
        // setModelData(data)
    }
    function getNewModelData(modelName) {
        console.log({ modelName, models })
        const newModelDAta = models.find(element => (element.parentOID + "/" + element.modelName) === modelName);
        return newModelDAta;
    }

    useEffect(() => {
        setModelData(prev => ({
            ...prev,
            modelFileName: initialModel.modelFileName
        }))
    }, [])
    return (
        <div >
            <div >
                <Label htmlFor="modelName">
                    {t("ModelName")}
                </Label>
                <Input
                    ariaDescribedby="Model name"
                    id="modelName"
                    placeholder="Model Name"
                    value={modelData.modelName}
                    onChange={(e) => {
                        setModelData((prev) => ({ ...prev, modelName: e.target.value }))
                        setActiveSaveButton(true)

                    }}
                >
                </Input>
                <Label htmlFor="modelFile"
                    className="hidden">
                    {t("ModelFile")}
                </Label>
                <Input
                    ariaDescribedby="model file"
                    id="modelFile"
                    className="hidden"
                    placeholder="file.fbx"
                    value={modelData.modelFile}
                    onChange={(e) => {

                    }
                    }
                />
                {

                }

                <Label htmlFor="selectType">
                    {t("Model")}
                </Label>
                <Input
                    ariaLabel='Per'
                    value={modelData.modelFileName}
                    ariaDescribedby="Type"
                    id="selectedModel"

                    disabled={true}
                    onChange={(e) => {
                        setCurrentListModel(e.target.value)
                        setModelData((prev) => ({ ...prev, modelFileName: e.target.value }))
                        setActiveSaveButton(true)
                    }

                    }
                    title="Type"

                />
                <br></br>
                <Button
                    className=' '
                    id="editPostion"
                    color="info"
                    onClick={() => {

                        // setActivePositionButtons(true);
                        setAddModelFile(true)
                        setReturnView(true)

                    }
                    }
                >
                    {t("ModelList")}
                </Button>
                <br></br>
                <Label htmlFor="selectType"

                >
                    {t("Type")}
                </Label>
                <Select
                    ariaLabel='Model'
                    value={modelData.interactionType}
                    ariaDescribedby="Type"
                    id="selectType"
                    list={[
                        {
                            text: t("goToDocumentData"),
                            value: "goToDocumentData"
                        },
                        {
                            text: t("openURL"),
                            value: "openURL"
                        },

                    ]}

                    onChange={(e) => {
                        setModelData((prev) => ({ ...prev, interactionType: e.target.value }))
                        setActiveSaveButton(true)


                    }

                    }
                    title="Type"

                />
                <Label
                    className={`${modelData.interactionType !== "openURL" ? 'hidden' : ''}`}
                >
                    {t("ExternalURL")}
                </Label>
                <br></br>
                <Input
                    className={`${modelData.interactionType !== "openURL" ? 'hidden' : ''}`}
                    ariaDescribedby="Url"
                    id="urlData"
                    placeholder="Url"

                    value={modelData.interactionReference}
                    onChange={(e) => {
                        setModelData((prev) => ({ ...prev, interactionReference: e.target.value }))
                        setActiveSaveButton(true)

                    }
                    }


                />
                <Label
                    aria-label=""
                    htmlFor="editPostion">
                    dasdasd
                </Label>
                <br />
                <Button
                    aria-label=""
                    id="editPostion"
                    color="secondary"
                    className=' '
                    onClick={() => {
                        console.log({ positionEditObject, currentPose })
                        setActivePositionButtons(true);
                        if (positionEditObject.isNew === true
                        ) {
                            console.log({ initialModel })
                            console.log({ modelData })
                            setPositionEditObject(prev => ({
                                ...prev, // Copiar todas las propiedades existentes
                                active: true,
                                type: "modelOID",
                                name: initialModel.modelName,
                                initialModelData: {

                                    ...positionEditObject.initialModelData,
                                    positionx: matterportHelper.positionInFrontCamera(currentPose.position.x, currentPose.position.z, currentPose.rotation.y).x,
                                    positiony: currentPose.position.y,

                                    positionz: matterportHelper.positionInFrontCamera(currentPose.position.x, currentPose.position.z, currentPose.rotation.y).z,

                                    rotationx: 0,
                                    rotationy: 0,

                                    rotationz: 0,

                                    scalex: 1,

                                    scaley: 1,

                                    scalez: 1,

                                    OID: defaultModelId,
                                    animated: 0,
                                    id: "",
                                    interactuable: modelData.interactionType,
                                    interactuable_content: modelData.interactionReference,
                                    interactuable_type: modelData.interactionType,
                                    length: 1,

                                    modelformat: "fbx",
                                    mongoName: currentNodeSelected._id + "/" + modelData.modelName,
                                    originalOID: defaultModelId
                                },
                                editsweepCameraPosition:
                                {
                                    sweepointUUID: currentPose.sweep,
                                    cameraHorizontalRotation: currentPose.rotation.y,
                                    cameraVerticalRotation: currentPose.rotation.x,
                                },
                            }));
                            setModelData({
                                ...modelData,
                                modelOID: defaultModelId,
                                // modelFileName: "default"
                            })
                        } else {
                            console.log({ editModels: models })
                            const newModelData = getNewModelData(positionEditObject.initialModelData.mongoName)
                            console.log({ newModelData })
                            setPositionEditObject(prev => ({
                                ...prev, // Copiar todas las propiedades existentes
                                type: "modelOID",
                                active: true,
                                name: initialModel.modelName,
                                editPosition: {

                                    position: {
                                        x: newModelData.objectTransform.xPosition,
                                        y: newModelData.objectTransform.yPosition,
                                        z: newModelData.objectTransform.zPosition,
                                    },
                                    rotation: {
                                        _x: newModelData.objectTransform.xRotation,
                                        _y: newModelData.objectTransform.yRotation,
                                        _z: newModelData.objectTransform.zRotation,
                                    },

                                    scale: {
                                        x: newModelData.objectTransform.xScale,
                                        y: newModelData.objectTransform.yScale,
                                        z: newModelData.objectTransform.zScale,
                                    }

                                }
                            }));

                        }

                    }}
                    icon="edit_location"
                >{t("EditPosition")}</Button>

            </div>


        </div>
    );
}

export default FormModel;
