import { ReactNode, useContext, useLayoutEffect, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import LANG, { getLangWithKey, ILang } from "../../../../lang";
import useDarkMode from "../../../../hooks/useDarkMode";

import { OverlayContext } from "../../../../contexts/OverlayContext";


// import '../../../styles/components/layout/_commonHeaderRight.scss'
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../../components/bootstrap/Modal";
import { useFormik } from "formik";
  
const MenuConfigureModalMap: any = (props: any) => {
  const { showModalMsgErrorImage, setShowModalMsgErrorImage } = props;
  const { currentFloor, mapSourceCurrentFloor, currentImage } = useContext(OverlayContext);
  const { t } = useTranslation('menu');


  const { i18n } = useTranslation();

  const changeLanguage = (lng: ILang['key']['lng']) => {
      i18n.changeLanguage(lng);
  };

  /**
   * Language attribute
   */
  useLayoutEffect(() => {
      document.documentElement.setAttribute('lang', i18n.language.substring(0, 2));
  });

    
  
  const changeActiveConfigModal = () => {
    setShowModalMsgErrorImage(!showModalMsgErrorImage);
  }


  return (
    <Modal 
      id="modalConfig"
      titleId="titleConfig"
      isOpen={ showModalMsgErrorImage } // Example: state
      setIsOpen={ changeActiveConfigModal } // Example: setState
      isStaticBackdrop={ true } 
      isScrollable={ false } 
      isCentered={true} 
      fullScreen= 'md' 
      isAnimation={ true }>
      <ModalHeader 
        /* className={ String } */
        setIsOpen={ changeActiveConfigModal} // Example: setState
        >
        <ModalTitle id="ErrorObtainImage">{t("ErrorObtainImage") as ReactNode}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <div className={`instructionText mb-2`}>
            {t("ErrorParagraph") as ReactNode}
            <br/>
            <br/>
            <ul>
                <li>{t("ConsiderationOnePartOne") as ReactNode} <i>"{t("UploadNewImageMap") as ReactNode}"</i> {t("ConsiderationOnePartTwo") as ReactNode}</li>
                <br/>
                <li>{t("SecondConsideration") as ReactNode}</li>
            </ul>
            
        </div>
      </ModalBody>
      <ModalFooter>
        <button type='button' className='btn btn-primary' onClick={()=>changeActiveConfigModal()}>{t("Close")}</button>
      </ModalFooter>
    </Modal>
  );
};

export default MenuConfigureModalMap;
